import * as React from "react";
import { Button, Dialog, DialogContent } from "@jhool-io/fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { formatDate } from "../../../../utils/helpers";
import { IPayer } from "../../types/payers.types";
import { useDeletePayer } from "../../hooks/payers.mutations";
import useToast from "../../../../hooks/useToast";
import { getStateInShortForm } from "../../../../utils/helpers/us-states/us-states";

interface Props {
    selectedPayer: IPayer;
    setIsEditView: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteComplete: () => void;
}

export default function PayerDetailsInfo({
    selectedPayer,
    setIsEditView,
    onDeleteComplete,
}: Props) {
    const deletePayer = useDeletePayer();
    const queryClient = useQueryClient();

    const { toast } = useToast();

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const handleDeletePayer = () => {
        deletePayer.mutate(
            { insurance_provider_id: selectedPayer.insurance_provider_id },
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({ queryKey: ["payers"] });
                    toast({
                        mode: "success",
                        message: res.message || "Payer deleted successfully",
                    });

                    // close modal
                    setShowDeleteModal(false);
                    onDeleteComplete();
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not delete payer. Please try again",
                    });
                },
            }
        );
    };

    return (
        <>
            <Dialog open={showDeleteModal} modal>
                <DialogContent
                    variant="center"
                    handleCloseDialog={() => setShowDeleteModal(false)}
                    title="Delete payer"
                    isSubmitBtnDisabled={deletePayer.isLoading}
                    saveText="Delete Payer"
                    onSaveClick={handleDeletePayer}
                    isDeleting
                >
                    <>
                        <h4 className="text-xl font-medium mb-8">
                            Are you sure you want to delete this payer?
                        </h4>

                        <p className="text-gray text-base tracking-[0.32px] leading-10">
                            Deleting this payer is permanent, and can&apos;t be
                            reversed.
                        </p>
                    </>
                </DialogContent>
            </Dialog>

            <div>
                <div className="flex justify-between mb-32 border-b border-b-strokedark px-24 pb-20">
                    <h4 className="text-xl">Payer Details</h4>

                    <div className="flex gap-10">
                        <Button
                            variant="secondary"
                            onClick={() => setIsEditView(true)}
                        >
                            Edit Payer
                        </Button>
                        <Button
                            variant="normal"
                            className="bg-danger text-white"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            Delete Payer
                        </Button>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-32 text-sm uppercase px-24">
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PROVIDER&apos;S NAME
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.name}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S ID
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_id
                                ? selectedPayer.payer_id
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S NAME
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_name
                                ? selectedPayer.payer_name
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S ADDRESS 1
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_address_1
                                ? selectedPayer.payer_address_1
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S ADDRESS 2
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_address_2
                                ? selectedPayer.payer_address_2
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S CITY
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_city
                                ? selectedPayer.payer_city
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S STATE
                        </h4>
                        <p className="font-medium leading-8">
                            {getStateInShortForm(selectedPayer.payer_state)}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER&apos;S ZIPCODE
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_zipcode
                                ? selectedPayer.payer_zipcode
                                : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            PAYER ENROLLED
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.payer_enrolled ? "YES" : "NO"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            ATTACHMENT PAYER&apos;S DETAILS
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.attachment_payer_id || "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold leading-6 text-gray mb-3">
                            ATTACHMENT PAYER&apos;S NAME
                        </h4>
                        <p className="font-medium leading-8">
                            {selectedPayer.attachment_payer_name || "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="font-semibold text-sm leading-6 text-gray mb-3">
                            UPDATED AT
                        </h4>
                        <p className="font-medium text-sm leading-8">
                            {formatDate(selectedPayer.updated_at)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
