import { ISortTable } from ".";
import { SessionDeliveryOption, SessionServiceType } from "./session";

export enum NoteTypes {
    INDIVIDUAL_INTAKE_NOTE = "individual_intake_note",
    MINOR_INTAKE_NOTE = "minor_intake_note",
    FAMILY_INTAKE_NOTE = "family_intake_note",
    FAMILY_TREATMENT_REVIEW = "family_treatment_review",
    INDIVIDUAL_TREATMENT_REVIEW = "individual_treatment_review",
    INDIVIDUAL_SOAP_NOTE = "individual_soap_note",
    FAMILY_SOAP_NOTE = "family_soap_note",
    RECORD_OF_DISCLOSURE = "record_of_disclosure",
    CANCELLATION_NOTE = "cancellation_note",
    SUPERVISION_NOTE = "supervision_note",
    TERMINATION_NOTE = "termination_note",
    SAFETY_PLAN = "safety_plan",
    GENERAL_UPDATE_NOTE = "general_update_note",
    SUPERVISOR_CHECK_IN = "supervisor_check_in",
    PAUSE_NOTIFICATION_NOTE = "pause_notification_note",
}

interface INoteLabels {
    [key: string]: string;
}

export const NoteLabels: INoteLabels = {
    individual_intake_note: "Individual intake note",
    family_intake_note: "Family intake note",
    // Leaving this for old notes currently on the app
    treatment_review: "Individual treatment review",
    individual_treatment_review: "Individual treatment review",
    family_treatment_review: "Family treatment review",
    individual_soap_note: "Individual soap note",
    family_soap_note: "Family soap note",
    record_of_disclosure: "Record of disclosure",
    cancellation_note: "No show/late cancel",
    supervision_note: "Supervision note",
    termination_note: "Termination note",
    safety_plan: "Safety plan",
    general_update_note: "General update note",
    supervisor_check_in: "Supervisor check-in note",
    minor_intake_note: "Minor intake note",
    pause_notification_note: "Pause notification note",
};

export enum ModifierTypes {
    PROLONGED_EVALUATION_AND_MANAGEMENT = "21 Prolonged Evaluation & Management",
    UNUSUAL_PROCEDURAL_SERVICES = "22 Unusual Procedural Services",
    UNRELATED_EVALUATION_AND_MANAGEMENT = "24 Unrelated Evaluation & Management",
    IDENTIFIABLE_EVALUATION_AND_MANAGEMENT_SERVICE = "25 Significant, Separately Identifiable Evaluation and Management Service by the Same Physician on the Same Day of the Procedure or Other Service",
    DISTINCT_PROCEDURAL_SERVICE = "59 Distinct Procedural Service",
    TELEMEDICINE_AUDIO_ONLY = "93 Telemedicine (Audio only)",
    TELEMEDICINE = "95 Telemedicine",
    MULTIPLE_MODIFIERS = "99 Multiple Modifiers",
    TELEHEALTH = "GT Telehealth",
}

export enum NoteStatus {
    CODED = "coded",
    DRAFT = "draft",
    PENDING_SUPERVISOR_REVIEW = "pending_supervisor_review",
    CHANGES_REQUESTED = "changes_requested",
    PENDING_CODE_REVIEW = "pending_code_review",
}

export enum MethodOfDisclosure {
    FAX = "Fax",
    TELEPHONE = "Telephone",
    ELECTRONIC = "Electronic",
    MAIL = "Mail",
    OTHER = "Other",
}

export enum TypeOfDisclosure {
    TREATMENT_RECORD = "Treatment Record",
    PAYMENT_INFORMATION = "Payment Information",
    HEALTHCARE_OPERATION = "Healthcare Operation",
}

export enum NoteListSortAttribute {
    DATE_OF_SERVICE = "date_of_service",
    CLIENT_NAME = "client_name",
    PROVIDER_NAME = "provider_name",
}

export enum FlaggedNoteForAuditStatus {
    FLAGGED = "flagged",
    SENT_FOR_CHANGES = "sent_for_changes",
    REQUIRES_ACTION = "requires_action",
    UNFLAGGED = "unflagged",
}

export interface INoteContent {
    id: string;
    header: string;
    body: string;
}

export enum Source {
    ELECTRONIC = "Electronic",
    MANUAL = "Manual",
}

export interface INoteContentDetails {
    first_session_date: string;
    last_session_date: string;
    no_of_sessions: string;
    patient_final_condition: string;
    prognosis: string;
    note_contents: INoteContent[];
}

export const noteTypesForExport = [
    NoteTypes.INDIVIDUAL_SOAP_NOTE,
    NoteTypes.INDIVIDUAL_INTAKE_NOTE,
    NoteTypes.FAMILY_INTAKE_NOTE,
    NoteTypes.SAFETY_PLAN,
    NoteTypes.INDIVIDUAL_TREATMENT_REVIEW,
    NoteTypes.FAMILY_TREATMENT_REVIEW,
    NoteTypes.FAMILY_SOAP_NOTE,
];

export interface INoteDetails {
    note_id?: string;
    note_type: NoteTypes;
    note_status?: NoteStatus;
    note_provider?: string;
    dates_of_service?: string;
    client?: {
        client_id?: string;
        first_name?: string;
        last_name?: string;
        insurance_provider_name?: "cigna";
        self_pay?: boolean;
    };
    start_time?: string;
    end_time?: string;
    duration?: string;
    appointment_type?: SessionServiceType;
    provider_signature?: string;
    date_added?: string;
    time_added?: string;
    provider?: {
        first_name: string;
        last_name: string;
    };
    is_archived?: boolean;
}

export interface IAddendumDetails {
    note_id: string;
    note_addendum_id?: string;
    author: {
        first_name: string;
        last_name: string;
        email: string;
    };
    signature: string;
    signature_date_time: string;
    title: string;
    content: string;
    field_id: string | null;
    field_title: string | null;
}

export interface INote {
    note_id: string;
    provider?: {
        first_name: string;
        last_name: string;
        user_id?: string;
    };
    date_of_service: string;
    note_type: NoteTypes;
    note_url?: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    cpt_code: string;
    status: NoteStatus;
    modifier_codes?: string[];
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
    author: {
        id: string;
        first_name: string;
        last_name: string;
    };
    flagged_for_audit_status?: FlaggedNoteForAuditStatus;
}

export interface ISuperviseeNote {
    note_id: string;
    date_of_service: string;
    note_type: NoteTypes;
    supervisee_name: string;
    cpt_code: string;
    status: NoteStatus;
    provider: {
        first_name: string;
        last_name: string;
        user_id: string;
    };
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        insurance_provider_name?: string;
        self_pay?: boolean;
    };
    is_archived?: boolean;
    session_history_id: string;
    appointment_type: SessionServiceType;
    created_at: string;
    author: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface IAppointmentDetails {
    client_name: string;
    date_of_service: string;
    note_type: string;
    appointment_type?: string;
    start_time?: string;
    provider_name?: string;
    provider_signature?: string;
    duration?: string;
    end_time?: string;
}

export interface INoteActivities {
    note_activities: IActivity[];
}

export interface IChangeCode {
    [noteId: string]: {
        cpt_code: string;
        modifier_codes?: string[];
        confirm_code?: boolean;
    };
}
export interface IDeleteNoteMessage {
    message_id: string;
}
export interface IDeleteNoteMessageResponse {
    deleted: boolean;
}

export interface ClientNoteParams {
    type: string;
    load_previous_notes: boolean;
    provider_id: string;
}
export interface IGetClientNotes {
    note_id: string;
    archived_notes: string;
    is_archived: boolean;
    note_content: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    provider: {
        provider_id: string;
        first_name: string;
        last_name: string;
    };
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
    };
    note_type: string;
    appointment_type: string;
    note_url: string;
    cpt_code: string;
    status: string;
    date_of_service: string;
    modifier_codes: string[];
}
export interface IGetMessages {
    message_id: string;
    message: string;
    sender_user_id: string;
    sender_first_name: string;
    sender_last_name: string;
    is_public: boolean;
    sent_time: string;
    recipient_user_id: string;
    recipient_first_name: string;
    recipient_last_name: string;
}

export interface IChangeCodeResponse {
    cpt_code: string;
    modifier_codes: string[];
    note_id: string;
    pos_code: string;
    status: string;
}

export interface IActivity extends ISortTable {
    note_activity_id: string;
    activity_datetime: string;
    activity_user: {
        id: string;
        first_name: string;
        last_name: string;
    };
    activity_description: string;
    metadata?: {
        addendum_field_id?: string;
        flag_for_audit_notes?: string;
    };
}

// Notes lists filter types
export interface INotesFilterTypes {
    search_string?: string;
    status?: string[];
    type?: string[];
    cpt_code?: string;
    from_date?: null | string;
    to_date?: null | string;
    page?: number;
    provider_name?: string;
    limit?: number;
    show_archived?: string;
    sort_attribute?: string;
    sort_order?: string;
    client_id?: string;
    insurance_provider_id?: string;
    author_id?: string;
    is_flagged_for_audit?: string;
    tab_name?: string;
    from_created_at_date?: null | string;
    to_created_at_date?: null | string;
    flagged_for_audit_status?: string[];
}

export interface INewNoteAppoinmentDetails {
    note_type: NoteTypes;
    client_name: string;
    appointment_type?: SessionServiceType;
    session_history_id: string | null;
    date_of_service?: string | null;
    session_start_time: string | null;
    session_end_time: string | null;
    provider_name?: string;
    session_id: string;
    client_id: string;
    duration?: string;
    provider_signature?: string;
    provider_signature_datetime?: string;
    supervisor_name?: string;
    supervisor_signature?: string;
    supervisor_signature_datetime?: string;
    provider_id: string;
    first_session_date: string | null;
    no_of_sessions?: number | null;
    modifier_codes?: string[];
    reason_for_short_duration?: string;
}

export type IEditNoteAppointmentDetails = INewNoteAppoinmentDetails;

export type CreateNoteSteps = "appointment-details" | "add-note";

export type EditNoteSteps = "appointment-details" | "edit-note";

export interface ISupervisionFormValues {
    supervision_note: string;
    client_note: string;
}

export interface ICancellationFormValues {
    reason: string;
}

export interface IRecordOfDisclosureFormValues {
    duration: string;
    diagnosis: string;
    disclosure_to_whom: string;
    disclosure_type: string;
    purpose_of_disclosure: string;
    type_disclosed: string;
    method_of_disclosure: string;
    session_start_time: string;
    session_end_time: string;
}

export type NoteContent<T> = T;
export interface ISessionNote {
    note_id: string;
    note_status: NoteStatus;
    type: NoteTypes;
    pos_code?: string;
    client: {
        client_id: string;
        first_name: string;
        last_name: string;
        date_of_birth?: string;
        age?: string;
        contact?: string;
        pronouns?: string;
        email?: string;
        sex?: string;
        self_pay?: boolean;
    };
    claim_id?: string;
    appointment_type: SessionServiceType;
    session_id: string;
    provider: {
        provider_id: string;
        first_name: string;
        last_name: string;
        supervisor_id?: string;
    };
    session_history_id: string;
    date_of_service: string;
    session_start_time: string;
    session_end_time: string;
    invoice_status?: string;
    invoice_id?: string;
    note_content: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    note_signatures: {
        provider_id: string;
        signature_date_time: string;
        role: string;
        first_name: string;
        last_name: string;
        signature: string;
    }[];
    cpt_code?: string;
    note_url?: string;
    delivery_option?: SessionDeliveryOption;
    modifier_codes?: string[];
    reason?: string;
    insurance_provider?: string;
    issue_category?: string;
    issue_notes?: string;
    is_archived?: boolean;
    archive_notes?: string;
    is_flagged_for_audit?: boolean;
    flagged_for_audit_notes?: string;
    reason_for_short_duration?: string;
    note_addendums: {
        field_addendums: {
            [key: string]: IAddendumDetails[];
        } | null;
        general_addendums: IAddendumDetails[] | null;
    };
    source?: Source;
    note_url_metadata?: {
        content_type: string;
    };
    manual_note_diagnosis_codes?: { label: string; code: string }[];
    flagged_for_audit_status?: FlaggedNoteForAuditStatus;
}

export interface INewSessionNote {
    client_id: string;
    session_id: string;
    provider_id: string;
    session_history_id: string | null;
    date_of_service: string | null;
    session_start_time?: string;
    session_end_time?: string;
    appointment_type: SessionServiceType;
    pos_code?: string;
    note_content: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    note_type: string;
    diagnosis_codes?: string[];
    cancelled_by?: string;
    reason_for_short_duration?: string;
}

export interface ISignNewNotePayload {
    signature: string;
    signature_date_time: string;
}

export interface IApproveNotePayload {
    note_ids: string[];
    signature: string;
    signature_date_time: string;
}
export interface IAddNoteMessage {
    message: string;
    is_public: boolean;
    recipient_user_id?: string;
}
export interface IApproveNoteResponse {
    message: string;
    data: {
        approved_notes: {
            note_id: string;
            status: NoteStatus;
            cpt_code: string;
        }[];
        unapproved_notes: string[];
    };
}

export interface IRequestChangePayload {
    request_reason: string;
    request_details: string;
    other_reason?: string;
}

export interface INewNoteResponse {
    client_id?: string;
    note_id: string;
    note_status: NoteStatus;
}

export interface IExportNotePayload {
    session_note_ids: string[];
}

export interface IChangeArchiveStatus {
    note_ids_to_archive: string[];
    archive_status: boolean;
    reason?: string;
}

// SOAP NOTE
export type SoapSteps = "one" | "two" | "three" | "four";

export enum SoapProgressSteps {
    STEP_ONE = "step_one",
    STEP_TWO = "step_two",
    STEP_THREE = "step_three",
    STEP_FOUR = "step_four",
}
export interface ISoapFormValues {
    duration?: string;
    isClientPresent?: boolean;
    appointment_location?: string;
    delivery_option?: string;
    other_delivery_option?: string;
    diagnosis_codes?: string[];
    emotional_behavioural_symptoms_demonstrated?: string;
    causing?: string;
    intention_for_session?: string;
    subjective?: string;
    objective?: string;
    assessment?: string;
    cognitive_behavioral_therapies?: string[];
    relationship_based_interventions?: string[];
    other?: string[];
    plan?: string;
    prognosis?: string;
    client_response?: string;
    goal_one?: string;
    goal_two?: string;
    goal_three?: string;
    progress_one?: string;
    progress_two?: string;
    progress_three?: string;
}
export interface INewAddendum {
    title: string;
    content: string;
    field_id: string;
    field_title: string;
    signature: string;
    signature_datetime: string;
}

export interface IEditDraftNote {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    note_content?: { [key: string]: any };
    date_of_service?: string | null;
    session_start_time?: string;
    session_end_time?: string;
    pos_code?: string;
    reason_for_short_duration?: string;
    appointment_type?: string;
    // adding this for form logic, will remove before submitting
    note_type?: string;
}

export interface FamilyIntakeNotePayload {
    name: string;
    gender: string;
    religion: string;
    education: string;
    race: string;
    ethinicity: string;
    sexual_orientation: string;
    current_employment: string;
    current_employment_details: string;
    relationship_status: string;
    name_of_partner: string;
    emergency_contact_information: string;
    legal_problems: string;
    anxiety_frequency: string;
    anxiety_details: string;
    panic_attacks_freqency: string;
    panic_attacks_details: string;
    depression_freqency: string;
    depression_details: string;
    selected_symptoms: string[];
    other_symptoms: "";
    physical_symptoms: string;
    medication_physical_psychiatric: string;
    sleeping_habits_and_concerns: string;
    difficulties_with_appetite_or_eating_patterns: string;
    parents_stepparents_history: string;
    siblings_history: string;
    grandparents_history: string;
    children_history: string;
    other_family_members_history: string;
    family_members_alcohol_abuse: string[];
    family_members_anxiety: string[];
    family_members_bipolar_disorder: string[];
    family_members_depression: string[];
    family_members_ptsd: string[];
    family_members_schizophrenia: string[];
    family_members_substance_abuse: string[];
    family_members_substance_abuse_substance: string;
    trauma_abuse_history: string;
    previous_mental_health_treatment_oupatient: string;
    impatient_psychiatric_hospitalization: string;
    relevant_medical_history: string;
    been_so_distressed_you_seriously_wished_to_end_your_life: string;
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: string;
    a_specific_plan_of_how_you_would_kill_yourself: string;
    access_to_weapons_or_means_of_hurting_self: string;
    made_serious_suicide_attempts: string;
    purposely_done_something_to_hurt_yourself: string;
    describe_done_something_to_hurt_yourself: string;
    heard_voices_telling_you_to_hurt_yourself: string;
    had_relatives_who_attempted_or_commited_sucide: string;
    had_thoughts_of_killing_or_seriously_hurting_someone: string;
    heard_voices_telling_you_to_hurt_others: string;
    hurt_someone_or_destroyed_property_on_purpose: string;
    slapped_kicked_punched_someone_with_intent_to_harm: string;
    been_arrested_or_detained_for_violent_behavior: string;
    been_to_jail_for_any_reason: string;
    been_on_probation_for_any_reason: string;
    do_you_have_access_to_guns: string;
    describe_do_you_have_access_to_guns: string;
    mental_health_appearance: string[];
    mental_health_movement: string[];
    mental_health_speech_behaviour: string[];
    mental_health_affect_mood: string[];
    mental_health_attention_concentration_effort: string[];
    mental_health_presentation_of_self: string[];
    mental_health_cognition: string[];
    patients_ability_and_capability_to_respond_to_treatment: string[];
    details_for_diminished_poor_limited_quality_patient_oriented_evidence: string;
    anxiety?: boolean;
    panic_attacks?: boolean;
    depression?: boolean;
    family_id?: string;
}

export const emptyFamilyIntakeNote: FamilyIntakeNotePayload = {
    name: "",
    gender: "",
    religion: "",
    education: "",
    race: "",
    ethinicity: "",
    sexual_orientation: "",
    current_employment: "",
    current_employment_details: "",
    relationship_status: "",
    name_of_partner: "",
    emergency_contact_information: "",
    legal_problems: "",
    anxiety_frequency: "",
    anxiety_details: "",
    panic_attacks_freqency: "",
    panic_attacks_details: "",
    depression_freqency: "",
    depression_details: "",
    selected_symptoms: [],
    other_symptoms: "",
    physical_symptoms: "",
    medication_physical_psychiatric: "",
    sleeping_habits_and_concerns: "",
    difficulties_with_appetite_or_eating_patterns: "",
    parents_stepparents_history: "",
    siblings_history: "",
    grandparents_history: "",
    children_history: "",
    other_family_members_history: "",
    family_members_alcohol_abuse: [],
    family_members_anxiety: [],
    family_members_bipolar_disorder: [],
    family_members_depression: [],
    family_members_ptsd: [],
    family_members_schizophrenia: [],
    family_members_substance_abuse: [],
    family_members_substance_abuse_substance: "",
    trauma_abuse_history: "",
    previous_mental_health_treatment_oupatient: "",
    impatient_psychiatric_hospitalization: "",
    relevant_medical_history: "",
    been_so_distressed_you_seriously_wished_to_end_your_life: "",
    has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live: "",
    describe_has_anything_happened_recently_to_make_you_feel_you_dont_want_to_live:
        "",
    a_specific_plan_of_how_you_would_kill_yourself: "",
    access_to_weapons_or_means_of_hurting_self: "",
    made_serious_suicide_attempts: "",
    purposely_done_something_to_hurt_yourself: "",
    describe_done_something_to_hurt_yourself: "",
    heard_voices_telling_you_to_hurt_yourself: "",
    had_relatives_who_attempted_or_commited_sucide: "",
    had_thoughts_of_killing_or_seriously_hurting_someone: "",
    heard_voices_telling_you_to_hurt_others: "",
    hurt_someone_or_destroyed_property_on_purpose: "",
    slapped_kicked_punched_someone_with_intent_to_harm: "",
    been_arrested_or_detained_for_violent_behavior: "",
    been_to_jail_for_any_reason: "",
    been_on_probation_for_any_reason: "",
    do_you_have_access_to_guns: "",
    describe_do_you_have_access_to_guns: "",
    mental_health_appearance: [],
    mental_health_movement: [],
    mental_health_speech_behaviour: [],
    mental_health_affect_mood: [],
    mental_health_attention_concentration_effort: [],
    mental_health_presentation_of_self: [],
    mental_health_cognition: [],
    patients_ability_and_capability_to_respond_to_treatment: [],
    details_for_diminished_poor_limited_quality_patient_oriented_evidence: "",
    anxiety: false,
    panic_attacks: false,
    depression: false,
};

export interface INoteAuditDahboardResponse {
    from_date: string;
    to_date: string;
    summary: {
        overdue_notes_count: number;
        average_time_to_sign_note_secs: number;
        average_time_to_complete_first_review_secs: number;
    };
    overdue_notes: INoteAuditDashboardList[];
}

export interface INoteAuditDashboardList {
    date_of_service: string;
    overdue_period_secs: number;
    session_service: SessionServiceType;
    note_status: NoteStatus;
    note_id: string;
    provider: {
        id: string;
        first_name: string;
        last_name: string;
        credential: string;
    };
    supervisor: {
        id: string;
        first_name: string;
        last_name: string;
    };
    client: {
        id: string;
        first_name: string;
        last_name: string;
    };
}

export interface INoteAiAnalysisResponse {
    message: string;
    data: {
        analysisAvailable: boolean;
        averageScore: number;
        reviewResponse: Partial<IIndividualSoapNoteAnalysisResponse>;
    };
}

export interface IIndividualSoapNoteAnalysisResponse {
    subjective: {
        score: number;
        suggestion: string;
    };
    objective: {
        score: number;
        suggestion: string;
    };
    assessment: {
        score: number;
        suggestion: string;
    };
    plan: {
        score: number;
        suggestion: string;
    };
    client_response: {
        score: number;
        suggestion: string;
    };
    progress_one: {
        score: number;
        suggestion: string;
    };
    goal_one: {
        score: number;
        suggestion: string;
    };
    progress_two: {
        score: number;
        suggestion: string;
    };
    goal_two: {
        score: number;
        suggestion: string;
    };
    progress_three: {
        score: number;
        suggestion: string;
    };
    goal_three: {
        score: number;
        suggestion: string;
    };
    prognosis: {
        score: number;
        suggestion: string;
    };
}
