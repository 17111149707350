import { Alert, Select } from "@jhool-io/fe-components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useChangeInsuranceProvider } from "../../../hooks/claims.mutations";
import { IChangeInsurance } from "../../../types/claims.types";
import { useFetchInsuranceProviders } from "../../../../clients/hooks/clients.queries";
import {
    handleRemoveEmptyParamFromQueryParams,
    removeEnumUnderscore,
} from "../../../../../utils/helpers";
import useToast from "../../../../../hooks/useToast";
import { useFetchSessionNote } from "../../../../../hooks/queries/note";
import { NoteStatus } from "../../../../notes/types/notes.types";
import { useFetchInvoices } from "../../../../billing/hooks/queries/billing.queries";
import useShowInsuranceOverSelfPay from "../../../../practice-settings/hooks/useShowInsuranceOverSelfPay";

type Options = {
    label: string;
    value: string;
};

interface ChangeInsuranceFormProps {
    onFormSubmit: () => void;
}

const schema = yup.object().shape({
    insurance_provider_id: yup
        .string()
        .required("Insurance provider is required"),
});

export default function ChangeInsuranceForm({
    onFormSubmit,
}: ChangeInsuranceFormProps) {
    const params = useParams();
    const noteId = params.noteId as string;
    const clientId = params.clientId as string;

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const { data: noteDetails } = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    const invoiceInView = useFetchInvoices(
        {
            invoice_id: noteDetails?.data?.invoice_id || "",
        },
        Boolean(noteDetails?.data?.invoice_id)
    );

    // Hook for fetching insurance providers
    const insuranceProviders = useFetchInsuranceProviders({
        client_id: clientId,
    });

    const { toast } = useToast();

    const queryClient = useQueryClient();

    const isSelfPay = () => {
        const invoiceData = invoiceInView?.data?.data?.[0];
        const hasInvoiceId = noteDetails?.data?.invoice_id;

        if (hasInvoiceId) {
            return invoiceData?.metadata?.self_pay && !showInsOverSelfPay;
        }
        return noteDetails?.data.client.self_pay && !showInsOverSelfPay;
    };

    const insuranceProviderToDisplay = () => {
        const invoiceData = invoiceInView?.data?.data?.[0];
        const hasInvoiceId = noteDetails?.data.invoice_id;

        if (hasInvoiceId) {
            return invoiceData?.insurance_provider;
        }

        return noteDetails?.data.insurance_provider;
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IChangeInsurance>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Select options for insurance providers
    const insuranceProvidersSelectOptions = [
        {
            insurance_provider_id: "self_pay",
            alias: "self_pay",
        },
        ...(insuranceProviders.data?.data || []),
    ]
        .sort((a, b) => a.alias.localeCompare(b.alias))
        .map((provider) => ({
            label: removeEnumUnderscore(provider.alias).toUpperCase(),
            value: provider.insurance_provider_id,
        }));

    const changeInsurance = useChangeInsuranceProvider(noteId || "");

    const onSubmit = (data: IChangeInsurance) => {
        changeInsurance.mutate(data, {
            onSuccess: (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["get-claim", noteId],
                });
                queryClient.invalidateQueries({
                    queryKey: [clientId, "session-note", noteId],
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        "invoices",
                        handleRemoveEmptyParamFromQueryParams({
                            invoice_id: noteDetails?.data.invoice_id || "",
                        }),
                    ],
                });
                toast({
                    mode: "success",
                    message:
                        res.message ||
                        "Insurance provider updated successfully.",
                });
                onFormSubmit();
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not update insurance provider at this time, please try again later.",
                });
            },
        });
    };

    if (insuranceProviders.isLoading) {
        return (
            <div className="flex justify-center items-center h-[80px]">
                <div className="small-loader !w-[30px] !h-[30px]" />
            </div>
        );
    }

    return (
        <form id="change-insurance" onSubmit={handleSubmit(onSubmit)}>
            {noteDetails?.data &&
            noteDetails.data.note_status === NoteStatus.CODED ? (
                <Alert
                    type="info"
                    description="The attached claim will be updated with the new insurance provider."
                    classNames="mb-24"
                />
            ) : null}
            <Controller
                name="insurance_provider_id"
                control={control}
                defaultValue={
                    insuranceProvidersSelectOptions?.find(
                        (option) =>
                            option.label.toLowerCase() ===
                            (isSelfPay()
                                ? "self pay"
                                : insuranceProviderToDisplay()?.toLowerCase())
                    )?.value
                }
                render={({ field }) => (
                    <Select
                        label="Insurance provider"
                        placeholder="Select insurance provider"
                        isSearchable
                        isLongListInDialog
                        onChange={(val) => {
                            field.onChange((val as Options).value);
                        }}
                        hasError={!!errors.insurance_provider_id}
                        errorText={errors.insurance_provider_id?.message}
                        options={insuranceProvidersSelectOptions}
                        defaultValue={insuranceProvidersSelectOptions?.find(
                            (option) => {
                                return (
                                    option.label.toLowerCase() ===
                                    (isSelfPay()
                                        ? "self pay"
                                        : insuranceProviderToDisplay()?.toLowerCase())
                                );
                            }
                        )}
                    />
                )}
            />
        </form>
    );
}
