import { Button } from "@jhool-io/fe-components";
import { useNavigate } from "react-router-dom";
import {
    cn,
    convertTimeTo12HoursFormat,
    formatDate,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../../utils/helpers";
import { IWeeklySchedule } from "../../../types/schedule.types";
import { ClaimStatus } from "../../../../claims/types/claims.types";
import { NoteStatus } from "../../../../notes/types/notes.types";
import { BillStatus } from "../../../../billing/types/billing.types";
import { getStatusTag } from "../../../../claims/helpers/claims.helper";
import { getNoteStatusTag } from "../../../../notes/helpers/notes.helpers";
import { getBillStatusTag } from "../../../../billing/helpers/billing.helpers";

interface BillerScheduleDetailsProps {
    schedule: IWeeklySchedule;
    borderLeftColor: string;
}

export default function BillerScheduleDetails({
    schedule,
    borderLeftColor,
}: BillerScheduleDetailsProps) {
    const navigate = useNavigate();
    return (
        <article
            className="bg-white rounded-r8 shadow-[0px_25px_50px_-12px_#00000040] px-20 py-40 inline-block border-l-[2px]"
            style={{ borderLeftColor }}
        >
            <div className="flex flex-col mb-24">
                <div className="flex items-start gap-x-12">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9 7.25C10.5188 7.25 11.75 6.01878 11.75 4.5C11.75 2.98122 10.5188 1.75 9 1.75C7.48122 1.75 6.25 2.98122 6.25 4.5C6.25 6.01878 7.48122 7.25 9 7.25Z"
                            stroke="#576A81"
                            strokeLinecap="round"
                        />
                        <path
                            d="M13.7621 15.516C14.6221 15.245 15.0741 14.295 14.7091 13.471C13.7391 11.28 11.5501 9.75 9.00011 9.75C6.45011 9.75 4.26111 11.28 3.29111 13.471C2.92611 14.296 3.37811 15.245 4.23811 15.516C5.46311 15.902 7.08411 16.25 9.00011 16.25C10.9161 16.25 12.5371 15.902 13.7621 15.516Z"
                            stroke="#576A81"
                            strokeLinecap="round"
                        />
                    </svg>
                    <div className="flex flex-col">
                        <span className="font-bold text-xl mb-12 leading-none">
                            {`${schedule.client.first_name} ${schedule.client.last_name} X ${schedule.provider.first_name} ${schedule.provider.last_name}`}
                        </span>
                        <span className="font-medium text-base text-gray mb-8 capitalize flex items-center gap-x-8">
                            <span>
                                {removeEnumUnderscore(schedule.service)}
                            </span>
                            •
                            <span className="uppercase">
                                {schedule.client.insurance_provider_name}
                            </span>
                        </span>
                        <span className="font-medium text-base text-gray mb-8">
                            {formatDate(
                                schedule.possible_start_date_time as string
                            )}
                            ,{" "}
                            {`${convertTimeTo12HoursFormat(
                                `${schedule.session_start_hr}:${schedule.session_start_min}`
                            ).toUpperCase()} - ${convertTimeTo12HoursFormat(
                                `${schedule.session_end_hr}:${schedule.session_end_min}`
                            ).toUpperCase()}`}
                        </span>
                        <div className="flex font-medium text-base text-gray gap-8">
                            {schedule.coinsurance_amount ? (
                                <>
                                    <div className="font-bold">COINS</div>
                                    {showMoneyInAppFormat(
                                        schedule?.coinsurance_amount || "--"
                                    )}
                                    ,
                                </>
                            ) : null}{" "}
                            {schedule.coinsurance_amount_paid ? (
                                <>
                                    <div className="font-bold">PAID</div>
                                    {showMoneyInAppFormat(
                                        schedule?.coinsurance_amount_paid ||
                                            "--"
                                    )}
                                </>
                            ) : null}
                        </div>

                        <div className="flex pt-8 gap-8">
                            {schedule.note_status && (
                                <span>
                                    {getNoteStatusTag(
                                        schedule.note_status as NoteStatus,
                                        "Note:"
                                    )}
                                </span>
                            )}
                            {schedule.invoice_status && (
                                <span>
                                    {getBillStatusTag(
                                        schedule.invoice_status as BillStatus,
                                        "Bill:"
                                    )}
                                </span>
                            )}
                        </div>
                        {schedule.claim_status && (
                            <div className="mt-8">
                                {getStatusTag(
                                    schedule.claim_status as ClaimStatus,
                                    "Claim:"
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-[13px] mb-[19px]">
                {schedule.note_id && (
                    <Button
                        variant="normal"
                        size="auto"
                        className="w-full rounde-r8 border border-strokedark h-32 bg-white text-gray text-xs font-semibold"
                        onClick={() =>
                            navigate(
                                `/billing-and-claims/${schedule.client.client_id}/${schedule.note_id}`
                            )
                        }
                    >
                        View bill
                    </Button>
                )}
            </div>
            {schedule.recent_sessions.length > 0 && (
                <div>
                    <div className="flex items-start gap-x-12">
                        <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.24263 9.2931C10.7232 9.2931 12.734 7.3247 12.734 4.89655C12.734 2.4684 10.7232 0.5 8.24263 0.5C5.76209 0.5 3.75122 2.4684 3.75122 4.89655C3.75122 7.3247 5.76209 9.2931 8.24263 9.2931Z"
                                stroke="#576A81"
                                strokeLinecap="round"
                            />
                            <path
                                d="M1 16.0369C2.55463 13.7413 5.21833 12.2266 8.24255 12.2266"
                                stroke="#576A81"
                                strokeLinecap="round"
                            />
                            <path
                                d="M16.5367 17.5024H9.14087L11.3782 12.5748C11.474 12.3626 11.6884 12.2266 11.9255 12.2266H18.4004C18.8339 12.2266 19.1238 12.6627 18.9477 13.0508L17.0841 17.1542C16.9883 17.3664 16.7739 17.5024 16.5367 17.5024Z"
                                stroke="#576A81"
                                strokeLinecap="round"
                            />
                            <path
                                d="M9.14098 17.5H5.54785"
                                stroke="#576A81"
                                strokeLinecap="round"
                            />
                        </svg>

                        <div>
                            <div className="mb-[14px]">
                                <span className="text-sm font-semibold">
                                    Last 3 sessions
                                </span>
                            </div>
                            <div className="flex flex-col gap-y-8 mb-16">
                                {schedule.recent_sessions.map((session) => (
                                    <div
                                        key={`${session.note_id} ${session.insurance_amount}`}
                                        className="bg-card-bg rounded-r8 p-8 flex items-center gap-x-8 text-sm text-gray"
                                    >
                                        <span>
                                            {formatDate(
                                                session.date_of_service as string
                                            )}
                                        </span>{" "}
                                        •{" "}
                                        <span>
                                            {formatDate(
                                                session.date_of_service as string,
                                                false,
                                                "hh:mm a"
                                            )}
                                        </span>{" "}
                                        •<span>{session.cpt_code || "--"}</span>{" "}
                                        •
                                        <span
                                            className={cn({
                                                "text-danger":
                                                    session.insurance_amount_outstanding >
                                                    0,
                                            })}
                                        >
                                            <span className="font-semibold uppercase">
                                                INS{" "}
                                            </span>
                                            {session.insurance_amount > 0
                                                ? `(${showMoneyInAppFormat(
                                                      session.insurance_amount_outstanding
                                                  )})`
                                                : showMoneyInAppFormat(
                                                      Math.abs(
                                                          session.insurance_amount_outstanding
                                                      )
                                                  )}
                                        </span>
                                        •
                                        <span
                                            className={cn({
                                                "text-danger":
                                                    session.coinsurance_amount_outstanding >
                                                    0,
                                            })}
                                        >
                                            <span className="font-semibold uppercase">
                                                COINS{" "}
                                            </span>
                                            {session.coinsurance_amount_outstanding >
                                            0
                                                ? `(${showMoneyInAppFormat(
                                                      session.coinsurance_amount_outstanding
                                                  )})`
                                                : showMoneyInAppFormat(
                                                      Math.abs(
                                                          session.coinsurance_amount_outstanding
                                                      )
                                                  )}
                                        </span>
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            className="ml-4"
                                            onClick={() =>
                                                navigate(
                                                    `/billing-and-claims/${schedule.client.client_id}/${session.note_id}`
                                                )
                                            }
                                        >
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.4"
                                                    d="M15.375 16.5L2.625 16.5"
                                                    stroke="#292D32"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M14.25 2.625L3.75 13.125"
                                                    stroke="#292D32"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M14.25 10.3275L14.25 2.625L6.5475 2.625"
                                                    stroke="#292D32"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <Button
                                variant="normal"
                                size="auto"
                                className="w-full rounde-r8 border border-strokedark h-32 bg-white text-gray text-xs font-semibold"
                                onClick={() =>
                                    navigate(
                                        `/clients/${schedule.client.client_id}?tab=claims`
                                    )
                                }
                            >
                                View payment History
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </article>
    );
}
