import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Tabs } from "@jhool-io/fe-components";
import HarmonizedView from "../../../../shared-ui/HarmonizedView/HarmonizedView";
import { cn } from "../../../../utils/helpers";
import NoteItemsWrapper from "../NoteItemsWrapper/NoteItemsWrapper";
import SingleNoteActions from "../SingleNoteActions/SingleNoteActions";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import PaymentTabWrapper from "../../../billing/components/PaymentTabWrapper/PaymentTabWrapper";
import ClaimsWrapper from "../../../claims/components/ClaimsWrapper/ClaimsWrapper";
import RemitsWrapper from "../../../remits/components/RemitsWrapper/RemitsWrapper";
import { useFetchRemitClaims } from "../../../remits/hooks/remits.queries";

export default function SingleNoteView() {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const { clientId, noteId } = params;

    const tabInUrl = searchParams.get("tab") || "note";

    const { data } = useFetchSessionNote(
        clientId as string,
        noteId as string,
        Boolean(clientId) && Boolean(noteId)
    );

    const handleSetTabInUrl = (tab: string) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
    };

    const remitsData = useFetchRemitClaims({
        note_id: noteId,
    });

    const tabItems = [
        {
            label: "Session Note",
            key: "note",
            children: (
                <div className="mt-24">
                    <NoteItemsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("note"),
        },
        {
            label: "Claims",
            key: "claims",
            children: (
                <div className="mt-24">
                    <ClaimsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("claims"),
            disabled: !data?.data?.claim_id,
        },
        {
            label: "Payments History",
            key: "payments",
            children: (
                <div className="mt-24">
                    <PaymentTabWrapper />
                </div>
            ),
            disabled: data?.data.is_archived && !data?.data.invoice_id,
        },
        {
            key: "remit",
            label: "Remits",
            children: (
                <div className="mt-24">
                    <RemitsWrapper />
                </div>
            ),
            onClick: () => handleSetTabInUrl("remit"),
            disabled:
                !remitsData?.data?.data || remitsData?.data?.data?.length === 0,
        },
    ];

    return (
        <>
            <div>
                <HarmonizedView topRightAction={<SingleNoteActions />} />
            </div>
            <div className="mt-32">
                <Tabs
                    items={tabItems}
                    className={cn("!w-[100%] !mb-0 !items-end")}
                    defaultActiveKey={tabInUrl}
                    size="small"
                />
            </div>
        </>
    );
}
