import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IRefundPayment,
    IAddPaymentToMatch,
    IChargeClient,
    IDeleteUnMatchedPayment,
    IDeleteUnMatchedPaymentReponse,
    IMatchedPaymentResponse,
    IRefreshedPaymentStatusResponse,
    IRefreshPaymentStatus,
    IRefundPaymentResponse,
    IUnmatchPayment,
    IUnmatchPaymentResponse,
    RefundPaymentApiResponse,
    IUpdatedInvoiceResponse,
    IUpdateInvoice,
    IAddPaymentResponse,
    IAddInvoicePayment,
    IUpdateInvoiceCharge,
    IUpdateInvoiceChargeResponse,
    IRematchPayment,
    IAddPayment,
    IBulkPayment,
    IBulkPaymentResponse,
    ISplitPaymentPayload,
    ISplitPaymentResponse,
    IInvoiceNotesResponse,
    IMovePayment,
    IMovePaymentResponse,
    IUpdatePayment,
    IUpdatePaymentResponse,
} from "../../utils/types/billing";
import {
    addInvoicePayment,
    bulkPayInvoices,
    chargeClient,
    createInvoiceNote,
    deleteUnMatchedPayment,
    matchPayment,
    movePayment,
    patchInvoiceStatus,
    refreshPaymentStatus,
    refundPayment,
    rematchPayment,
    splitPayment,
    unMatchPayment,
    updateInvoiceCharge,
    updatePayment,
} from "../../services/billing";
import { IApiResponse } from "../../utils/types/api-response";

// match unmatched payment
export function useAddUnmatchedPayment() {
    return useMutation<
        IApiResponse<IMatchedPaymentResponse>,
        AxiosError<IApiResponse<IMatchedPaymentResponse>>,
        IAddPaymentToMatch
    >({
        mutationKey: ["match-payment"],
        mutationFn: (payload: IAddPaymentToMatch) => matchPayment(payload),
    });
}

//  delete unmatched payment
export function useDeleteUnMatchedPayment() {
    return useMutation<
        IApiResponse<IDeleteUnMatchedPaymentReponse>,
        AxiosError<IApiResponse<IDeleteUnMatchedPaymentReponse>>,
        IDeleteUnMatchedPayment
    >({
        mutationKey: ["delete-payment"],
        mutationFn: (payload: IDeleteUnMatchedPayment) =>
            deleteUnMatchedPayment(payload),
    });
}

//  delete matched payment
export function useUnMatchPayment() {
    return useMutation<
        IApiResponse<IUnmatchPaymentResponse>,
        AxiosError<IApiResponse<IUnmatchPaymentResponse>>,
        IUnmatchPayment
    >({
        mutationKey: ["unmatch-payment"],
        mutationFn: (payload: IUnmatchPayment) => unMatchPayment(payload),
    });
}
// move payment
export function useMovePayment() {
    return useMutation<
        IApiResponse<IMovePaymentResponse>,
        AxiosError<IApiResponse<IMovePaymentResponse>>,
        IMovePayment
    >({
        mutationKey: ["move-payment"],
        mutationFn: (payload: IMovePayment) => movePayment(payload),
    });
}

// refund payment
export function useRefundPayment() {
    return useMutation<
        RefundPaymentApiResponse<IRefundPaymentResponse>,
        AxiosError<RefundPaymentApiResponse<IRefundPaymentResponse>>,
        IRefundPayment
    >({
        mutationKey: ["refund-payment"],
        mutationFn: (payload: IRefundPayment) => refundPayment(payload),
    });
}

// refresh payment invoice status
export function useRefreshPaymentStatus() {
    return useMutation<
        IApiResponse<IRefreshedPaymentStatusResponse>,
        AxiosError<IApiResponse<IRefreshedPaymentStatusResponse>>,
        IRefreshPaymentStatus
    >({
        mutationFn: (payload: IRefreshPaymentStatus) =>
            refreshPaymentStatus(payload),
    });
}

export function useChargeClient(clientId: string) {
    interface IChargeClientResponse {
        message: string;
    }
    return useMutation<
        IApiResponse<IChargeClientResponse>,
        AxiosError<IApiResponse<IChargeClientResponse>>,
        IChargeClient
    >({
        mutationKey: ["charge-client"],
        mutationFn: (payload: IChargeClient) => chargeClient(clientId, payload),
    });
}
// update invoice status
export function useUpdateInvoiceStatus() {
    return useMutation<
        IApiResponse<IUpdatedInvoiceResponse>,
        AxiosError<IApiResponse<IUpdatedInvoiceResponse>>,
        IUpdateInvoice
    >({
        mutationKey: ["update-invoice"],
        mutationFn: (payload: IUpdateInvoice) => patchInvoiceStatus(payload),
    });
}

// add invoice payment
export function useAddInvoicePayment(invoiceId: string) {
    return useMutation<
        IApiResponse<IAddPaymentResponse>,
        AxiosError<IApiResponse<IAddPaymentResponse>>,
        IAddInvoicePayment
    >({
        mutationKey: ["add-payment", invoiceId],
        mutationFn: (payload: IAddInvoicePayment) =>
            addInvoicePayment(invoiceId, payload),
    });
}

export function useUpdateInvoiceCharge() {
    return useMutation<
        IApiResponse<IUpdateInvoiceChargeResponse>,
        AxiosError<IApiResponse<IUpdateInvoiceChargeResponse>>,
        IUpdateInvoiceCharge
    >({
        mutationKey: ["update-charge"],
        mutationFn: (payload: IUpdateInvoiceCharge) =>
            updateInvoiceCharge(payload),
    });
}

export function useUpdatePayment() {
    return useMutation<
        IApiResponse<IUpdatePaymentResponse>,
        AxiosError<IApiResponse<IUpdatePaymentResponse>>,
        IUpdatePayment
    >({
        mutationKey: ["update-payment"],
        mutationFn: (payload) => updatePayment(payload),
    });
}

export function useRematchPayment() {
    return useMutation<
        IApiResponse<IAddPayment>,
        AxiosError<IApiResponse<IAddPayment>>,
        IRematchPayment
    >({
        mutationKey: ["rematch-payment"],
        mutationFn: (payload: IRematchPayment) => rematchPayment(payload),
    });
}

export function useBulkPayInvoices() {
    return useMutation<
        IApiResponse<IBulkPaymentResponse>,
        AxiosError<IApiResponse<IBulkPaymentResponse>>,
        IBulkPayment
    >({
        mutationKey: ["bulk-pay-invoices"],
        mutationFn: (payload: IBulkPayment) => bulkPayInvoices(payload),
    });
}

export function useSplitPayment() {
    return useMutation<
        IApiResponse<ISplitPaymentResponse>,
        AxiosError<IApiResponse<ISplitPaymentResponse>>,
        ISplitPaymentPayload
    >({
        mutationKey: ["split-payment"],
        mutationFn: (payload: ISplitPaymentPayload) => splitPayment(payload),
    });
}

// create invoice note
export function useCreateInvoiceNote() {
    return useMutation<
        IApiResponse<IInvoiceNotesResponse>,
        AxiosError<IApiResponse<IInvoiceNotesResponse>>,
        { invoice_id: string; description: string }
    >({
        mutationKey: ["add-invoice-note"],
        mutationFn: (payload) =>
            createInvoiceNote(payload.invoice_id, payload.description),
    });
}
