/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
    Button,
    Dialog,
    DialogContent,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import { useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";

import { useGetPayers } from "../../hooks/payers.queries";
import { IPayer } from "../../types/payers.types";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { useDebounce } from "../../../../hooks/helpers";
import { formatDate } from "../../../../utils/helpers";
import PayerDetails from "../PayerDetails/PayerDetails";
import AddNewPayer from "../AddNewPayer/AddNewPayer";

export default function PayersList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [internalUserRowSelection, setInternalUserRowSelection] =
        React.useState({});
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    // get filters from params
    const nameFilter = searchParams.get("name") || "";

    // use debounce hook for name filter input
    const debouncedName = useDebounce(nameFilter || "", 500);

    // name onChange Fn
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("name");
        else searchParams.set("name", e.target.value);

        setSearchParams(searchParams);
    };

    // fetch all payers
    const { data, error, isLoading, isSuccess } = useGetPayers({
        insurance_provider_name: debouncedName,
    });

    // table columns
    const columns: ColumnDef<IPayer>[] = [
        {
            accessorKey: "name",
            header: "INSURANCE PROVIDER",
            cell: ({ row }) => (
                <span className="inline-block max-w-[240px] uppercase">
                    {row.original.name}
                </span>
            ),
        },
        {
            accessorKey: "alias",
            header: "PAYER SHORT CODE",
            cell: ({ row }) => (
                <span className="inline-block max-w-[240px] uppercase">
                    {row.original.alias ? row.original.alias : "--"}
                </span>
            ),
        },
        {
            accessorKey: "payer_id",
            header: "PAYER ID",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.payer_id ?? "--"}
                </span>
            ),
        },
        {
            accessorKey: "attachment_payer_id",
            header: "ATTACHMENT PAYER ID",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.attachment_payer_id
                        ? row.original.attachment_payer_id
                        : "--"}
                </span>
            ),
        },
        {
            accessorKey: "attachement_payer_name",
            header: "ATTACHMENT PAYER NAME",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.attachment_payer_name || "--"}
                </span>
            ),
        },
        {
            accessorKey: "payer_name",
            header: "PAYER Name",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.payer_name ?? "--"}
                </span>
            ),
        },
        {
            accessorKey: "payer_enrolled",
            header: "PAYER ENROLLED",
            cell: ({ row }) => (
                <span className="uppercase">
                    {row.original.payer_enrolled ? "Yes" : "No"}
                </span>
            ),
        },
        {
            accessorKey: "updated_at",
            header: "UPDATED AT",
            cell: ({ row }) => (
                <span className="uppercase">
                    {formatDate(row.original.updated_at)}
                </span>
            ),
        },
    ];

    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [showAddModal, setShowAddModal] = React.useState(false);
    const [selectedPayer, setSelectedPayer] = React.useState<IPayer>();
    const [isEditView, setIsEditView] = React.useState(false);

    const isAddingPayerMutating = useIsMutating(["create_payer"]);

    return (
        <>
            {/* edit/view existing payer dialog */}
            {selectedPayer ? (
                <Dialog open={showDetailsModal}>
                    <DialogContent
                        showFooter={false}
                        title="Payer details"
                        handleCloseDialog={() => {
                            setShowDetailsModal(false);
                            setIsEditView(false);
                        }}
                        onCancelClick={() => setIsEditView(false)}
                        classNames="px-0"
                        wrapperClass="w-[750px]"
                    >
                        <PayerDetails
                            selectedPayer={selectedPayer}
                            isEditView={isEditView}
                            setIsEditView={setIsEditView}
                            onFormSubmit={() => {
                                setShowDetailsModal(false);
                                setIsEditView(false);
                                setSelectedPayer(undefined);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            ) : null}

            {/* add new payer dialog */}
            <Dialog open={showAddModal}>
                <DialogContent
                    variant="center"
                    title="Create New Payer"
                    submitBtnFormValue="add-payer-form"
                    handleCloseDialog={() => setShowAddModal(false)}
                    isSubmitBtnDisabled={isAddingPayerMutating > 0}
                    classNames="px-0"
                    wrapperClass="w-[750px]"
                >
                    <AddNewPayer onFormSubmit={() => setShowAddModal(false)} />
                </DialogContent>
            </Dialog>

            <div className="rounded-r8 bg-white py-16 px-12 mb-32">
                <div className="flex items-center justify-between">
                    <div className="w-full flex md:w-[75%] h-[4.8rem] mb-0">
                        <SearchInput
                            placeholder="Search by name.."
                            containerClass="w-[400px] max-w-full"
                            value={nameFilter}
                            onChange={handleNameChange}
                        />
                    </div>

                    <Button onClick={() => setShowAddModal(true)}>
                        Add New Payer
                    </Button>
                </div>
            </div>

            <div className="mt-4 md:mt-12">
                {isLoading ? (
                    <Skeleton
                        type="table"
                        containerTestId="payerslist-loader"
                        count={6}
                    />
                ) : null}

                {error && error?.response?.status !== 404 && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg="Cannot display payers list at this time. Please try again later"
                    />
                )}

                {data?.data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Payers will appear here once they are added"
                        isError={false}
                        emptyMessage="No payer added yet"
                    />
                )}

                {isSuccess && data?.data && data.data.length > 0 ? (
                    <div>
                        <Table
                            columns={columns}
                            data={data?.data}
                            rowSelection={internalUserRowSelection}
                            setRowSelection={setInternalUserRowSelection}
                            setSortConfig={setSortConfig}
                            sortConfig={sortConfig}
                            handleRowClick={(row) => {
                                setSelectedPayer(row.original as IPayer);
                                setShowDetailsModal(true);
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
}
