import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
    IBatchRemit,
    IBatchRemitFilters,
    IRemit,
    IRemitDocument,
    IRemitsFilters,
} from "../types/remits.types";
import {
    IApiResponse,
    IPaginatedApiResponse,
} from "../../../utils/types/api-response";
import { handleRemoveEmptyParamFromQueryParams } from "../../../utils/helpers";
import {
    fetchBatchRemits,
    fetchRemitClaims,
    fetchSingleRemitDocuments,
} from "../services/remits.services";
import { useDoesUserHaveRole } from "../../../hooks/permissions";
import { UserRole } from "../../user-management/types/user-management.types";

export function useFetchBatchRemits(
    filters: IBatchRemitFilters,
    enabled?: boolean
) {
    return useQuery<
        IPaginatedApiResponse<IBatchRemit[]>,
        AxiosError<IApiResponse<IBatchRemit[]>>
    >({
        queryKey: [
            "batch-remits",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],
        queryFn: () => fetchBatchRemits(filters),
        enabled,
    });
}

export function useFetchRemitClaims(
    filters: IRemitsFilters,
    enabled?: boolean
) {
    const userHasRequiredRole = useDoesUserHaveRole([
        UserRole.SUPER_ADMIN,
        UserRole.BILLER,
        UserRole.BILLER_ADMIN,
        UserRole.CODER,
        UserRole.CLIENT_SUPPORT,
    ]);
    return useQuery<
        IPaginatedApiResponse<IRemit[]>,
        AxiosError<IApiResponse<IRemit[]>>
    >({
        queryKey: [
            "remit-claims",
            handleRemoveEmptyParamFromQueryParams(filters),
        ],

        queryFn: () => fetchRemitClaims(filters),
        enabled: enabled ?? userHasRequiredRole,
    });
}

// Get remit EOB
export function useFetchSingleRemitEOB(remitId: string, enabled?: boolean) {
    return useQuery<{ data: string }, AxiosError<{ data: string }>>({
        queryKey: ["remit-eob", remitId],
        queryFn: () => fetchSingleRemitDocuments(remitId),
        enabled,
    });
}

// Get remit files
export function useFetchSingleRemitDocuments(
    remitId: string,
    enabled?: boolean
) {
    return useQuery<
        IApiResponse<IRemitDocument[]>,
        AxiosError<IApiResponse<IRemitDocument[]>>
    >({
        queryKey: ["remit-docs", remitId],
        queryFn: () => fetchSingleRemitDocuments(remitId, true),
        enabled,
    });
}
