import * as React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    Button,
    Dialog,
    DialogContent,
    Pagination,
    Table,
} from "@jhool-io/fe-components";
import DocumentViewer from "../../../../components/DocumentViewer/DocumentViewer";
import FilePicker from "../../../../components/FilePicker/FilePicker";
import ListState from "../../../../components/ListState/ListState";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import useToast from "../../../../hooks/useToast";
import {
    removeEnumUnderscore,
    formatDate,
    makeStringFirstLetterCapital,
} from "../../../../utils/helpers";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import { SupportedUploadMethod } from "../../../../utils/types/filepicker";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import { IClientDocument } from "../../types/clients.types";
import { useDeleteClientDocument } from "../../../../hooks/mutations/client";
import { useFetchDocuments } from "../../hooks/clients.queries";
import MobileListCard from "../../../../shared-ui/MobileListCard/MobileListCard";
import TrashIcon from "../../../../components/Icons/Trash";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import { useAppSelector } from "../../../../hooks/useRedux";

type DocumentTabDialogTypes =
    | "view-document"
    | "upload-document"
    | "delete-confirmation"
    | "view-uploaded-document";

export default function DocumentsList() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Component local states
    const [modalInView, setModalInView] =
        React.useState<DocumentTabDialogTypes | null>(null);
    const [documentInViewDetails, setDocumentInViewDetails] =
        React.useState<IClientDocument | null>(null);
    const [documentToDeleteId, setDocumentToDeleteId] = React.useState<
        string | null
    >(null);

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    // check if logged in user can add or upload client documents
    const canAddDocument = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_DOCUMENT_WRITE,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
    ]);

    const mobileListRef = React.useRef<HTMLDivElement>(null);

    const entry = useIntersectionObserver(mobileListRef, { threshold: 0.2 });
    const isVisible = !!entry?.isIntersecting;

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client documents
    const { data, isLoading, isSuccess, error } = useFetchDocuments({
        client_id: clientId,
        page: pageFilter,
        limit: limitFilter,
    });

    // Initialize delete document hook
    const deleteDocument = useDeleteClientDocument(
        clientId,
        documentToDeleteId || ""
    );

    // Toast hook
    const { toast } = useToast();

    const { practice } = useAppSelector((state) => state.userPractice);

    // Query client
    const queryClient = useQueryClient();

    // Function to set modal in view
    const handleSetModalInView = (modal: DocumentTabDialogTypes | null) => {
        setModalInView(() => modal);
    };

    // Function to close modal
    const handleCloseModal = () => {
        handleSetModalInView(null);
        if (documentInViewDetails) setDocumentInViewDetails(null);
        if (documentToDeleteId) setDocumentToDeleteId(null);
    };

    // Function to determine which component to render inside modal
    const handleCurrentModalToShow = () => {
        switch (modalInView) {
            case "upload-document":
                return (
                    <FilePicker
                        name="filepicker"
                        uploadMethods={[
                            {
                                method: SupportedUploadMethod.manualupload,
                            },
                            {
                                method: SupportedUploadMethod.googledrive,
                            },
                            {
                                method: SupportedUploadMethod.dropbox,
                            },
                        ]}
                        uploadType="documents"
                        successCallback={() => {
                            setModalInView(null);
                        }}
                    />
                );

            case "view-document":
                if (documentInViewDetails) {
                    return (
                        <DocumentViewer
                            document={documentInViewDetails}
                            file={documentInViewDetails?.url as string}
                        />
                    );
                }
                return null;

            default:
                return null;
        }
    };

    // Function to call for deleting document
    const handleDeleteDocument = () => {
        deleteDocument.mutate(null, {
            onSuccess: (res) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IClientDocument[]>
                >(
                    [
                        clientId,
                        `documents`,
                        {
                            client_id: clientId,
                            page: pageFilter,
                            limit: limitFilter,
                        },
                    ],
                    (prev) => {
                        const prevIsNotUndefined =
                            prev as IPaginatedApiResponse<IClientDocument[]>;

                        if (prevIsNotUndefined.count === 1) {
                            return {
                                count: 0,
                                data: [],
                                current_page: 0,
                                total_count: 0,
                                message: "",
                                total_pages: 0,
                            };
                        }

                        return {
                            ...prevIsNotUndefined,
                            data: prevIsNotUndefined?.data.filter(
                                (item) =>
                                    item.document_id !== documentToDeleteId
                            ),
                        };
                    }
                );

                queryClient.invalidateQueries({
                    queryKey: [clientId, `documents`, pageFilter],
                });

                toast({
                    mode: "success",
                    message: res.message || "Document deleted successfully",
                });

                setModalInView(null);
            },
            onError(err) {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "Could not delete document, please try again",
                });
            },
        });
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    // onChange handler for limit
    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    // Table columns
    const columns: ColumnDef<IClientDocument>[] = React.useMemo(() => {
        const handleSetDocumentInViewDetails = (
            document: IClientDocument | null
        ) => {
            handleSetModalInView("view-document");
            setDocumentInViewDetails(document);
        };

        const handleSetDocumentToDeleteId = (id: string) => {
            handleSetModalInView("delete-confirmation");
            setDocumentToDeleteId(id);
        };
        return [
            {
                accessorKey: "title",
                header: "TITLE",
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: ({ row }) => (
                    <Button
                        className="p-0 underline font-normal capitalize text-primary"
                        variant="normal"
                        aria-label="title button"
                        onClick={() =>
                            handleSetDocumentInViewDetails(row.original)
                        }
                    >
                        {row.original.title}
                    </Button>
                ),
            },
            {
                accessorKey: "type",
                header: "TYPE",
                cell: ({ row }) => removeEnumUnderscore(row.original.type),
            },
            {
                header: "UPLOADED BY",
                cell: ({ row }) =>
                    `${row.original.uploaded_by.first_name} ${row.original.uploaded_by.last_name}`,
            },
            {
                accessorKey: "uploaded_date",
                header: "DATE ",
                cell: ({ row }) => formatDate(row.original.uploaded_date),
            },
            {
                accessorKey: "document_id",
                header: "",
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: ({ row }) =>
                    !practice?.roles?.includes(UserRole.PROVIDER) ? (
                        <Button
                            type="button"
                            variant="normal"
                            aria-label="delete button"
                            onClick={() =>
                                handleSetDocumentToDeleteId(
                                    row.original.document_id
                                )
                            }
                        >
                            <TrashIcon stroke="#0B132B" />
                        </Button>
                    ) : null,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Dialog
                open={
                    modalInView === "view-document" ||
                    modalInView === "upload-document"
                }
            >
                <DialogContent
                    title={
                        modalInView === "view-document"
                            ? "View Document"
                            : "Upload document"
                    }
                    handleCloseDialog={handleCloseModal}
                    showFooter={false}
                    variant={
                        modalInView === "upload-document" ? "center" : "drawer"
                    }
                    wrapperClass={
                        modalInView === "view-document"
                            ? "w-[900px]"
                            : undefined
                    }
                >
                    <>{handleCurrentModalToShow()}</>
                </DialogContent>
            </Dialog>
            <Dialog open={modalInView === "delete-confirmation"}>
                <DialogContent
                    title="Delete document"
                    isDeleting
                    variant="center"
                    handleCloseDialog={handleCloseModal}
                    saveText="Yes, delete file"
                    cancelText="Cancel"
                    isSubmitBtnDisabled={deleteDocument.isLoading}
                    isCancelBtnDisabled={deleteDocument.isLoading}
                    onSaveClick={handleDeleteDocument}
                    showFooter
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            Are you sure you want to delete this document?
                        </h3>
                        <span className="text-sm">
                            By deleting this document, you won’t be able to
                            recover it.
                        </span>
                    </>
                </DialogContent>
            </Dialog>

            <div className="hidden sm:flex justify-between items-center bg-white px-24 py-[29px] mb-16 rounded-r8 border border-strokedark">
                <span className=" text-base font-bold">Uploaded Documents</span>
                {canAddDocument && (
                    <Button
                        onClick={() => handleSetModalInView("upload-document")}
                        aria-label="Upload document"
                    >
                        Upload document
                    </Button>
                )}
            </div>

            <div className="block sm:hidden">
                {canAddDocument && (
                    <Button
                        onClick={() => handleSetModalInView("upload-document")}
                        className="w-full mb-24"
                    >
                        Upload document
                    </Button>
                )}

                <h3 className="uppercase mb-16 font-medium text-sm text-black">
                    UPLOADED DOCUMENTS
                </h3>
            </div>

            {isLoading && (
                <Skeleton
                    type="table"
                    containerTestId="documents-loader"
                    count={6}
                />
            )}

            {error && (
                <ListState
                    isError
                    listHeader="UPLOADED DOCUMENTS"
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display client documents at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data.data.length === 0 && (
                <ListState
                    listHeader="UPLOADED DOCUMENTS"
                    isError={false}
                    stateHelperText="Client documents will appear here when added"
                    emptyMessage="No documents yet"
                    emptyBtnProps={{
                        showButton: canAddDocument,
                        buttonText: "Add document",
                        onButtonClick: () =>
                            handleSetModalInView("upload-document"),
                    }}
                />
            )}
            {data && isSuccess && data.data.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        <Table
                            data={data.data}
                            columns={columns}
                            hasPagination={data.total_count > 20}
                            className="capitalize"
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    onLimitChange={handleLimitChange}
                                    limit={limitFilter}
                                />
                            }
                        />
                    </div>
                    <div
                        className="block sm:hidden"
                        role="table"
                        ref={mobileListRef}
                    >
                        {data.data.map((document) => (
                            <MobileListCard
                                key={document.document_id}
                                className="mb-12"
                            >
                                <Button
                                    className="text-sm text-left font-semibold underline capitalize p-0 text-primary"
                                    variant="normal"
                                    aria-label="title button"
                                    onClick={() => {
                                        handleSetModalInView("view-document");
                                        setDocumentInViewDetails(document);
                                    }}
                                >
                                    {document.title}
                                </Button>

                                <div className="flex flex-col gap-y-8 p-12 rounded-r8 bg-card-bg">
                                    <div className="flex gap-x-12 text-xs font-semibold">
                                        <span className=" text-gray">
                                            Type:
                                        </span>
                                        {removeEnumUnderscore(
                                            makeStringFirstLetterCapital(
                                                document.type
                                            )
                                        )}
                                    </div>
                                    <div className="flex gap-x-12 text-xs font-semibold">
                                        <span className=" text-gray">
                                            Date uploaded:
                                        </span>
                                        {formatDate(document.uploaded_date)}
                                    </div>
                                    <div className="flex justify-between items-center text-xs font-semibold">
                                        <span className="flex gap-x-12">
                                            <span className=" text-gray">
                                                Uploaded by:
                                            </span>
                                            <span>
                                                {`${document.uploaded_by.first_name} ${document.uploaded_by.last_name}`}
                                            </span>
                                        </span>
                                        <Button
                                            variant="normal"
                                            onClick={() => {
                                                handleSetModalInView(
                                                    "delete-confirmation"
                                                );
                                                setDocumentToDeleteId(
                                                    document.document_id
                                                );
                                            }}
                                        >
                                            <TrashIcon stroke="#0B132B" />
                                        </Button>
                                    </div>
                                </div>
                            </MobileListCard>
                        ))}
                        {data.total_pages > 1 && isVisible && (
                            <Pagination
                                totalCount={data.total_count}
                                totalPages={data.total_pages}
                                currentPage={pageFilter}
                                onPageChange={handlePageChange}
                                limit={limitFilter}
                                onLimitChange={handleLimitChange}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
