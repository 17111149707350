import { formatDate } from "../../../../utils/helpers";
import { SAFETY_PLAN_NOTE_LABELS } from "../../../../utils/constants";
import { IAddendumDetails } from "../../../../utils/types/notes";
import NoteContentItem from "../NoteContentItem/NoteContentItem";

interface ViewSafetyPlanNoteProps {
    noteContent: { [key: string]: string };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}

export default function ViewSafetyPlanNote({
    noteContent,
    fieldAddendums,
}: ViewSafetyPlanNoteProps) {
    const followUpDate =
        noteContent.when_should_follow_up_be_done_for_this_client
            ? formatDate(
                  noteContent.when_should_follow_up_be_done_for_this_client,
                  true
              )
            : "--";

    return (
        <>
            <NoteContentItem
                headerText={`Step 1: ${SAFETY_PLAN_NOTE_LABELS.warning_signs}`}
                fieldId="warning_signs"
                addendums={fieldAddendums?.warning_signs}
                content={noteContent.warning_signs || "--"}
            />
            <NoteContentItem
                headerText={`Step 2: ${SAFETY_PLAN_NOTE_LABELS.internal_coping_strategies}`}
                fieldId="internal_coping_strategies"
                addendums={fieldAddendums?.internal_coping_strategies}
                content={noteContent.internal_coping_strategies || "--"}
            />
            <NoteContentItem
                headerText={`Step 3: ${SAFETY_PLAN_NOTE_LABELS.people_and_social_settings_that_provide_distraction}`}
                fieldId="people_and_social_settings_that_provide_distraction"
                addendums={
                    fieldAddendums?.people_and_social_settings_that_provide_distraction
                }
                content={
                    noteContent.people_and_social_settings_that_provide_distraction ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`Step 4: ${SAFETY_PLAN_NOTE_LABELS.people_whom_i_can_ask_for_help}`}
                fieldId="people_whom_i_can_ask_for_help"
                addendums={fieldAddendums?.people_whom_i_can_ask_for_help}
                content={noteContent.people_whom_i_can_ask_for_help || "--"}
            />
            <NoteContentItem
                headerText={`Step 5: ${SAFETY_PLAN_NOTE_LABELS.professionals_or_agencies_i_can_contact_during_a_crisis}`}
                fieldId="professionals_or_agencies_i_can_contact_during_a_crisis"
                addendums={
                    fieldAddendums?.professionals_or_agencies_i_can_contact_during_a_crisis
                }
                content={
                    noteContent.professionals_or_agencies_i_can_contact_during_a_crisis ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={`Step 6: ${SAFETY_PLAN_NOTE_LABELS.making_the_environment_safe}`}
                fieldId="making_the_environment_safe"
                addendums={fieldAddendums?.making_the_environment_safe}
                content={noteContent.making_the_environment_safe || "--"}
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.the_one_thing_that_is_important_to_me_and_worth_living_for_is
                }
                fieldId="the_one_thing_that_is_important_to_me_and_worth_living_for_is"
                addendums={
                    fieldAddendums?.the_one_thing_that_is_important_to_me_and_worth_living_for_is
                }
                content={
                    noteContent.the_one_thing_that_is_important_to_me_and_worth_living_for_is ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.how_likely_is_the_client_to_use_this_safety_plan
                }
                fieldId="how_likely_is_the_client_to_use_this_safety_plan"
                addendums={
                    fieldAddendums?.how_likely_is_the_client_to_use_this_safety_plan
                }
                content={
                    noteContent.how_likely_is_the_client_to_use_this_safety_plan ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.how_likely_is_the_client_to_adhere_to_this_safety_plan
                }
                fieldId="how_likely_is_the_client_to_adhere_to_this_safety_plan"
                addendums={
                    fieldAddendums?.how_likely_is_the_client_to_adhere_to_this_safety_plan
                }
                content={
                    noteContent.how_likely_is_the_client_to_adhere_to_this_safety_plan ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.detail_hloc_options_and_next_steps
                }
                fieldId="detail_hloc_options_and_next_steps"
                addendums={fieldAddendums?.detail_hloc_options_and_next_steps}
                content={noteContent.detail_hloc_options_and_next_steps || "--"}
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis
                }
                fieldId="where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis"
                addendums={
                    fieldAddendums?.where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis
                }
                content={
                    noteContent.where_will_the_client_keep_the_safety_plan_and_how_will_it_be_located_during_a_crisis ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.is_the_format_appropriate_for_client_capacity_and_circumstances
                }
                fieldId="is_the_format_appropriate_for_client_capacity_and_circumstances"
                addendums={
                    fieldAddendums?.is_the_format_appropriate_for_client_capacity_and_circumstances
                }
                content={
                    noteContent.is_the_format_appropriate_for_client_capacity_and_circumstances ||
                    "--"
                }
            />
            <NoteContentItem
                headerText={
                    SAFETY_PLAN_NOTE_LABELS.when_should_follow_up_be_done_for_this_client
                }
                fieldId="when_should_follow_up_be_done_for_this_client"
                addendums={
                    fieldAddendums?.when_should_follow_up_be_done_for_this_client
                }
                content={followUpDate}
            />
            <NoteContentItem
                headerText={SAFETY_PLAN_NOTE_LABELS.frequency_of_sessions}
                fieldId="frequency_of_sessions"
                addendums={fieldAddendums?.frequency_of_sessions}
                content={noteContent.frequency_of_sessions || "--"}
            />
            {noteContent.other_frequency_of_sessions ? (
                <NoteContentItem
                    headerText={
                        SAFETY_PLAN_NOTE_LABELS.other_frequency_of_sessions
                    }
                    fieldId="other_frequency_of_sessions"
                    addendums={fieldAddendums?.other_frequency_of_sessions}
                    content={noteContent.other_frequency_of_sessions || "--"}
                />
            ) : null}
            <NoteContentItem
                headerText={SAFETY_PLAN_NOTE_LABELS.date_of_next_session}
                fieldId="date_of_next_session"
                addendums={fieldAddendums?.date_of_next_session}
                content={
                    noteContent.date_of_next_session
                        ? formatDate(noteContent.date_of_next_session, true)
                        : "--"
                }
            />
            <NoteContentItem
                headerText={SAFETY_PLAN_NOTE_LABELS.date_created}
                fieldId="date_created"
                addendums={fieldAddendums?.date_created}
                content={
                    noteContent.date_created
                        ? formatDate(noteContent.date_created, true)
                        : "--"
                }
            />
        </>
    );
}
