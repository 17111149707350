import * as React from "react";
import NoteContentItem from "../NoteContentItem/NoteContentItem";
import { IAddendumDetails } from "../../../../utils/types/notes";
import { formatDate } from "../../../../utils/helpers";
import { GENERAL_UPDATE_NOTE_LABELS } from "../../../../utils/constants";

interface ViewGeneralNotesProps {
    noteContent: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    fieldAddendums: {
        [key: string]: IAddendumDetails[];
    } | null;
}
export default function ViewGeneralNote({
    noteContent,
    fieldAddendums,
}: ViewGeneralNotesProps) {
    return (
        <>
            <NoteContentItem
                addendums={fieldAddendums?.update_note_content}
                headerText={GENERAL_UPDATE_NOTE_LABELS.update_note_content}
                fieldId="update_note_content"
                content={noteContent?.update_note_content || "--"}
            />
            <NoteContentItem
                headerText={GENERAL_UPDATE_NOTE_LABELS.date_of_update}
                fieldId="date_of_update"
                addendums={fieldAddendums?.date_of_update}
                content={
                    noteContent.date_of_update
                        ? formatDate(noteContent.date_of_update, true)
                        : "--"
                }
            />
        </>
    );
}
