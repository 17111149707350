/* eslint-disable react/no-unstable-nested-components */
import cn from "classnames";
import * as React from "react";
import { ColumnDef, ExpandedState, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
    Button,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Table,
    Tag,
    Tooltip,
} from "@jhool-io/fe-components";
import {
    buildSuccessMessage,
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    handleFormatDatePickerValue,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import {
    APP_COLORS,
    CPT_CODES,
    CPT_CODES_DESCRIPTION,
    LISTS_DEFAULT_LIMIT,
} from "../../../../utils/constants";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../../../hooks/permissions";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import {
    useUnMatchPayment,
    useUpdatePayment,
} from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import { IPaginatedApiResponse } from "../../../../utils/types/api-response";
import { getInvoicePayment } from "../../../../services/billing";
import {
    BillStatus,
    BillStatusLabels,
    IBillingResponse,
    IGetPaymentResponse,
    IPaymentBreakdown,
    IUnmatchPaymentResponse,
    PaymentModals,
    RemarkCodeDescription,
    WriteOffCodesDecription,
} from "../../../billing/types/billing.types";
import UpdatePaymentForm, {
    ISelectedPayment,
} from "../../../remits/components/UpdatePaymentForm/UpdatePaymentForm";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import EditIcon from "../../../../components/Icons/Edit";
import UnionUpIcon from "../../../../components/Icons/UnionUp";
import UnionDownIcon from "../../../../components/Icons/UnionDown";
import UnmatchedPaymentList from "../../../billing/components/UnmatchedPaymentList/UnmatchedPaymentList";
import PaymentActionForm from "../../../billing/components/PaymentActionForm/PaymentActionForm";
import InvoicePaymentForm from "./InvoicePaymentForm/InvoicePaymentForm";
import PaymentsOverview from "../../../billing/components/PaymentsOverview/PaymentsOverview";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import CreditCards from "../Insurance/CreditCards/CreditCards";
import { useFetchInvoices } from "../../../billing/hooks/queries/billing.queries";
import UpdateChargeForm from "../../../billing/components/UpdateChargeForm/UpdateChargeForm";
import SplitPaymentForm from "../../../billing/components/SplitPaymentForm/SplitPaymentForm";
import {
    getBillStatusTag,
    paymentDateToDisplay,
} from "../../../billing/helpers/billing.helpers";
import InfoCircleIcon from "../../../../components/Icons/InfoCircle";
import ChargeClient from "../../../billing/components/ChargeClientForm/ChargeClientForm";
import { usePostPayments } from "../../../remits/hooks/remits.mutations";
import ConfirmPaymentIcon from "../../../../components/Icons/ConfirmPayment";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";
import AddBulkWriteOffForm from "../../../billing/components/WriteOff/AddBulkWriteOffForm/AddBulkWriteOffForm";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { useUpdateInvoiceStatus } from "../../../payments/hooks/payments.mutations";
import PendingResolutionForm, {
    PendingResolutionMetaData,
} from "../../../billing/components/PendingResolutionForm/PendingResolutionForm";
import useShowInsuranceOverSelfPay from "../../../practice-settings/hooks/useShowInsuranceOverSelfPay";

interface InvoiceRowBreakdown {
    [invoiceId: string]: {
        expanded: boolean;
        response: IGetPaymentResponse | null;
        isLoading: boolean;
    };
}

export default function ClientClaimsList() {
    const [rowSelection, setRowSelection] = React.useState({});
    const [expandedRows, setExpandedRows] = React.useState<ExpandedState>({});
    const [modalInView, setModalInView] = React.useState<PaymentModals | null>(
        null
    );
    const [propsForPendingResolution, setPropsForPendingResolution] =
        React.useState<PendingResolutionMetaData | null>(null);
    const [showPendingReolutionDialog, setShowPendingResolutionDialog] =
        React.useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState<
        null | string
    >();
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IBillingResponse>[]>();
    const [showChargeModal, setShowChargeModal] = React.useState(false);

    const [invoiceRowBreakdown, setInvoiceRowBreakdown] =
        React.useState<InvoiceRowBreakdown>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const [paymentIdToUnmatch, setPaymentIdToUnmatch] = React.useState<
        string | null
    >(null);
    const [paymentDate, setPaymentDate] = React.useState<string | null>(null);
    const [selectedTransactionId, setSelectedTransactionId] = React.useState<
        string | null
    >(null);
    const [selectedPayment, setSelectedPayment] =
        React.useState<ISelectedPayment | null>(null);
    const [invoiceToUpdateId, setInvoiceToUpdateId] = React.useState<
        string | null
    >(null);
    const [chargeAmount, setChargeAmount] = React.useState<number | null>(null);
    const [chargeType, setChargeType] = React.useState<string | null>(null);
    const [paymentAmount, setPaymentAmount] = React.useState<number | null>(
        null
    );
    const [writeOffAction, setWriteOffAction] = React.useState<
        "move" | "close" | null
    >(null);

    // check if logged in user has permission to perform payment actions
    const canUserPerformAction = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const postPayments = usePostPayments();

    const changeWriteoffPaymentStatus = useUpdatePayment();

    const getBillStatus = () => {
        const invoiceStatusFilter = searchParams.getAll("invoice_status[]");

        if (invoiceStatusFilter.length > 0) {
            if (invoiceStatusFilter.includes("all")) {
                return [];
            }
            return invoiceStatusFilter;
        }

        return [];
    };

    const selectedBills = getSelectedData?.map((item) => item.original);

    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;
    const cptCodeFilter = searchParams.get("cpt_code") || "";
    // const invoiceStatusFilter = getBillStatus() || "";
    const invoiceStatusFilter = getBillStatus() || [];
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    const navigate = useNavigate();

    const showOpenInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.OPEN
            ).length;

    const showCloseInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.CLOSED
            ).length;

    const showPendingResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_RESOLUTION
            ).length;

    const showPendingInsuranceResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_INSURANCE_RESOLUTION
            ).length;

    const showPendingPatientResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_PATIENT_RESOLUTION
            ).length;

    const clientDetails = useFetchClientBasicAndPersonalInfo(clientId);

    const sumCoInsuranceAmount =
        (selectedBills &&
            selectedBills.reduce(
                (total: number, invoiceDetail: IBillingResponse) => {
                    return total + (invoiceDetail?.coinsurance_amount || 0);
                },
                0
            )) ||
        0;

    const canViewCreditCardInfo = useDoesUserHaveRole([
        UserRole.SUPER_ADMIN,
        UserRole.CLIENT_SUPPORT,
    ]);

    // check if logged in user can perform bulk actions
    const canPerformBulkActions = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    // check if logged in user can unmatch payments
    const canUnmatchPayments = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_INVOICE_WRITE,
    ]);

    const queryClient = useQueryClient();

    // Toast for success and error states
    const { toast } = useToast();

    const unmatchPayment = useUnMatchPayment();
    const updateInvoice = useUpdateInvoiceStatus();
    const isAddingPayment = useIsMutating(["add-payment"]);
    const updatePaymentIsMutating = useIsMutating(["update-payment"]);

    const confirmPaymentIsMutating = useIsMutating([
        "post-payment",
        paymentIdToUnmatch,
    ]);

    /**
     * Get client charge failed modal in view
     * Should be "prompt" | "pr" | null
     */
    const chargeModalInView = searchParams.get("cf_modal");

    const canSplitPayment = searchParams.get("split_payment");

    const chargeClientIsMutating = useIsMutating(["charge-client"]);
    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);
    const isUpdatingPayment = useIsMutating(["update-payment"]);
    const invoicePaymentIsMutating = useIsMutating(["bulk-pay-invoices"]);
    const isUpdatingCharge = useIsMutating(["update-charge"]);
    const splitPaymentIsMutating = useIsMutating(["split-payment"]);
    const bulkWriteOffIsMutating = useIsMutating(["bulk-invoice-payment"]);

    const paymentIsMutating = useIsMutating([
        "add-payment",
        selectedBills ? selectedBills[0]?.invoice_id : "",
    ]);

    const allowedRolesForUpdatingCharge = [
        UserRole.BILLER,
        UserRole.BILLER_ADMIN,
        UserRole.SUPER_ADMIN,
    ];

    const canUpdateChargeAmount = useDoesUserHaveRole(
        allowedRolesForUpdatingCharge
    );

    const handleModalInView = (obj: {
        modal: PaymentModals | null;
        paymentId?: string;
        transId?: string | null;
        invoiceId?: string | null;
        chargeAmount?: number | null;
        chargeType?: string | null;
        paymentAmount?: number | null;
        paymentDate?: string | null;
    }) => {
        setModalInView(obj.modal);

        if (obj.paymentId) setPaymentIdToUnmatch(obj.paymentId);
        else setPaymentIdToUnmatch(null);

        if (obj.transId) setSelectedTransactionId(obj.transId);
        else setSelectedTransactionId(null);

        if (obj.invoiceId) setInvoiceToUpdateId(obj.invoiceId);
        else setInvoiceToUpdateId(null);

        if (obj.chargeAmount) setChargeAmount(obj.chargeAmount);
        else setChargeAmount(null);

        if (obj.chargeType) setChargeType(obj.chargeType);
        else setChargeType(null);

        if (obj.paymentAmount) setPaymentAmount(obj.paymentAmount);
        else setChargeAmount(null);
        if (obj.paymentDate) setPaymentDate(obj.paymentDate);
        else setChargeAmount(null);

        setSearchParams(searchParams);
    };

    // payload to get invoice payment
    const getInvoicePayload = {
        invoice_id: selectedBills?.[0]?.invoice_id || selectedInvoiceId || "",
    };

    // Hook for fetching all invoice
    const { data, isLoading, isSuccess, error } = useFetchInvoices({
        cpt_code: cptCodeFilter,
        invoice_status: invoiceStatusFilter,
        from_date: fromDateFilter,
        to_date: toDateFilter,
        page: pageFilter,
        limit: limitFilter,
        client_id: clientId,
    });

    const showInsOverSelfPay = useShowInsuranceOverSelfPay();

    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleStatusChange = (status: string) => {
        if (invoiceStatusFilter.includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );

        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleCptCodeChange = (value: string) => {
        if (value === "") searchParams.delete("cpt_code");
        else searchParams.set("cpt_code", value);
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        localStorage.setItem(
            "clientclaimslist_filters",
            searchParams.toString()
        );
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const getWriteOffCodeDescription = (code: string) => {
        const writeOffDescriptions = Object.values(WriteOffCodesDecription);
        const matchingDescription = writeOffDescriptions?.find(
            (description) =>
                code?.toLowerCase() === description.split(":")[0]?.toLowerCase()
        );
        return matchingDescription || "--";
    };

    const getRemarkCodeDescription = (code: string): string => {
        const matchingDescription = RemarkCodeDescription[code.toLowerCase()];
        return matchingDescription || "--";
    };

    const getPaymentTypeTag = (
        type: string,
        method: string,
        info: {
            refund_reason: string;
            cheque_number: string;
            authorization_number: string;
        },
        writeoff_code?: string,
        auth_code?: string
    ) => {
        switch (type) {
            case "wbci":
            case "wbi":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={
                                <>
                                    Write-off:{" "}
                                    <span className="uppercase">
                                        {writeoff_code}
                                    </span>
                                </>
                            }
                        />
                        <Tooltip
                            content={getWriteOffCodeDescription(
                                writeoff_code || "--"
                            )}
                            classNames="uppercase"
                        >
                            <Button variant="normal" size="auto">
                                <InfoIcon />
                            </Button>
                        </Tooltip>
                    </div>
                );
            case "ri":
            case "rci":
                return (
                    <Tag
                        bgColor="rgba(204, 250, 233, 0.50)"
                        textColor="#00563E"
                        className="font-normal px-8 py-4 h-24 rounded-r4 whitespace-nowrap"
                        title={
                            <>
                                Refund:{" "}
                                {removeEnumUnderscore(info?.refund_reason) ??
                                    "Others"}
                            </>
                        }
                    />
                );
            case "i":
            case "ci":
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(247, 229, 164, 0.50)"
                            textColor="#634D1"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={<>Credit: {removeEnumUnderscore(method)}</>}
                        />
                        {info?.cheque_number && (
                            <Tooltip
                                content={`CHEQUE NUMBER - ${info.cheque_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {auth_code && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${auth_code}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
            case "pi":
                return (
                    <Tag
                        bgColor="rgba(172, 222, 250, 0.50)"
                        textColor="#165574"
                        className="font-normal px-8 py-4 h-24 rounded-r4"
                        title="Patient insurance"
                    />
                );

            default:
                return (
                    <div className="flex items-center gap-x-4 whitespace-nowrap">
                        <Tag
                            bgColor="rgba(229, 229, 229, 0.50)"
                            textColor="#0B132B"
                            className="capitalize flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                            title={type ?? "others"}
                        />
                        {info && (
                            <Tooltip
                                content={info.refund_reason}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                        {info?.authorization_number && (
                            <Tooltip
                                content={`AUTHORIZATION CODE - ${info.authorization_number}`}
                                classNames="uppercase"
                            >
                                <Button variant="normal" size="auto">
                                    <InfoIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                );
        }
    };

    const getPaymentStatusTag = (status: string) => {
        let bgColor: string;
        let textColor: string;

        switch (status) {
            case "completed":
                bgColor = "rgba(204, 250, 233, 0.50)";
                textColor = "#00563E";
                break;
            case "failed":
                bgColor = "rgba(251, 199, 198, 0.50)";
                textColor = "#981F41";
                break;
            default:
                bgColor = "rgba(247, 229, 164, 0.50)";
                textColor = "#634D17";
        }

        return (
            <Tag
                bgColor={bgColor}
                textColor={textColor}
                title={makeStringFirstLetterCapital(status)}
                className="capitalize rounded-r4 px-8 text-xxs"
            />
        );
    };

    const handleCloseWriteOffDialog = () => {
        handleModalInView({ modal: null });
        setSelectedPayment(null);
    };

    const handleMessageToDisplay = () => {
        if (fromDateFilterToDate && toDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? (
                            <Skeleton width="120px" />
                        ) : (
                            `${clientDetails.data?.data.first_name} ${clientDetails.data?.data.last_name}`
                        )
                    } between ${format(
                        fromDateFilterToDate,
                        "MMM d, yyyy"
                    )} and ${format(toDateFilterToDate, "MMM d, yyyy")} . `}
                </h4>
            );
        }

        if (fromDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? (
                            <Skeleton width="120px" />
                        ) : (
                            `${clientDetails.data?.data.first_name} ${clientDetails.data?.data.last_name}`
                        )
                    } from ${format(fromDateFilterToDate, "MMM d, yyyy")}.`}
                </h4>
            );
        }

        if (toDateFilterToDate) {
            return (
                <h4 className="text-base font-bold mb-24">
                    {`Showing bills for  ${
                        clientDetails.isLoading ? (
                            <Skeleton width="120px" />
                        ) : (
                            `${clientDetails.data?.data.first_name} ${clientDetails.data?.data.last_name}`
                        )
                    } to ${format(toDateFilterToDate, "MMM d, yyyy")}.`}
                </h4>
            );
        }

        return (
            <h4 className="text-base font-bold mb-24">
                {`Showing all bills for ${
                    clientDetails.isLoading ? (
                        <Skeleton width="120px" />
                    ) : (
                        `${clientDetails.data?.data.first_name} ${clientDetails.data?.data.last_name}`
                    )
                }. `}
            </h4>
        );
    };

    // function to match payment
    const handleUnMatchPayment = (paymentId: string) => {
        const unMatchPaymentPayload = {
            matched_payments: {
                [selectedInvoiceId as string]: [paymentId],
            },
        };
        unmatchPayment.mutate(unMatchPaymentPayload, {
            onSuccess: (response) => {
                queryClient.setQueryData<
                    IPaginatedApiResponse<IUnmatchPaymentResponse>
                >(["get-payment"], (prev) => {
                    const prevRequired =
                        prev as IPaginatedApiResponse<IUnmatchPaymentResponse>;
                    return {
                        ...prevRequired,
                        invoice_status: response.status,
                    };
                });
                queryClient.invalidateQueries({
                    queryKey: ["get-payment", getInvoicePayload],
                });
                handleModalInView({ modal: null });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.successful_payments,
                        response.data.failed_payments
                    )}`,
                });
            },
        });
    };

    const handleUpdateWriteoff = (payload: IPaymentBreakdown) => {
        const dataToSend = {
            payment_id: payload.payment_id,
            amount: payload.amount,
            payment_date: handleFormatDatePickerValue(payload.payment_date),
            payment_status: "pending",
        };
        changeWriteoffPaymentStatus.mutate(dataToSend, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });

                toast({
                    mode: "success",
                    message: "Writeoff status updated successfully",
                });
            },

            onError: () => {
                toast({
                    mode: "error",
                    message:
                        "Error updating writeoff status please try again later",
                });
            },
        });
    };

    const handlePostPayment = (id: string) => {
        postPayments.mutate(
            { payment_ids: [id] },
            {
                onSuccess(res) {
                    queryClient.invalidateQueries({
                        queryKey: ["get-payment"],
                    });
                    handleModalInView({ modal: null });
                    toast({
                        mode: "success",
                        message: res.message || "Payment posted successfully.",
                    });
                },
                onError(err) {
                    toast({
                        mode: "error",
                        message:
                            err.response?.data.message ||
                            "Could not post payment.",
                    });
                },
            }
        );
    };

    const handleGenerateStatement = () => {
        if (fromDateFilter && toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}&to_date=${searchParams.get("to_date")}`
            );
        } else if (fromDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?from_date=${searchParams.get(
                    "from_date"
                )}`
            );
        } else if (toDateFilter) {
            navigate(
                `/reports/billing-reports/${clientId}?to_date=${searchParams.get(
                    "to_date"
                )}`
            );
        } else {
            navigate(`/reports/billing-reports/${clientId}`);
        }
    };

    const getBreakdownPaymentSource = (paymentType: string) => {
        if (paymentType === "pr100") return "PR100";
        if (
            paymentType === "i" ||
            paymentType === "wbi" ||
            paymentType === "ri"
        ) {
            return "Insurance";
        }
        return "Co-Insurance";
    };

    // Function to charge modal search params
    const removeChargeModalParams = () => {
        searchParams.delete("cf_modal");
        setSearchParams(searchParams);
    };

    const handleCloseUnmatchPaymentsModal = () => {
        searchParams.delete("unmatch_page");
        searchParams.delete("payment_status");
        searchParams.delete("unmatch_limit");
        searchParams.delete("split_payment");
        handleModalInView({ modal: null });

        setSearchParams();
    };

    const setExpandedInvoice = (invoiceId: string, isExpanded: boolean) => {
        setInvoiceRowBreakdown((currentBreakDown) => {
            const clonedBreakdown = { ...currentBreakDown };

            const invoiceBreakdownRecord = clonedBreakdown[invoiceId] || {
                expanded: false,
                response: null,
                isLoading: false,
            };

            invoiceBreakdownRecord.expanded = isExpanded;

            if (isExpanded) {
                invoiceBreakdownRecord.isLoading = true;
                invoiceBreakdownRecord.response = null;
            }

            clonedBreakdown[invoiceId] = invoiceBreakdownRecord;

            return clonedBreakdown;
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshInvoiceBreakdownData = async ({
        refreshAllOpened,
        invoiceRowBreakdown_,
    }: {
        refreshAllOpened: boolean;
        invoiceRowBreakdown_: InvoiceRowBreakdown;
    }) => {
        const clonedInvoiceBreakdown = { ...invoiceRowBreakdown_ };
        const invoiceIdsToRefresh = Object.keys(clonedInvoiceBreakdown).filter(
            (invoiceId) =>
                refreshAllOpened
                    ? clonedInvoiceBreakdown[invoiceId].expanded
                    : clonedInvoiceBreakdown[invoiceId].expanded &&
                      clonedInvoiceBreakdown[invoiceId].response === null
        );

        if (invoiceIdsToRefresh.length > 0) {
            // Use Promise.all to make API requests for each invoiceId.
            await Promise.all(
                invoiceIdsToRefresh.map(async (invoiceId) => {
                    const response = await getInvoicePayment(
                        invoiceId,
                        getInvoicePayload
                    );

                    clonedInvoiceBreakdown[invoiceId].response = response;
                    clonedInvoiceBreakdown[invoiceId].isLoading = false;
                })
            );

            setInvoiceRowBreakdown(clonedInvoiceBreakdown);
        }
    };

    const renderRowSubComponent = ({ row }: { row: Row<IBillingResponse> }) => {
        const invoiceIdToRender = row.original.invoice_id as string;

        const matchingResponse:
            | {
                  response: IGetPaymentResponse;
              }
            | undefined =
            invoiceIdToRender in invoiceRowBreakdown
                ? {
                      response: invoiceRowBreakdown[invoiceIdToRender]
                          .response as IGetPaymentResponse,
                  }
                : undefined;

        return (
            <div>
                {invoiceRowBreakdown[invoiceIdToRender]?.isLoading && (
                    <Skeleton count={3} width="100%" />
                )}
                {matchingResponse &&
                    matchingResponse.response?.payment_breakdown === null && (
                        <div className="mr-10">
                            <ListState
                                isError={false}
                                stateHelperText="No payment breakdown available for this invoice"
                                emptyMessage="Payment breakdown will appear here when payments are added to the invoice"
                                wrapperClass="h-[80px]"
                                cardClass="my-8"
                            />
                        </div>
                    )}
                {matchingResponse &&
                    matchingResponse.response?.payment_breakdown && (
                        <div className="-ml-10 pt-12 bg-primary-50">
                            <div className="grid grid-cols-12 gap-12 items-end px-24 pb-3 text-gray text-xs font-semibold border-b border-secondary">
                                <span>PAYMENT DATE</span>
                                <span>SOURCE</span>
                                <span>PAYMENT STATUS</span>
                                <span className="col-[4_/_span_2]">TYPE</span>
                                <span>REMARK CODES</span>
                                <span>PAYMENT AMOUNT</span>
                                <span>EFT</span>
                                <span>POSTED BY</span>
                                <span>PAYMENT NOTES</span>
                                <span>DESCRIPTION</span>
                                <span />
                            </div>

                            {matchingResponse.response?.payment_breakdown.map(
                                (item) => (
                                    <div
                                        className="grid grid-cols-12 gap-12 items-center text-sm py-12 border-b border-secondary last:border-none px-24"
                                        key={item.payment_id}
                                    >
                                        <span>
                                            {paymentDateToDisplay(
                                                item.payment_date,
                                                item.payment_channel || ""
                                            )}
                                        </span>

                                        <span>
                                            {getBreakdownPaymentSource(
                                                item.payment_type
                                            )}
                                        </span>

                                        <span>
                                            {getPaymentStatusTag(
                                                item.payment_status
                                            )}
                                        </span>

                                        <div className="col-start-4 col-end-6 overflow-x-scroll overflow-y-hidden">
                                            {getPaymentTypeTag(
                                                item.payment_type,
                                                item.payment_method,
                                                item.payment_info,
                                                item.writeoff_code,
                                                item.auth_code
                                            )}
                                        </div>

                                        <div>
                                            {item.remark_codes &&
                                            item.remark_codes.length > 0 ? (
                                                <div className="flex gap-x-4 gap-y-8 flex-wrap items-center">
                                                    {item.remark_codes.map(
                                                        (code) => (
                                                            <div
                                                                key={code}
                                                                className="flex items-center gap-x-4"
                                                            >
                                                                <Tag
                                                                    bgColor="#F3F9FE"
                                                                    textColor="#059"
                                                                    className="uppercase flex items-center font-normal px-8 py-4 h-24 rounded-r4"
                                                                    title={code}
                                                                />
                                                                <Tooltip
                                                                    content={getRemarkCodeDescription(
                                                                        code
                                                                    )}
                                                                >
                                                                    <Button
                                                                        variant="normal"
                                                                        size="auto"
                                                                    >
                                                                        <InfoIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                "--"
                                            )}
                                        </div>

                                        {item.parent_payment_id &&
                                        item.payment_type !== "rci" ? (
                                            <Tooltip content="Split Payment">
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        columnGap: "5px",
                                                    }}
                                                >
                                                    {`${showMoneyInAppFormat(
                                                        item?.amount
                                                    )}`}
                                                    <InfoIcon />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <span>
                                                {`${showMoneyInAppFormat(
                                                    item?.amount
                                                )}`}
                                            </span>
                                        )}

                                        <span className="overflow-x-auto">
                                            {item.eft_number ? (
                                                <Link
                                                    to={`/remit/EOB/${item.remittance_id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary text-sm underline"
                                                >
                                                    {item.eft_number}
                                                </Link>
                                            ) : (
                                                "--"
                                            )}
                                        </span>

                                        <span>
                                            {Object.keys(item.posted_by)
                                                .length > 0
                                                ? `${item.posted_by.first_name} ${item.posted_by.last_name}`
                                                : "Mantle"}
                                        </span>

                                        <span className="flex flex-wrap">
                                            {item.payment_notes || "--"}
                                        </span>

                                        <span className="flex flex-wrap">
                                            {item.payment_description || "--"}
                                        </span>

                                        <span>
                                            {item.payment_type === "pi" &&
                                                item.payment_status ===
                                                    "pending" && (
                                                    <Tooltip content="Confirm Payment">
                                                        <Button
                                                            size="auto"
                                                            variant="normal"
                                                            onClick={() =>
                                                                handleModalInView(
                                                                    {
                                                                        modal: "confirm_payment",
                                                                        paymentId:
                                                                            item.payment_id,
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            <ConfirmPaymentIcon />
                                                        </Button>
                                                    </Tooltip>
                                                )}

                                            {item.payment_type !== "pi" && (
                                                <Dropdown>
                                                    <DropdownTrigger asChild>
                                                        <MoreButton />
                                                    </DropdownTrigger>

                                                    <DropdownContent
                                                        width="auto"
                                                        align="end"
                                                    >
                                                        {canUnmatchPayments &&
                                                            item.payment_type !==
                                                                "pi" &&
                                                            !item.has_split_payments && (
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleModalInView(
                                                                            {
                                                                                modal: "unmatch_payment",
                                                                                paymentId:
                                                                                    item.payment_id,
                                                                                transId:
                                                                                    item.transaction_id ||
                                                                                    null,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {item.transaction_id
                                                                        ? "Unmatch Payment"
                                                                        : "Delete Payment"}
                                                                </DropdownItem>
                                                            )}
                                                        {(item.payment_type ===
                                                            "i" ||
                                                            item.payment_type ===
                                                                "wbi") &&
                                                            item.payment_status ===
                                                                "pending" && (
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleModalInView(
                                                                            {
                                                                                modal: "confirm_payment",
                                                                                paymentId:
                                                                                    item.payment_id,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Confirm
                                                                    Payment
                                                                </DropdownItem>
                                                            )}

                                                        {(item.payment_type ===
                                                            "wbi" ||
                                                            item.payment_type ===
                                                                "i") &&
                                                            item.payment_status ===
                                                                "completed" && (
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        handleUpdateWriteoff(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    Mark as
                                                                    Pending
                                                                </DropdownItem>
                                                            )}

                                                        {item.payment_type ===
                                                            "ci" &&
                                                            item.amount > 0 && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleModalInView(
                                                                            {
                                                                                modal: "move_payment",
                                                                                paymentId:
                                                                                    item.payment_id,
                                                                                paymentAmount:
                                                                                    item.amount,
                                                                                paymentDate:
                                                                                    item.payment_date,
                                                                            }
                                                                        );
                                                                        setSelectedInvoiceId(
                                                                            matchingResponse
                                                                                .response
                                                                                .invoice_id
                                                                        );
                                                                    }}
                                                                >
                                                                    Split
                                                                    Payment
                                                                </DropdownItem>
                                                            )}

                                                        <DropdownItem
                                                            onClick={() => {
                                                                setSelectedPayment(
                                                                    item as ISelectedPayment
                                                                );
                                                                handleModalInView(
                                                                    {
                                                                        modal: "update_payment",
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            Edit Payment
                                                        </DropdownItem>

                                                        {item.refundable_amount &&
                                                            item.refundable_amount >
                                                                0 &&
                                                            item.payment_channel ===
                                                                "authorize.net" && (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        handleModalInView(
                                                                            {
                                                                                modal: "refund-authorize",
                                                                                transId:
                                                                                    item.transaction_id,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Refund
                                                                </DropdownItem>
                                                            )}
                                                    </DropdownContent>
                                                </Dropdown>
                                            )}
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                    )}
            </div>
        );
    };

    const getPaymentModalHeader = () => {
        switch (modalInView) {
            case "writeoff":
                return "Write Off";
            case "payment":
                return "Add payment";
            case "refund":
                return "Add refund";
            case "refund-authorize":
                return "Add refund";
            default:
                return "Add payment";
        }
    };

    const getPaymentModalSaveText = () => {
        switch (modalInView) {
            case "writeoff":
                return "Add write-off";
            case "payment":
                return "Add payment";
            case "refund":
                return "Add refund";
            case "refund-authorize":
                return "Add refund";
            default:
                return "Add payment";
        }
    };

    const paymentFormActionType = () => {
        if (modalInView === "payment") {
            return "Payment";
        }
        if (modalInView === "writeoff") {
            return "Write-Off";
        }
        return "Add-Refund";
    };

    const handleCloseDialog = () => {
        handleModalInView({ modal: null });
        searchParams.delete("writeoff_type");
        setSearchParams(searchParams);
        setWriteOffAction(null);
    };

    const getOutstandingValueToDisplay = (row: Row<IBillingResponse>) => {
        const coInsurance = row.original.accounting_coinsurance;
        const insurancePending =
            row.original.patient_insurance_pending_settlement;

        if (coInsurance <= 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center">
                        {showMoneyInAppFormat(Math.abs(coInsurance))} +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return showMoneyInAppFormat(Math.abs(coInsurance));
        }

        if (coInsurance > 0) {
            if (insurancePending && insurancePending > 0) {
                return (
                    <span className="flex items-center text-danger">
                        ({showMoneyInAppFormat(coInsurance)}) +{" "}
                        {showMoneyInAppFormat(insurancePending)}
                        <Tooltip content="Insurance balance transferred to patient">
                            <Button variant="normal" size="auto">
                                <InfoCircleIcon
                                    stroke={APP_COLORS.COLOR_BLACK}
                                    fill={APP_COLORS.COLOR_BLACK}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                );
            }
            return (
                <span className="text-danger">
                    ({showMoneyInAppFormat(coInsurance)})
                </span>
            );
        }

        return "--";
    };

    const handleShowPendingResolutionDialog = (row?: Row<IBillingResponse>) => {
        setShowPendingResolutionDialog(true);
        setPropsForPendingResolution({
            noteId: row?.original.note_id,
            clientId: row?.original.client.client_id,
            invoiceId: row?.original.invoice_id,
            invoiceIds: getSelectedData
                ? getSelectedData.map((item) => item.original.invoice_id)
                : undefined,
        });
    };

    const handleClosePendingResolutionDialog = () => {
        setShowPendingResolutionDialog(false);
        setPropsForPendingResolution(null);
    };

    // function to update invoice status
    const handleUpdateInvoiceStatus = (
        status: BillStatus,
        invoiceId?: string
    ) => {
        const selectedIds = getSelectedData
            ? getSelectedData
                  .filter((item) => Boolean(item.original.invoice_id))
                  .map((item) => item.original.invoice_id)
            : [];

        const idsToChange = invoiceId ? [invoiceId] : selectedIds;

        const changeStatusPayload = {
            invoice_ids: idsToChange,
            status,
        };
        updateInvoice.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["invoices"],
                });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.updated_invoices,
                        response.data.unupdated_invoices
                    )}`,
                });
                if (selectedIds) setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err?.response?.data.message ||
                        "Cannot update bill status at this time",
                });
            },
        });
    };

    const columns: ColumnDef<IBillingResponse>[] = [
        {
            id: "invoice_id",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) => (
                <div className="flex gap-12">
                    <Button
                        variant="normal"
                        size="auto"
                        onClick={() => {
                            setSelectedInvoiceId(row.original.invoice_id);
                            row.toggleExpanded();
                            setExpandedInvoice(
                                row.original.invoice_id as string,
                                !row.getIsExpanded()
                            );
                        }}
                    >
                        {row.getIsExpanded() ? (
                            <UnionUpIcon />
                        ) : (
                            <UnionDownIcon />
                        )}
                    </Button>

                    <Link
                        to={`/billing-and-claims/${row.original.client.client_id}/${row.original.note_id}`}
                        className="text-primary underline"
                    >
                        {formatDate(row.original.date_of_service) || "--"}
                    </Link>
                </div>
            ),
        },

        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => {
                if (row.original.cpt_code) {
                    return (
                        <Tooltip
                            content={
                                CPT_CODES_DESCRIPTION[
                                    row.original.cpt_code as string
                                ] || ""
                            }
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px",
                                }}
                            >
                                {row.original.cpt_code || "--"}
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    );
                }
                return "--";
            },
        },

        {
            accessorKey: "pos_code",
            header: "pos code",
            cell: ({ row }) => {
                const regex = /^(\d+) - (.+)$/;

                if (row.original.pos_code) {
                    return (
                        <Tooltip
                            content={
                                row.original.pos_code.match(regex)?.[2] || ""
                            }
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px",
                                }}
                            >
                                {row.original.pos_code.match(regex)?.[1] || ""}
                                <InfoIcon />
                            </span>
                        </Tooltip>
                    );
                }
                return "--";
            },
        },
        {
            accessorKey: "insurance_provider_name",
            header: "Insurance Provider",
            cell: ({ row }) => (
                <span
                    className={
                        row.original.metadata?.self_pay && !showInsOverSelfPay
                            ? "normal-case"
                            : "uppercase"
                    }
                >
                    {row.original.metadata?.self_pay && !showInsOverSelfPay
                        ? "Self pay"
                        : (row.original.insurance_provider &&
                              removeEnumUnderscore(
                                  row.original.insurance_provider || ""
                              )) ||
                          "--"}
                </span>
            ),
        },
        {
            accessorKey: "note_author",
            header: "CLINICIAN",
            cell: ({ row }) =>
                row.original.note_author
                    ? `${row.original.note_author.first_name} ${row.original.note_author.last_name}`
                    : "--",
        },
        {
            accessorKey: "invoice_status",
            header: "BILL STATUS",
            cell: ({ row }) => (
                <span>
                    {getBillStatusTag(
                        row.original.invoice_status as BillStatus
                    )}
                </span>
            ),
        },
        {
            header: "INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex gap-4">
                            {showMoneyInAppFormat(
                                row.original.insurance_amount
                            )}
                            {canUpdateChargeAmount &&
                                row.original.invoice_status !==
                                    BillStatus.CLOSED && (
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={() => {
                                            handleModalInView({
                                                modal: "update_charge",
                                                invoiceId:
                                                    row.original.invoice_id,
                                                chargeAmount:
                                                    row.original
                                                        .insurance_amount,
                                                chargeType: "insurance",
                                            });
                                        }}
                                    >
                                        <EditIcon className="w-12 h-12" />
                                    </Button>
                                )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.insurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => (
                        <span
                            className={cn("text-sm", {
                                "text-danger":
                                    row.original.accounting_insurance > 0,
                            })}
                        >
                            {row.original.accounting_insurance > 0
                                ? `(${showMoneyInAppFormat(
                                      row.original.accounting_insurance
                                  )})`
                                : showMoneyInAppFormat(
                                      Math.abs(
                                          row.original.accounting_insurance
                                      )
                                  )}
                        </span>
                    ),
                },
            ],
        },

        {
            header: "CO-INSURANCE",
            columns: [
                {
                    accessorKey: "charge",
                    header: "CHARGE",
                    cell: ({ row }) => (
                        <div className="flex gap-4">
                            {showMoneyInAppFormat(
                                row.original.coinsurance_amount
                            )}
                            {canUpdateChargeAmount &&
                                row.original.invoice_status !==
                                    BillStatus.CLOSED && (
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        onClick={() => {
                                            handleModalInView({
                                                modal: "update_charge",
                                                invoiceId:
                                                    row.original.invoice_id,
                                                chargeAmount:
                                                    row.original
                                                        .coinsurance_amount,
                                                chargeType: "coinsurance",
                                            });
                                        }}
                                    >
                                        <EditIcon className="w-12 h-12" />
                                    </Button>
                                )}
                        </div>
                    ),
                },
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(
                            row.original.coinsurance_amount_collected
                        ),
                },
                {
                    accessorKey: "balance",
                    header: "OUTSTANDING",
                    cell: ({ row }) => getOutstandingValueToDisplay(row),
                },
            ],
        },
        {
            header: "PR100",
            columns: [
                {
                    accessorKey: "collected",
                    header: "COLLECTED",
                    cell: ({ row }) =>
                        showMoneyInAppFormat(row.original.pr100_collected),
                },
            ],
        },
        {
            accessorKey: "metadata",
            header: "ACCEPTS ASSIGNMENT",
            cell: ({ row }) =>
                row.original?.metadata &&
                row.original?.metadata?.accepts_assignment ? (
                    <span
                        className={
                            row.original.metadata.accepts_assignment
                                ? "text-primary"
                                : "text-danger"
                        }
                    >
                        {row.original.metadata.accepts_assignment
                            ? "Yes"
                            : "No"}
                    </span>
                ) : (
                    "--"
                ),
        },
    ];

    React.useEffect(() => {
        refreshInvoiceBreakdownData({
            refreshAllOpened:
                unmatchPayment.isLoading ||
                isAddingPayment > 0 ||
                splitPaymentIsMutating > 0 ||
                confirmPaymentIsMutating > 0 ||
                updatePaymentIsMutating > 0,
            invoiceRowBreakdown_: invoiceRowBreakdown,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        invoiceRowBreakdown,
        isAddingPayment,
        unmatchPayment.isLoading,
        splitPaymentIsMutating,
        updatePaymentIsMutating,
        confirmPaymentIsMutating,
    ]);

    return (
        <>
            {showPendingReolutionDialog && (
                <Dialog open={showPendingReolutionDialog}>
                    <DialogContent
                        title="Pending session fee"
                        handleCloseDialog={handleClosePendingResolutionDialog}
                        variant="center"
                        showFooter
                        cancelText="Cancel"
                        saveText="Mark as Pending session fee"
                        submitBtnFormValue="pending-resolution"
                        isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                        isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                    >
                        <PendingResolutionForm
                            onFormSubmit={() => {
                                handleClosePendingResolutionDialog();
                                setRowSelection({});
                            }}
                            metaProps={propsForPendingResolution || undefined}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={modalInView === "bulk_writeoff"}>
                <DialogContent
                    handleCloseDialog={() => setModalInView(null)}
                    title="Bulk Write-Off"
                    variant="center"
                    saveText="Bulk Write-Off"
                    submitBtnFormValue="bulk-writeoff-form"
                    isSubmitBtnDisabled={bulkWriteOffIsMutating > 0}
                    isCancelBtnDisabled={bulkWriteOffIsMutating > 0}
                >
                    <AddBulkWriteOffForm
                        onFormSubmit={() => setModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            {selectedBills && selectedBills[0]?.invoice_id && (
                <Dialog open={modalInView === "unmatched_payments"}>
                    <DialogContent
                        title="Unmatched Payments"
                        showFooter={false}
                        handleCloseDialog={handleCloseUnmatchPaymentsModal}
                        wrapperClass={cn("w-[900px]", {
                            "w-[1500px]": canSplitPayment,
                        })}
                    >
                        <UnmatchedPaymentList
                            invoiceBalance={
                                selectedBills[0].accounting_coinsurance || 0
                            }
                            invoiceId={selectedBills[0].invoice_id}
                            onFormSubmit={handleCloseUnmatchPaymentsModal}
                            dateOfService={
                                selectedBills[0].date_of_service || ""
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}

            {invoiceToUpdateId && (
                <Dialog open={modalInView === "update_charge"}>
                    <DialogContent
                        variant="center"
                        saveText="Update charge"
                        submitBtnFormValue="update-charge"
                        handleCloseDialog={() =>
                            handleModalInView({ modal: null })
                        }
                        title="Update charge amount"
                        isCancelBtnDisabled={isUpdatingCharge > 0}
                        isSubmitBtnDisabled={isUpdatingCharge > 0}
                    >
                        <UpdateChargeForm
                            invoiceId={invoiceToUpdateId as string}
                            onFormSubmit={() =>
                                handleModalInView({ modal: null })
                            }
                            amount={chargeAmount || 0}
                            selectedChargeType={chargeType as string}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {selectedBills && selectedBills[0]?.invoice_id && (
                <Dialog open={modalInView === "charge_client"}>
                    <DialogContent
                        variant="center"
                        showFooter={
                            !chargeModalInView || chargeModalInView === "pr"
                        }
                        submitBtnFormValue={
                            chargeModalInView === "pr"
                                ? "pending-resolution"
                                : "charge-client"
                        }
                        handleCloseDialog={
                            chargeModalInView
                                ? () => {
                                      handleModalInView({ modal: null });
                                      removeChargeModalParams();
                                      setRowSelection({});
                                  }
                                : () => {
                                      handleModalInView({ modal: null });
                                      setRowSelection({});
                                  }
                        }
                        saveText={
                            chargeModalInView === "pr"
                                ? "Mark as Pending resolution"
                                : "Charge client"
                        }
                        isSubmitBtnDisabled={
                            chargeClientIsMutating > 0 ||
                            updateInVoiceIsMutating > 0
                        }
                        isCancelBtnDisabled={
                            chargeClientIsMutating > 0 ||
                            updateInVoiceIsMutating > 0
                        }
                        title={
                            chargeModalInView === "pr"
                                ? "Mark as Pending resolution"
                                : "Charge client"
                        }
                        isBackBtnDisabled={paymentIsMutating > 0}
                    >
                        <ChargeClient
                            onFormSubmit={
                                chargeModalInView
                                    ? () => {
                                          handleModalInView({ modal: null });
                                          removeChargeModalParams();
                                          setRowSelection({});
                                      }
                                    : () => {
                                          handleModalInView({ modal: null });
                                          setRowSelection({});
                                      }
                            }
                            invoiceId={selectedBills[0].invoice_id}
                            noteId={
                                selectedBills &&
                                selectedBills.length === 1 &&
                                selectedBills[0].note_id
                                    ? selectedBills[0].note_id
                                    : ""
                            }
                        />
                    </DialogContent>
                </Dialog>
            )}

            <Dialog
                open={
                    modalInView === "payment" ||
                    modalInView === "refund" ||
                    modalInView === "writeoff"
                }
            >
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    title={getPaymentModalHeader()}
                    saveText={
                        searchParams.get("writeoff_type") === "insurance"
                            ? "Save & Hold"
                            : getPaymentModalSaveText()
                    }
                    submitBtnFormValue="action-form"
                    isSubmitBtnDisabled={paymentIsMutating > 0}
                    isCancelBtnDisabled={paymentIsMutating > 0}
                    canCancelButtonSubmit
                    onCancelClick={() => {
                        if (searchParams.get("writeoff_type") === "insurance") {
                            setWriteOffAction("move");
                        } else setModalInView(null);
                    }}
                    cancelBtnFormValue="writeoff-move-form"
                    cancelText={
                        searchParams.get("writeoff_type") === "insurance"
                            ? "Save & Move"
                            : undefined
                    }
                    backBtnFormValue="writeoff-close-form"
                    canBackButtonSubmit
                    showBackBtn={
                        searchParams.get("writeoff_type") === "insurance"
                    }
                    backText="Save & Close Bill"
                    onBackClick={() => {
                        if (searchParams.get("writeoff_type") === "insurance") {
                            setWriteOffAction("close");
                        }
                    }}
                >
                    <PaymentActionForm
                        selectedActionType={paymentFormActionType()}
                        clientId={clientId}
                        onFormSubmit={() => {
                            handleCloseDialog();
                            setRowSelection({});
                        }}
                        modalType={modalInView}
                        invoiceId={
                            selectedBills &&
                            selectedBills.length === 1 &&
                            selectedBills[0]?.invoice_id
                                ? selectedBills[0].invoice_id
                                : undefined
                        }
                        writeOffAction={writeOffAction}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={
                    modalInView === "refund-authorize" &&
                    Boolean(selectedInvoiceId)
                }
            >
                <DialogContent
                    handleCloseDialog={handleCloseDialog}
                    variant="center"
                    onCancelClick={handleCloseDialog}
                    title={getPaymentModalHeader()}
                    saveText={getPaymentModalSaveText()}
                    submitBtnFormValue="action-form"
                    isSubmitBtnDisabled={paymentIsMutating > 0}
                    isCancelBtnDisabled={paymentIsMutating > 0}
                >
                    <PaymentActionForm
                        invoiceId={selectedInvoiceId || undefined}
                        clientId={clientId}
                        selectedActionType="Add-Refund"
                        onFormSubmit={handleCloseDialog}
                        transactionId={selectedTransactionId || undefined}
                        modalType="refund"
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={modalInView === "unmatch_payment"}>
                <DialogContent
                    variant="center"
                    isDeleting={!selectedTransactionId}
                    title={
                        selectedTransactionId
                            ? "Unmatch Payment"
                            : "Delete Payment"
                    }
                    isSubmitBtnDisabled={unmatchPayment.isLoading}
                    isCancelBtnDisabled={unmatchPayment.isLoading}
                    handleCloseDialog={() => handleModalInView({ modal: null })}
                    saveText={
                        selectedTransactionId
                            ? "Unmatch Payment"
                            : "Delete Payment"
                    }
                    onSaveClick={
                        paymentIdToUnmatch
                            ? () => handleUnMatchPayment(paymentIdToUnmatch)
                            : undefined
                    }
                >
                    <>
                        <h3 className="font-medium text-xl mb-8">
                            {`Are you sure you want to ${
                                selectedTransactionId ? "Unmatch" : "Delete"
                            } payment`}
                        </h3>
                        <p className="text-gray font-normal text-base">
                            {selectedTransactionId ? (
                                <span>
                                    Unmatching this payment will remove it from
                                    this bill. <br />
                                    You will still be able to see this payment
                                    in the unmatched payment tab.
                                </span>
                            ) : (
                                <span>
                                    Deleting this payment will remove it from
                                    your records. <br />
                                    You will no longer be able to see this
                                    payment.
                                </span>
                            )}
                        </p>
                    </>
                </DialogContent>
            </Dialog>

            {paymentIdToUnmatch && (
                <Dialog open={modalInView === "confirm_payment"}>
                    <DialogContent
                        handleCloseDialog={handleCloseDialog}
                        variant="center"
                        classNames="p-24"
                        title="Confirm Payment"
                        saveText="Yes,confirm"
                        onSaveClick={() =>
                            handlePostPayment(paymentIdToUnmatch)
                        }
                        isCancelBtnDisabled={confirmPaymentIsMutating > 0}
                        isSubmitBtnDisabled={confirmPaymentIsMutating > 0}
                    >
                        <div>
                            <h3 className="text-xl mb-8 font-medium">
                                Are you sure you want to confirm this payment?
                            </h3>
                            <p className="text-base text-gray font-normal">
                                By confirming, please note that this action is
                                irreversible
                            </p>
                        </div>
                    </DialogContent>
                </Dialog>
            )}

            <Dialog open={showChargeModal}>
                <DialogContent
                    variant="center"
                    title="Charge invoices"
                    handleCloseDialog={() => {
                        setShowChargeModal(false);
                        setRowSelection({});
                    }}
                    showFooter
                    saveText="Charge invoices"
                    submitBtnFormValue="invoice-payment"
                    classNames=" h-[25vh]"
                    isSubmitBtnDisabled={invoicePaymentIsMutating > 0}
                    isCancelBtnDisabled={invoicePaymentIsMutating > 0}
                >
                    <div>
                        <p className="text-center text-sm font-medium mb-24">
                            A sum of{" "}
                            <span className="text-primary">
                                {showMoneyInAppFormat(sumCoInsuranceAmount)}
                            </span>{" "}
                            will be deducted from your card
                        </p>

                        <p className="text-center mx-auto max-w-[60%] mb-24 text-sm font-medium">
                            This charge will be made to the last payment card
                            used.Kindly confirm proceed.
                        </p>

                        <InvoicePaymentForm
                            onFormSubmit={() => {
                                setShowChargeModal(false);
                                setRowSelection({});
                            }}
                            invoiceIds={
                                selectedBills?.map(
                                    (bill) => bill.invoice_id as string
                                ) || []
                            }
                        />
                    </div>
                </DialogContent>
            </Dialog>

            {selectedPayment && (
                <Dialog open={modalInView === "update_payment"}>
                    <DialogContent
                        handleCloseDialog={handleCloseWriteOffDialog}
                        variant="center"
                        title="Edit payment"
                        classNames="h-auto"
                        submitBtnFormValue="update-payment"
                        isSubmitBtnDisabled={isUpdatingPayment > 0}
                        isCancelBtnDisabled={isUpdatingPayment > 0}
                    >
                        <UpdatePaymentForm
                            onFormSubmit={handleCloseWriteOffDialog}
                            selectedPayment={selectedPayment}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {paymentAmount &&
                paymentIdToUnmatch &&
                selectedInvoiceId &&
                paymentDate && (
                    <Dialog open={modalInView === "move_payment"}>
                        <DialogContent
                            variant="center"
                            title="Split Payment"
                            handleCloseDialog={() => {
                                handleModalInView({ modal: null });
                                setSearchParams(searchParams);
                            }}
                            showFooter
                            wrapperClass="w-[700px]"
                            saveText="Split Payment"
                            onCancelClick={() =>
                                handleModalInView({ modal: null })
                            }
                            isSubmitBtnDisabled={splitPaymentIsMutating > 0}
                            isCancelBtnDisabled={splitPaymentIsMutating > 0}
                            submitBtnFormValue="move-payment"
                        >
                            <SplitPaymentForm
                                paymentId={paymentIdToUnmatch}
                                defaultAmount={paymentAmount}
                                onFormSubmit={() =>
                                    handleModalInView({ modal: null })
                                }
                                invoiceToMoveId={selectedInvoiceId}
                                paymentDate={paymentDate}
                            />
                        </DialogContent>
                    </Dialog>
                )}

            <div>
                <PaymentsOverview />

                <div className="bg-white p-16 rounded-r8 mb-24 mt-32">
                    {handleMessageToDisplay()}

                    <div className="flex gap-12 flex-wrap justify-between">
                        <div className="flex gap-12 flex-wrap">
                            <Button
                                variant="normal"
                                className="border border-card-bg bg-white shadow-active"
                                onClick={() => handleGenerateStatement()}
                            >
                                {fromDateFilter || toDateFilter
                                    ? "Generate account statement for selected period"
                                    : "Generate account statement"}
                            </Button>

                            {selectedBills && selectedBills.length === 1 && (
                                <Button
                                    variant="normal"
                                    className="border border-card-bg bg-white shadow-active"
                                    onClick={() =>
                                        handleModalInView({
                                            modal: "unmatched_payments",
                                        })
                                    }
                                >
                                    Check unmatched payments
                                </Button>
                            )}
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton text="Bill Status" />
                                </DropdownTrigger>
                                <DropdownContent
                                    align="end"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto"
                                >
                                    {Object.values(BillStatus).map((status) => (
                                        <DropdownItem
                                            key={status}
                                            onClick={() =>
                                                handleStatusChange(status)
                                            }
                                            isMulti
                                            itemValue={status}
                                            values={invoiceStatusFilter}
                                        >
                                            {removeEnumUnderscore(
                                                BillStatusLabels[status]
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            </Dropdown>

                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                    >
                                        {cptCodeFilter || "CPT Code"}
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </DropdownTrigger>

                                <DropdownContent
                                    align="start"
                                    width="auto"
                                    maxHeight={216}
                                    className="overflow-y-auto min-w-[120px]"
                                >
                                    <DropdownItem
                                        className={cn(
                                            "flex gap-x-8 items-center text-xs font-medium",
                                            {
                                                "text-primary": !cptCodeFilter,
                                            }
                                        )}
                                        onClick={() => handleCptCodeChange("")}
                                    >
                                        All
                                        {!cptCodeFilter ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>

                                    {Object.values(
                                        CPT_CODES.map((code) => (
                                            <DropdownItem
                                                className={cn(
                                                    "flex justify-between items-center capitalize text-xs font-medium",
                                                    {
                                                        "text-primary":
                                                            cptCodeFilter ===
                                                            code,
                                                    }
                                                )}
                                                key={code}
                                                onClick={() =>
                                                    handleCptCodeChange(code)
                                                }
                                            >
                                                {code}

                                                {cptCodeFilter === code ? (
                                                    <CheckPrimaryColorIcon />
                                                ) : null}
                                            </DropdownItem>
                                        ))
                                    )}
                                </DropdownContent>
                            </Dropdown>

                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        size="auto"
                                        variant="normal"
                                        className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                    >
                                        Date
                                        <ChevronDownIcon
                                            stroke={APP_COLORS.COLOR_BLACK}
                                        />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    align="end"
                                    className="p-16 gap-y-12"
                                >
                                    <DatePicker
                                        label="From"
                                        placeholderText="MM/DD/YYYY"
                                        selected={fromDateFilterToDate}
                                        onChange={handleFromDateFilterChange}
                                        maxDate={
                                            toDateFilterToDate ||
                                            new Date(Date.now())
                                        }
                                        isClearable
                                    />

                                    <DatePicker
                                        label="To"
                                        placeholderText="MM/DD/YYYY"
                                        selected={toDateFilterToDate}
                                        onChange={handleToDateFilterChange}
                                        minDate={fromDateFilterToDate}
                                        isClearable
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>

                        {canUserPerformAction && (
                            <Dropdown>
                                <DropdownTrigger asChild>
                                    <FilterButton
                                        classNames="bg-primary text-white"
                                        text="Actions"
                                        stroke={APP_COLORS.COLOR_WHITE}
                                    />
                                </DropdownTrigger>
                                <DropdownContent
                                    width="auto"
                                    className="text-gray"
                                    align="end"
                                >
                                    <>
                                        {selectedBills?.length === 1 && (
                                            <DropdownItem
                                                onClick={() =>
                                                    handleModalInView({
                                                        modal: "charge_client",
                                                    })
                                                }
                                            >
                                                Charge Client
                                            </DropdownItem>
                                        )}
                                        <DropdownItem
                                            onClick={() =>
                                                handleModalInView({
                                                    modal: "payment",
                                                })
                                            }
                                        >
                                            Add Payment
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                handleModalInView({
                                                    modal: "writeoff",
                                                });
                                                searchParams.set(
                                                    "writeoff_type",
                                                    "insurance"
                                                );
                                                setSearchParams(searchParams);
                                            }}
                                        >
                                            Writeoff
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                handleModalInView({
                                                    modal: "bulk_writeoff",
                                                })
                                            }
                                        >
                                            Bulk Writeoff
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() =>
                                                handleModalInView({
                                                    modal: "refund",
                                                })
                                            }
                                        >
                                            Refund
                                        </DropdownItem>
                                    </>
                                </DropdownContent>
                            </Dropdown>
                        )}
                    </div>

                    {canPerformBulkActions ? (
                        <div>
                            {getTotalAmountOfTableRowsSelected(rowSelection)
                                .amount > 0 ? (
                                <div className="flex items-center mt-24 mb-16 gap-12 flex-wrap">
                                    <div className="font-semibold text-base">
                                        {
                                            getTotalAmountOfTableRowsSelected(
                                                rowSelection
                                            ).amountString
                                        }{" "}
                                        :
                                    </div>
                                    <Button
                                        onClick={() => setShowChargeModal(true)}
                                    >
                                        Charge Invoices
                                    </Button>
                                    {showCloseInvoiceBulkAction && (
                                        <Button
                                            onClick={() =>
                                                handleUpdateInvoiceStatus(
                                                    BillStatus.CLOSED
                                                )
                                            }
                                            variant="secondary"
                                            disabled={updateInvoice.isLoading}
                                        >
                                            Mark as close
                                        </Button>
                                    )}
                                    {showOpenInvoiceBulkAction && (
                                        <Button
                                            onClick={() =>
                                                handleUpdateInvoiceStatus(
                                                    BillStatus.OPEN
                                                )
                                            }
                                            variant="secondary"
                                            disabled={updateInvoice.isLoading}
                                        >
                                            Mark as open
                                        </Button>
                                    )}
                                    {showPendingResolutionInvoiceBulkAction && (
                                        <Button
                                            onClick={() =>
                                                handleShowPendingResolutionDialog()
                                            }
                                            variant="secondary"
                                            disabled={updateInvoice.isLoading}
                                        >
                                            Mark as pending session fee
                                        </Button>
                                    )}
                                    {showPendingInsuranceResolutionInvoiceBulkAction && (
                                        <Button
                                            onClick={() =>
                                                handleUpdateInvoiceStatus(
                                                    BillStatus.PENDING_INSURANCE_RESOLUTION
                                                )
                                            }
                                            variant="secondary"
                                            disabled={updateInvoice.isLoading}
                                        >
                                            Mark as pending insurance resolution
                                        </Button>
                                    )}
                                    {showPendingPatientResolutionInvoiceBulkAction && (
                                        <Button
                                            onClick={() =>
                                                handleUpdateInvoiceStatus(
                                                    BillStatus.PENDING_INSURANCE_RESOLUTION
                                                )
                                            }
                                            variant="secondary"
                                            disabled={updateInvoice.isLoading}
                                        >
                                            Mark as pending patient resolution
                                        </Button>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <TableFiltersDisplay
                        appliedFilters={[
                            {
                                key: "invoice_status[]",
                                values: invoiceStatusFilter,
                            },
                        ]}
                        wrapperClass="mt-12"
                        localStorageKey="clientclaimslist_filters"
                    />
                </div>

                {error && (
                    <ListState
                        isError
                        stateHelperText="Try reloading this page to solve this issue"
                        errorMsg={
                            error?.response?.data.message ||
                            `Cannot display invoices at this time please try again later`
                        }
                    />
                )}
                {data && isSuccess && data.data.length === 0 && (
                    <ListState
                        stateHelperText="Invoice list will appear here"
                        isError={false}
                        emptyMessage="There are no invoices yet"
                    />
                )}
                {isLoading && (
                    <Skeleton
                        type="table"
                        containerTestId="client-payment-list-loader"
                        count={6}
                    />
                )}
                {!isLoading && isSuccess && data && data.data.length > 0 && (
                    <Table
                        data={data.data}
                        columns={columns}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        setGetSelectedData={setGetSelectedData}
                        expandedRows={expandedRows}
                        setExpandedRows={setExpandedRows}
                        renderRowSubComponent={renderRowSubComponent}
                        hasPagination
                        isHeaderFixed
                        pagination={
                            <Pagination
                                totalCount={data.total_count}
                                totalPages={data.total_pages}
                                currentPage={pageFilter}
                                onPageChange={handlePageChange}
                                limit={limitFilter}
                                onLimitChange={handleLimitChange}
                            />
                        }
                    />
                )}

                {canViewCreditCardInfo ? (
                    <div className="mt-32">
                        <CreditCards />
                    </div>
                ) : null}
            </div>
        </>
    );
}
