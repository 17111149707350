export interface ICalculateClientFee {
    customer_status: string;
    insurance: string;
    deductible: string;
    deductible_met: string;
    inn_coinsurance: string;
    inn_copay: string;
    oon_coinsurance: string;
    oon_copay: string;
    ucr: string;
    service_type?: string;
}

export interface ISubmitCalculateClientFee {
    year: string;
    fee_params: ICalculateClientFee[];
}

export interface ICalculateFeeResponse {
    masters: string;
    doctoral: string;
}

export enum CalculateFeesInsuranceProviders {
    AetnaMeritain = "Aetna/Meritain",
    BeaconGHI = "Beacon GHI",
    BeaconGHINational = "Beacon GHI National",
    BCBS = "BCBS",
    NYSHIP = "NYSHIP",
    CignaWellfleet = "Cigna/Wellfleet",
    UMR = "UMR",
    HarvardPilgrim = "Harvard Pilgrim",
    Oxford = "Oxford",
    UHC = "UHC",
    UHCStudent = "UHC-Student",
}

export enum CalculatePsychFeesInsuranceProviders {
    NYSHIP_CARELON = "NYSHIP/Carelon",
    GHI_CARELON = "GHI/Carelon",
}

export enum CalculateFeesCustomerStatus {
    NEW = "New",
    EXXISTING = "Existing",
}

export enum CalculateFeesServiceType {
    INITIAL = "Initial",
    FOLLOWUP = "Followup",
    TESTING = "Testing",
}

export enum CalculateFeesYearList {
    TWENTYFIVE = "2025",
    TWENTYFOUR = "2024",
    OLDTWENTYFOUR = "OLD 2024",
    TWENTYTHREE = "2023",
}
