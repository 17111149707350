/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import Navbar from "../../../../components/Navbar/Navbar";
import { IPractice } from "../../types/practices.types";
import { copyToClipboard } from "../../../../utils/helpers/copy";
import AddPracticeForm from "../AddPracticeForm/AddPracticeForm";
import EditPracticeForm from "../EditPracticeForm/EditPracticeForm";
import { useFetchAllPractices } from "../../hooks/practices.queries";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import { getStateInShortForm } from "../../../../utils/helpers/us-states/us-states";
import { useDebounce } from "../../../../hooks/helpers";
import useToast from "../../../../hooks/useToast";
import { LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";

type Modals = "create" | "edit";

export default function PracticesList() {
    const [modalInView, setModalInView] = React.useState<Modals | null>(null);
    const [practiceIdToEdit, setPracticeIdToEdit] = React.useState<
        string | null
    >(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const practiceNameFilter = searchParams.get("practice_name") || "";
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const debouncedPracticeName = useDebounce(practiceNameFilter, 500);

    const { data, isLoading, error, isSuccess } = useFetchAllPractices({
        limit: limitFilter,
        page: pageFilter,
        practice_name: debouncedPracticeName,
    });

    const addPracticeIsMutating = useIsMutating(["add-practice"]);
    const editPracticeIsMutating = useIsMutating(["update-practice"]);

    const { toast } = useToast();

    // Get practices list count
    let practicesCount;
    if (error) practicesCount = "";
    else if (isLoading) practicesCount = "";
    else if (data?.data && data?.data.length === 0) practicesCount = 0;
    else if (data?.data && data?.data.length > 0)
        practicesCount = data.total_count;

    const practiceToEdit = data?.data.find(
        (practice) => practice.practice_id === practiceIdToEdit
    );

    const handleModalInView = (modal: Modals | null) => {
        setModalInView(modal);
    };

    const handlePracticeNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.value === "") searchParams.delete("practice_name");
        else searchParams.set("practice_name", e.target.value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    // onChange handler for page
    const handlePageChange = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    // onChange handler for limit
    const handleLimitChange = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const handleCopyPracticeId = React.useCallback(
        (id: string) => {
            copyToClipboard(id);
            toast({
                mode: "success",
                message: "Practice id copied successfully",
            });
        },
        [toast]
    );

    const columns: ColumnDef<IPractice>[] = React.useMemo(
        () => [
            {
                accessorKey: "practice_name",
                header: "Practice Name",
            },
            {
                accessorKey: "npi",
                header: "NPI",
                cell: ({ row }) => row.original.npi || "--",
            },
            {
                accessorKey: "state",
                header: "State",
                cell: ({ row }) => getStateInShortForm(row.original.state),
            },
            {
                accessorKey: "no_of_providers",
                header: "No. of providers",
            },
            {
                accessorKey: "no_of_clients",
                header: "No. of clients",
            },
            {
                accessorKey: "practice_id",
                header: "",
                cell: ({ row }) => (
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <MoreButton />
                        </DropdownTrigger>
                        <DropdownContent
                            width="auto"
                            className="px-8 py-16 text-gray"
                            align="end"
                        >
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleModalInView("edit");
                                    setPracticeIdToEdit(
                                        row.original.practice_id
                                    );
                                }}
                            >
                                Edit practice
                            </DropdownItem>
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopyPracticeId(
                                        row.original.practice_id
                                    );
                                }}
                            >
                                Copy practice ID
                            </DropdownItem>
                        </DropdownContent>
                    </Dropdown>
                ),
            },
        ],
        [handleCopyPracticeId]
    );
    return (
        <>
            <Dialog open={modalInView === "create"}>
                <DialogContent
                    title="Add Practice"
                    saveText="Add practice"
                    handleCloseDialog={() => handleModalInView(null)}
                    variant="center"
                    isCancelBtnDisabled={addPracticeIsMutating > 0}
                    isSubmitBtnDisabled={addPracticeIsMutating > 0}
                    submitBtnFormValue="add-practice"
                >
                    <AddPracticeForm
                        onFormSubmit={() => handleModalInView(null)}
                    />
                </DialogContent>
            </Dialog>
            {practiceToEdit && (
                <Dialog open={modalInView === "edit"}>
                    <DialogContent
                        title="Edit Practice"
                        handleCloseDialog={() => {
                            handleModalInView(null);
                            setPracticeIdToEdit(null);
                        }}
                        variant="center"
                        isCancelBtnDisabled={editPracticeIsMutating > 0}
                        isSubmitBtnDisabled={editPracticeIsMutating > 0}
                        submitBtnFormValue="edit-practice"
                    >
                        <EditPracticeForm
                            practiceToEdit={practiceToEdit}
                            onFormSubmit={() => {
                                handleModalInView(null);
                                setPracticeIdToEdit(null);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Navbar title={`Practices (${practicesCount})`} />
            <div className="page">
                <div className="mb-32 flex justify-between items-center bg-white px-12 py-16 rounded-r8">
                    <SearchInput
                        placeholder="Search by practice name"
                        value={practiceNameFilter}
                        onChange={handlePracticeNameChange}
                        containerClass="w-[350px] h-32 max-w-full"
                    />
                    <Button
                        aria-label="add practice"
                        onClick={() => handleModalInView("create")}
                    >
                        Add practice
                    </Button>
                </div>
                <div>
                    {isLoading && (
                        <Skeleton
                            type="table"
                            count={6}
                            containerTestId="practices-loader"
                        />
                    )}
                    {error && (
                        <ListState
                            isError
                            stateHelperText="Try reloading this page to solve this issue"
                            errorMsg={
                                error?.response?.data.message ||
                                `Cannot display practices list at this time please try again later`
                            }
                        />
                    )}
                    {data && isSuccess && data?.data?.length === 0 && (
                        <ListState
                            stateHelperText="Practice list will appear here when available"
                            isError={false}
                            emptyMessage="No practice added yet"
                        />
                    )}
                    {isSuccess && data && data.data.length > 0 && (
                        <Table
                            data={data.data}
                            columns={columns}
                            hasPagination={data.total_count > 20}
                            pagination={
                                <Pagination
                                    totalCount={data.total_count}
                                    totalPages={data.total_pages}
                                    currentPage={pageFilter}
                                    onPageChange={handlePageChange}
                                    limit={limitFilter}
                                    onLimitChange={handleLimitChange}
                                />
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
}
