import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosProgressEvent } from "axios";
import React from "react";
import { IApiResponse } from "../../../utils/types/api-response";
import {
    IAddManualRemit,
    IAddRemitClaims,
    IAddRemitResponse,
    IArchiveRemitPayload,
    IBatchRemit,
    IDeleteManualBatchRemit,
    IPostPaymentsPayload,
    IPostPaymentsResponse,
    IRemit,
    IUpdateRemitStatus,
} from "../types/remits.types";
import {
    addRemit,
    addRemitClaims,
    archiveRemit,
    deleteManualBatchRemit,
    deleteRemitDocument,
    editRemit,
    postPayment,
    postPayments,
    updateRemitStatus,
} from "../services/remits.services";

// @deprecated
export function usePostPayment(paymentId: string) {
    return useMutation<
        IApiResponse<IRemit>,
        AxiosError<IApiResponse<IRemit>>,
        string
    >({
        mutationKey: ["post-payment", paymentId],
        mutationFn: () => postPayment(paymentId),
    });
}

export function usePostPayments() {
    return useMutation<
        IApiResponse<IPostPaymentsResponse>,
        AxiosError<IApiResponse<IPostPaymentsResponse>>,
        IPostPaymentsPayload
    >({
        mutationKey: ["post-payments"],
        mutationFn: (paymentIds) => postPayments(paymentIds),
    });
}

export function useUpdateRemitStatus() {
    return useMutation<
        IApiResponse<IBatchRemit>,
        AxiosError<IApiResponse<IBatchRemit>>,
        IUpdateRemitStatus
    >({
        mutationKey: ["update-remit-status"],
        mutationFn: (data: IUpdateRemitStatus) => updateRemitStatus(data),
    });
}

export function useAddManualRemit() {
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const mutation = useMutation<
        IApiResponse<IAddRemitResponse>,
        AxiosError<IApiResponse<IAddRemitResponse>>,
        FormData
    >({
        mutationKey: ["add-manual-remit"],
        mutationFn: (data) =>
            addRemit(data, (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                    setUploadProgress(
                        Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    );
                }
            }),
        onSuccess: () => {
            setUploadProgress(0);
        },
        onError: () => {
            setUploadProgress(0);
        },
    });
    return { ...mutation, uploadProgress };
}

export function useAddRemitClaims(batchRemitId: string) {
    return useMutation<
        IApiResponse<IAddRemitResponse>,
        AxiosError<IApiResponse<IAddRemitResponse>>,
        IAddRemitClaims
    >({
        mutationKey: ["add-remit", batchRemitId],
        mutationFn: (data: IAddRemitClaims) =>
            addRemitClaims(batchRemitId, data),
    });
}

export function useDeleteRemitDocument(
    remitId: string,
    remittanceAttachmentId: string
) {
    interface IApiResponse {
        message: string;
    }
    return useMutation<IApiResponse, AxiosError<IApiResponse>, null>({
        mutationKey: ["delete-remit-document", remitId],
        mutationFn: () => deleteRemitDocument(remitId, remittanceAttachmentId),
    });
}

export function useArchiveRemit() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IArchiveRemitPayload
    >({
        mutationKey: ["archive-remit"],
        mutationFn: (payload: IArchiveRemitPayload) => archiveRemit(payload),
    });
}

export function useDeleteManualBatchRemit() {
    return useMutation<
        IApiResponse<{ message: string }>,
        AxiosError<IApiResponse<{ message: string }>>,
        IDeleteManualBatchRemit
    >({
        mutationKey: ["delete-manual-remit"],
        mutationFn: (payload: IDeleteManualBatchRemit) =>
            deleteManualBatchRemit(payload),
    });
}

export function useEditManualRemit(batchRemitId: string) {
    return useMutation<
        IApiResponse<IAddRemitResponse>,
        AxiosError<IApiResponse<IAddRemitResponse>>,
        IAddManualRemit
    >({
        mutationKey: ["edit-manual-remit", batchRemitId],
        mutationFn: (data) => editRemit(batchRemitId, data),
    });
}
