import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import {
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Input,
    TextArea,
} from "@jhool-io/fe-components";
import { parseISO } from "date-fns";
import { IGetClientNotes, ISessionNote } from "../../../../utils/types/notes";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";

import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { IEditGeneralNotesPayload } from "../../../../modules/notes/types/notes.types";
import { useEditNote } from "../../../../modules/notes/hooks/edit-note";
import EditNoteFooter from "../../../../modules/notes/components/NoteCreationFooters/EditNoteFooter/EditNoteFooter";
import { GENERAL_UPDATE_NOTE_LABELS } from "../../../../utils/constants";

interface EditGeneralNoteProps {
    noteDetails: ISessionNote;
    currentAction?: string | null;
}

export default function EditGeneralNote({
    noteDetails,
    currentAction,
}: EditGeneralNoteProps) {
    const [localDateOfUpdate, setLocalDateOfUpdate] =
        React.useState<Date | null>(
            noteDetails.note_content?.date_of_update
                ? parseISO(noteDetails.note_content.date_of_update)
                : null
        );
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );

    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    const { handleAutoSaveNote, handleEditDraftNote, handleSignNote } =
        useEditNote();

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<IEditGeneralNotesPayload>({
        resolver: yupResolver(
            yup.object({
                update_note_content: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
                date_of_update: yup.date().nullable(),
                signature: yup.string().when([], {
                    is: () => currentAction === "with_signature",
                    then: yup.string().required("This field is required"),
                    otherwise: yup.string(),
                }),
            })
        ),
        mode: "onChange",
        defaultValues: {
            update_note_content:
                noteDetails?.note_content?.update_note_content || "",
            signature: noteDetails?.note_content?.signature || "",
        },
    });

    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue("update_note_content", note.note_content.update_note_content);
        setValue("date_of_update", note.note_content.date_of_update);
        setShowPrompt(false);
    };

    return (
        <>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />

            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}

            <form
                onSubmit={handleSubmit(handleSignNote)}
                aria-label="general update form"
                id="edit-note-form"
                className="pb-[60px] md:pb-[150px]"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <div className="fg">
                    <TextArea
                        {...register("update_note_content")}
                        label={GENERAL_UPDATE_NOTE_LABELS.update_note_content}
                        errorText={errors.update_note_content?.message}
                        onBlur={handleSubmit(handleAutoSaveNote)}
                        hasError={!!errors.update_note_content}
                        isRequired
                    />
                </div>
                <div className="fg">
                    <Controller
                        name="date_of_update"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                isOptional
                                label={
                                    GENERAL_UPDATE_NOTE_LABELS.date_of_update
                                }
                                onChange={(date) => {
                                    field.onChange(date);
                                    setLocalDateOfUpdate(date);
                                }}
                                value={
                                    field.value
                                        ? formatDate(field.value, true)
                                        : undefined
                                }
                                selected={localDateOfUpdate}
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        )}
                    />
                </div>

                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
                <div>
                    <EditNoteFooter
                        onSaveButtonClick={handleSubmit(handleEditDraftNote)}
                        noteDOS={noteDetails.date_of_service}
                    />
                </div>
            </form>
        </>
    );
}
