import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { parseISO } from "date-fns";
import {
    DatePicker,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    FormCheckbox,
    Input,
    RadioInput,
    Select,
    TextArea,
} from "@jhool-io/fe-components";
import {
    IGetClientNotes,
    ISessionNote,
    NoteTypes,
} from "../../../../utils/types/notes";
import { useFetchDiagnosisCodes } from "../../../../hooks/queries";
import { AppointmentLocation } from "../../../../utils/types/session";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
} from "../../../../utils/helpers";
import NoteFormInfoBox from "../../NoteFormInfoBox/NoteFormInfoBox";
import { useFetchClientNotesList } from "../../../../hooks/queries/note";
import LoadPreviousSessionNotificationModal from "../../LoadPreviousSessionNotificatiModal/LoadPreviousSessionNotificationModal";
import { SOAP_PLAN_NOTE_LABELS } from "../../../../utils/constants";
import FilterButton from "../../../../shared-ui/Buttons/FilterButton/FilterButton";
import { IEditSoapNote } from "../../../../modules/notes/types/notes.types";
import EditNoteFooter from "../../../../modules/notes/components/NoteCreationFooters/EditNoteFooter/EditNoteFooter";
import { useEditNote } from "../../../../modules/notes/hooks/edit-note";

interface EditSoapNoteProps {
    noteDetails: ISessionNote;
    currentAction: string | null;
}

type Option = {
    label: React.ReactNode;
    value: string;
};

export default function EditSoapNote({
    noteDetails,
    currentAction,
}: EditSoapNoteProps) {
    // Local component state
    const [localDateOfNextSession, setLocalDateOfNextSession] =
        React.useState<Date | null>(
            noteDetails?.note_content?.date_of_next_session
                ? parseISO(noteDetails?.note_content?.date_of_next_session)
                : null
        );
    const [searchValue, setSearchValue] = React.useState("");
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(
        {} as IGetClientNotes
    );

    const { handleAutoSaveNote, handleEditDraftNote, handleSignNote } =
        useEditNote();

    // Fetch diagonis codes
    const { data, isLoading, error } = useFetchDiagnosisCodes();
    const clientNoteParams = {
        type: noteDetails?.type as string,
        load_previous_notes: true,
        provider_id: noteDetails?.provider?.provider_id as string,
    };

    const { data: notes } = useFetchClientNotesList(
        noteDetails?.client?.client_id as string,
        clientNoteParams
    );

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<IEditSoapNote>({
        resolver: yupResolver(
            yup
                .object()
                .shape({
                    duration: yup.string(),
                    appointment_location: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Session location is required"),
                        otherwise: yup.string(),
                    }),
                    delivery_option: yup
                        .string()
                        .nullable()
                        .when(
                            "appointment_location",
                            (appointment_location, field) =>
                                appointment_location === "teletherapy" &&
                                currentAction === "with_signature"
                                    ? field.required(
                                          "Client teletherapy location is required"
                                      )
                                    : field
                        ),
                    provider_location: yup
                        .string()
                        .nullable()
                        .when(
                            "appointment_location",
                            (appointment_location, field) =>
                                appointment_location === "teletherapy" &&
                                currentAction === "with_signature"
                                    ? field.required(
                                          "Provider teletherapy location is required"
                                      )
                                    : field
                        ),
                    consent_was_received_for_the_teletherapy_session:
                        yup.bool(),
                    the_teletherapy_session_was_conducted_via_video: yup.bool(),
                    other_delivery_option: yup
                        .string()
                        .when("delivery_option", (delivery_option, field) =>
                            delivery_option === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other client teletherapy location is required"
                                  )
                                : field
                        ),
                    other_provider_location: yup
                        .string()
                        .when("provider_location", (provider_location, field) =>
                            provider_location === "Other" &&
                            currentAction === "with_signature"
                                ? field.required(
                                      "Other provider teletherapy location is required"
                                  )
                                : field
                        ),
                    diagnosis_codes: yup.array().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .array()
                            .max(1, "You can only select 1 diagnosis")
                            .min(1, "Diagnosis is required")
                            .required("Diagnosis is required"),
                        otherwise: yup
                            .array()
                            .nullable()
                            .max(1, "You can only select 1 diagnosis"),
                    }),
                    secondary_diagnosis: yup.string(),
                    tertiary_diagnosis: yup.string(),
                    isClientPresent: yup.bool(),
                    emotional_behavioural_symptoms_demonstrated: yup
                        .string()
                        .when([], {
                            is: () => currentAction === "with_signature",
                            then: yup
                                .string()
                                .required(
                                    "Emotional behavioural symptoms demonstrated is required"
                                ),
                            otherwise: yup.string(),
                        }),
                    causing: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Causing is required"),
                        otherwise: yup.string(),
                    }),
                    intention_for_session: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Intention for session is required"),
                        otherwise: yup.string(),
                    }),
                    subjective: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Subjective field is required"),
                        otherwise: yup.string(),
                    }),
                    objective: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Objective field is required"),
                        otherwise: yup.string(),
                    }),
                    assessment: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Assessment field is required"),
                        otherwise: yup.string(),
                    }),
                    cognitive_behavioral_therapies: yup.array().nullable(),
                    relationship_based_interventions: yup.array().nullable(),
                    other: yup.array().nullable(),
                    plan: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Plan field is required"),
                        otherwise: yup.string(),
                    }),
                    frequency_of_sessions: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("This field is required"),
                        otherwise: yup.string().nullable(),
                    }),
                    other_frequency_of_sessions: yup
                        .string()
                        .when(
                            "frequency_of_sessions",
                            (frequency_of_sessions, field) =>
                                frequency_of_sessions === "Other" &&
                                currentAction === "with_signature"
                                    ? field.required(
                                          "Other frequency of sessions is required"
                                      )
                                    : field
                        ),
                    date_of_next_session: yup.date().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .date()
                            .required("Date of next session is required"),
                        otherwise: yup.date().nullable(),
                    }),
                    prognosis: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Prognosis is required"),
                        otherwise: yup.string(),
                    }),
                    client_response: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup
                            .string()
                            .required("Client response is required"),
                        otherwise: yup.string(),
                    }),
                    goal_one: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Goal is required"),
                        otherwise: yup.string(),
                    }),
                    progress_one: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Progress is required"),
                        otherwise: yup.string(),
                    }),
                    goal_two: yup.string(),
                    progress_two: yup.string(),
                    goal_three: yup.string(),
                    progress_three: yup.string(),
                    communication_factors_impacting_treatment: yup
                        .array()
                        .nullable(),
                    details_around_communication_factors_impacting_treatment:
                        yup.string(),
                    signature: yup.string().when([], {
                        is: () => currentAction === "with_signature",
                        then: yup.string().required("Signature is required"),
                        otherwise: yup.string(),
                    }),
                })
                .test(
                    "at-least-one-item-in-array",
                    "Please select at least one option from the Therapy Intervention Techniques fields",
                    (values) => {
                        const arrays = [
                            values.cognitive_behavioral_therapies,
                            values.relationship_based_interventions,
                            values.other,
                        ];
                        return arrays.some(
                            (arr) => Array.isArray(arr) && arr.length > 0
                        );
                    }
                )
        ),
        mode: "onChange",
        defaultValues: noteDetails.note_content || undefined,
    });

    const diagnosisCodes = watch("diagnosis_codes");

    // Get diagnosis codes for select
    const DiagnosisCodesForSelect = data?.data.map((diagnosis) => ({
        label: `${diagnosis.code} ${diagnosis.description}`,
        value: diagnosis.code,
    }));

    // Get causing options for select
    const CausingOptionsForSelect = [
        "Maladaptive Functioning",
        "Inappropriate Behaviour",
        "Maladaptive Functioning & Inappropriate Behaviour",
    ].map((cause) => ({
        label: cause,
        value: cause,
    }));

    // Get intention for session options for select
    const IntentionForSessionForSelect = [
        "Attempts to alleviate the emotional disturbances",
        "Reverse or change maladaptive patterns of behavior",
        "Facilitate coping mechanisms",
        "Encourage personality growth and development",
        "Encourage personality growth and minimize maladaptive functioning",
        "Facilitate coping mechanisms",
    ].map((intention) => ({
        label: intention,
        value: intention,
    }));

    // Get appointment location options
    const AppointmentLocationsForSelect = Object.values(
        AppointmentLocation
    ).map((location) => {
        return {
            label: removeEnumUnderscore(makeStringFirstLetterCapital(location)),
            value: location,
        };
    });

    // Get filter options for diagnosis list
    const filterDiagnosisOptions = (phrases: string[]) => {
        const filteredOptions = DiagnosisCodesForSelect?.filter((option) =>
            phrases.every(
                (phrase) =>
                    option &&
                    option.label.toLowerCase().includes(phrase.toLowerCase())
            )
        );
        return filteredOptions;
    };

    const handleCheckCognitive = (checkboxValue: string) => {
        return Boolean(
            noteDetails.note_content?.cognitive_behavioral_therapies?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    const handleCheckRelationship = (checkboxValue: string) => {
        return Boolean(
            noteDetails.note_content?.relationship_based_interventions?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    const handleCheckOther = (checkboxValue: string) => {
        return Boolean(
            noteDetails.note_content?.other?.find(
                (value: string) => value.trim() === checkboxValue.trim()
            )
        );
    };

    const isCheckboxChecked = (checkboxValue: string, key: string) => {
        return Boolean(
            noteDetails.note_content?.[key]?.find(
                (value: string) => value === checkboxValue
            )
        );
    };

    // Function to handle item click and set the selected note content
    const handleLoadPreviousSession = (note: IGetClientNotes) => {
        setValue(
            "appointment_location",
            note.note_content.appointment_location
        );
        setValue("assessment", note.note_content.assessment);
        setValue("causing", note.note_content.causing);
        setValue("client_response", note.note_content.client_response);
        setValue(
            "cognitive_behavioral_therapies",
            note.note_content.cognitive_behavioral_therapies
        );
        setValue(
            "consent_was_received_for_the_teletherapy_session",
            note.note_content.consent_was_received_for_the_teletherapy_session
        );
        setValue("delivery_option", note.note_content.delivery_option);
        setValue("diagnosis_codes", note.note_content.diagnosis_codes);
        setValue("secondary_diagnosis", note.note_content.secondary_diagnosis);
        setValue("tertiary_diagnosis", note.note_content.tertiary_diagnosis);
        setValue("duration", note.note_content.duration);
        setValue(
            "emotional_behavioural_symptoms_demonstrated",
            note.note_content.emotional_behavioural_symptoms_demonstrated
        );
        setValue("goal_one", note.note_content.goal_one);
        setValue("goal_two", note.note_content.goal_two);
        setValue("goal_three", note.note_content.goal_three);
        setValue(
            "intention_for_session",
            note.note_content.intention_for_session
        );
        setValue("isClientPresent", note.note_content.isClientPresent);
        setValue("objective", note.note_content.objective);
        setValue("other", note.note_content.other);
        setValue(
            "other_delivery_option",
            note.note_content.other_delivery_option
        );
        setValue(
            "other_provider_location",
            note.note_content.other_provider_location
        );
        setValue("plan", note.note_content.plan);
        setValue("prognosis", note.note_content.prognosis);
        setValue("progress_one", note.note_content.progress_one);
        setValue("progress_three", note.note_content.progress_three);
        setValue("progress_two", note.note_content.progress_two);
        setValue("provider_location", note.note_content.provider_location);
        setValue(
            "relationship_based_interventions",
            note.note_content.relationship_based_interventions
        );
        setValue("subjective", note.note_content.subjective);
        setValue(
            "the_teletherapy_session_was_conducted_via_video",
            note.note_content.the_teletherapy_session_was_conducted_via_video
        );
        setValue(
            "frequency_of_sessions",
            note.note_content.frequency_of_sessions
        );
        setValue(
            "other_frequency_of_sessions",
            note.note_content.other_frequency_of_sessions
        );
        setValue(
            "date_of_next_session",
            note.note_content.date_of_next_session
        );
        setValue(
            "communication_factors_impacting_treatment",
            note.note_content.communication_factors_impacting_treatment
        );
        setValue(
            "details_around_communication_factors_impacting_treatment",
            note.note_content
                .details_around_communication_factors_impacting_treatment
        );
        setShowPrompt(false);
    };

    const rootError = errors
        ? Object.values(errors).find(
              (val) => val.type === "at-least-one-item-in-array"
          )?.message
        : undefined;

    return (
        <div>
            <LoadPreviousSessionNotificationModal
                showPrompt={showPrompt}
                setShowPrompt={setShowPrompt}
                handleLoadPreviousSession={() =>
                    handleLoadPreviousSession(selectedNote)
                }
            />
            {notes && notes.data.length > 0 && (
                <div className="flex justify-end mb-24">
                    <Dropdown>
                        <DropdownTrigger asChild>
                            <FilterButton text="Load previous session note" />
                        </DropdownTrigger>
                        <DropdownContent width="auto" align="end">
                            {notes &&
                                notes?.data?.map((note) => (
                                    <DropdownItem
                                        key={note.note_id}
                                        onClick={() => {
                                            setShowPrompt(true);
                                            setSelectedNote(note);
                                        }}
                                    >
                                        <div>
                                            {makeStringFirstLetterCapital(
                                                removeEnumUnderscore(
                                                    note.note_type
                                                )
                                            )}{" "}
                                            - Note content Details -{" "}
                                            {formatDate(note.date_of_service)}
                                        </div>
                                    </DropdownItem>
                                ))}
                        </DropdownContent>
                    </Dropdown>
                </div>
            )}
            <form
                onSubmit={handleSubmit(handleSignNote)}
                className="pb-[60px] md:pb-[150px]"
                aria-label="soap form"
                id="edit-note-form"
            >
                <div className="mb-24">
                    <NoteFormInfoBox />
                </div>
                <section id="step-one" className="mb-40">
                    {noteDetails.type === NoteTypes.FAMILY_SOAP_NOTE && (
                        <div className="fg">
                            <FormCheckbox
                                {...register("isClientPresent")}
                                label={SOAP_PLAN_NOTE_LABELS.isClientPresent}
                                defaultChecked={
                                    noteDetails.note_content?.isClientPresent
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Controller
                            name="appointment_location"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.appointment_location
                                    }
                                    placeholder={
                                        SOAP_PLAN_NOTE_LABELS.appointment_location
                                    }
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    value={
                                        AppointmentLocationsForSelect.find(
                                            (location) =>
                                                location.value === field.value
                                        ) || null
                                    }
                                    options={AppointmentLocationsForSelect}
                                    hasError={!!errors.appointment_location}
                                    errorText={
                                        errors.appointment_location?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                    {watch(`appointment_location`) ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {SOAP_PLAN_NOTE_LABELS.delivery_option}
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register(`delivery_option`)}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.delivery_option === "Home"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register(`delivery_option`)}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.delivery_option === "Office"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register(`delivery_option`)}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.delivery_option === "Other"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            </div>
                            {errors.delivery_option ? (
                                <div className="note-error">
                                    <p>Delivery option is required</p>
                                </div>
                            ) : null}
                            {watch(`delivery_option`) === "Other" ||
                            noteDetails.note_content?.delivery_option ===
                                "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register(`other_delivery_option`)}
                                        label={
                                            SOAP_PLAN_NOTE_LABELS.other_delivery_option
                                        }
                                        placeholder={
                                            SOAP_PLAN_NOTE_LABELS.other_delivery_option
                                        }
                                        hasError={
                                            !!errors?.other_delivery_option
                                        }
                                        errorText={
                                            errors?.other_delivery_option
                                                ?.message
                                        }
                                        defaultValue={
                                            noteDetails.note_content
                                                ?.other_delivery_option
                                        }
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}
                    {watch(`appointment_location`) ===
                        AppointmentLocation.TELETHERAPY && (
                        <>
                            <p className="note-label">
                                {SOAP_PLAN_NOTE_LABELS.provider_location}
                            </p>
                            <div className="fg fg-space-between three flex">
                                <RadioInput
                                    {...register(`provider_location`)}
                                    label="Home"
                                    value="Home"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.provider_location === "Home"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register(`provider_location`)}
                                    label="Office"
                                    value="Office"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.provider_location === "Office"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />

                                <RadioInput
                                    {...register(`provider_location`)}
                                    label="Other"
                                    value="Other"
                                    defaultChecked={
                                        noteDetails.note_content
                                            ?.provider_location === "Other"
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            </div>
                            {errors.provider_location ? (
                                <div className="note-error">
                                    <p>Delivery option is required</p>
                                </div>
                            ) : null}
                            {watch(`provider_location`) === "Other" ||
                            noteDetails.note_content?.provider_location ===
                                "Other" ? (
                                <div className="fg">
                                    <Input
                                        {...register(`other_provider_location`)}
                                        label={
                                            SOAP_PLAN_NOTE_LABELS.other_provider_location
                                        }
                                        placeholder={
                                            SOAP_PLAN_NOTE_LABELS.other_provider_location
                                        }
                                        hasError={
                                            !!errors?.other_provider_location
                                        }
                                        errorText={
                                            errors?.other_provider_location
                                                ?.message
                                        }
                                        defaultValue={
                                            noteDetails.note_content
                                                ?.other_provider_location
                                        }
                                        onBlur={handleSubmit(
                                            handleAutoSaveNote
                                        )}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}
                    {watch("appointment_location") ===
                    AppointmentLocation.TELETHERAPY ? (
                        <div className="note-checkboxes">
                            <FormCheckbox
                                {...register(
                                    "consent_was_received_for_the_teletherapy_session"
                                )}
                                label={
                                    SOAP_PLAN_NOTE_LABELS.consent_was_received_for_the_teletherapy_session
                                }
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.consent_was_received_for_the_teletherapy_session
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                            <FormCheckbox
                                {...register(
                                    "the_teletherapy_session_was_conducted_via_video"
                                )}
                                label={
                                    SOAP_PLAN_NOTE_LABELS.the_teletherapy_session_was_conducted_via_video
                                }
                                defaultChecked={
                                    noteDetails.note_content
                                        ?.the_teletherapy_session_was_conducted_via_video
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    ) : null}
                    <div className="fg">
                        <Controller
                            name="diagnosis_codes"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isRequired
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.diagnosis_codes
                                    }
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading diagnosis") ||
                                        (data &&
                                            !isLoading &&
                                            SOAP_PLAN_NOTE_LABELS.diagnosis_codes)
                                    }
                                    isSearchable
                                    onInputChange={(value) =>
                                        setSearchValue(value)
                                    }
                                    filterOption={() => true} // Disable default filtering
                                    options={filterDiagnosisOptions(
                                        searchValue.split(" ")
                                    )}
                                    onChange={(val) => {
                                        field.onChange(
                                            (val as Option[]).map(
                                                (code) => code.value
                                            )
                                        );
                                    }}
                                    isDisabled={isLoading || Boolean(error)}
                                    hasError={!!errors.diagnosis_codes}
                                    value={
                                        DiagnosisCodesForSelect?.filter(
                                            (diagnosis) =>
                                                field.value?.includes(
                                                    diagnosis.value
                                                )
                                        ) || null
                                    }
                                    hideSelectedOptions
                                    errorText={
                                        errors.diagnosis_codes?.type ===
                                        "typeError"
                                            ? "Diagnosis codes are required"
                                            : errors.diagnosis_codes?.message
                                    }
                                    isMulti
                                    multiHasValues={
                                        diagnosisCodes &&
                                        diagnosisCodes.length > 0
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="secondary_diagnosis"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.secondary_diagnosis
                                    }
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading diagnosis") ||
                                        (data &&
                                            !isLoading &&
                                            SOAP_PLAN_NOTE_LABELS.secondary_diagnosis)
                                    }
                                    isSearchable
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={[
                                        {
                                            label: "Select diagnosis",
                                            value: "",
                                        },
                                        ...(DiagnosisCodesForSelect || []),
                                    ]}
                                    isDisabled={isLoading || Boolean(error)}
                                    hasError={!!errors.secondary_diagnosis}
                                    value={
                                        DiagnosisCodesForSelect?.find(
                                            (diagnosis) =>
                                                field.value?.includes(
                                                    diagnosis.value
                                                )
                                        ) || null
                                    }
                                    errorText={
                                        errors.secondary_diagnosis?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                    isOptional
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="tertiary_diagnosis"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.tertiary_diagnosis
                                    }
                                    placeholder={
                                        (!data && isLoading && "Loading...") ||
                                        (error &&
                                            !isLoading &&
                                            "Error loading diagnosis") ||
                                        (data &&
                                            !isLoading &&
                                            SOAP_PLAN_NOTE_LABELS.tertiary_diagnosis)
                                    }
                                    isSearchable
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    options={[
                                        {
                                            label: "Select diagnosis",
                                            value: "",
                                        },
                                        ...(DiagnosisCodesForSelect || []),
                                    ]}
                                    isDisabled={isLoading || Boolean(error)}
                                    hasError={!!errors.tertiary_diagnosis}
                                    value={
                                        DiagnosisCodesForSelect?.find(
                                            (diagnosis) =>
                                                field.value?.includes(
                                                    diagnosis.value
                                                )
                                        ) || null
                                    }
                                    errorText={
                                        errors.tertiary_diagnosis?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                    isOptional
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <TextArea
                            {...register(
                                "emotional_behavioural_symptoms_demonstrated"
                            )}
                            placeholder={
                                SOAP_PLAN_NOTE_LABELS.emotional_behavioural_symptoms_demonstrated
                            }
                            label={
                                SOAP_PLAN_NOTE_LABELS.emotional_behavioural_symptoms_demonstrated
                            }
                            hasError={
                                !!errors.emotional_behavioural_symptoms_demonstrated
                            }
                            defaultValue={
                                noteDetails.note_content
                                    ?.emotional_behavioural_symptoms_demonstrated
                            }
                            errorText={
                                errors
                                    .emotional_behavioural_symptoms_demonstrated
                                    ?.message
                            }
                            isRequired
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="causing"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={SOAP_PLAN_NOTE_LABELS.causing}
                                    placeholder={SOAP_PLAN_NOTE_LABELS.causing}
                                    isRequired
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    value={
                                        CausingOptionsForSelect.find(
                                            (cause) =>
                                                cause.value === field.value
                                        ) || null
                                    }
                                    options={CausingOptionsForSelect}
                                    hasError={!!errors.causing}
                                    errorText={errors.causing?.message}
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                    <div className="fg">
                        <Controller
                            name="intention_for_session"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.intention_for_session
                                    }
                                    placeholder={
                                        SOAP_PLAN_NOTE_LABELS.intention_for_session
                                    }
                                    onChange={(val) =>
                                        field.onChange((val as Option).value)
                                    }
                                    value={
                                        IntentionForSessionForSelect.find(
                                            (session) =>
                                                session.value === field.value
                                        ) || null
                                    }
                                    options={IntentionForSessionForSelect}
                                    hasError={!!errors.intention_for_session}
                                    errorText={
                                        errors.intention_for_session?.message
                                    }
                                    isRequired
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                />
                            )}
                        />
                    </div>
                </section>
                <section id="step-two" className="mb-40">
                    <h3 className="note-header">Session Note</h3>
                    <p className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.subjective}
                    </p>
                    <p className="note-label">
                        This section is for Subjective reporting of your
                        clients, it can include their mood, their reported
                        symptoms, their efforts since your last meeting to
                        implement your homework or recommendations or any
                        questions they have
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("subjective")}
                            hasError={!!errors.subjective}
                            errorText={errors.subjective?.message}
                            defaultValue={noteDetails.note_content?.subjective}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.objective}
                    </p>
                    <p className="note-label">
                        What were the behaviors, nonverbal expressions,gestures,
                        postures, and overall presentation of the client?
                        Consider client&apos;s mood and affect,client&apos;s
                        response to treatment, any use of assessments.
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("objective")}
                            label="How did they present themselves? Was there nervous talking or lack of eye contact?"
                            placeholder="How did they present themselves? Was there nervous talking or lack of eye contact?"
                            hasError={!!errors.objective}
                            errorText={errors.objective?.message}
                            defaultValue={noteDetails.note_content?.objective}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <p className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.assessment}
                    </p>
                    <p className="note-label">
                        This section is using clinical judgment and analysis to
                        provide a combined summary of session&apos;s focus. This
                        should not be a mere repeat of the S & O sections, but
                        rather a synthesis of those sections that displays a
                        greater understanding of the client as revealed during
                        that particular therapy session. Which clinical themes
                        are present? What do the subjective reports of the
                        client and objective observations really show about the
                        current state of the client?
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("assessment")}
                            hasError={!!errors.assessment}
                            errorText={errors.assessment?.message}
                            defaultValue={noteDetails.note_content?.assessment}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                </section>
                <section id="step-three" className="mb-40">
                    <h3 className="note-header">
                        Therapy Intervention Techniques
                    </h3>
                    <p className="note-label">
                        Please select at least 1 intervention used
                    </p>
                    <p className="note-label note-label-opt">
                        {SOAP_PLAN_NOTE_LABELS.cognitive_behavioral_therapies}
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Cognitive-Behavioral therapy (CBT)"
                            value="Cognitive-Behavioral therapy (CBT)"
                            defaultChecked={handleCheckCognitive(
                                "Cognitive-Behavioral therapy (CBT)"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Dialectical Behavioral therapy (DBT)"
                            value="Dialectical Behavioral therapy (DBT)"
                            defaultChecked={handleCheckCognitive(
                                "Dialectical Behavioral therapy (DBT)"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Modeling and skills training"
                            value="Modeling and skills training"
                            defaultChecked={handleCheckCognitive(
                                "Modeling and skills training"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Trauma-focused CBT"
                            value="Trauma-focused CBT"
                            defaultChecked={handleCheckCognitive(
                                "Trauma-focused CBT"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="EDMR"
                            value="EDMR"
                            defaultChecked={handleCheckCognitive("EDMR")}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Rational Emotive Behaviour therapy"
                            value="Rational Emotive Behaviour therapy"
                            defaultChecked={handleCheckCognitive(
                                "Rational Emotive Behaviour therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Acceptance Commitment Therapy"
                            value="Acceptance Commitment Therapy"
                            defaultChecked={handleCheckCognitive(
                                "Acceptance Commitment Therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Solution Based Brief Therapy"
                            value="Solution Based Brief Therapy"
                            defaultChecked={handleCheckCognitive(
                                "Solution Based Brief Therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("cognitive_behavioral_therapies")}
                            label="Mindfulness Based Cognitive Therapy"
                            value="Mindfulness Based Cognitive Therapy"
                            defaultChecked={handleCheckCognitive(
                                "Mindfulness Based Cognitive Therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    {errors.cognitive_behavioral_therapies ? (
                        <div className="note-error">
                            <p>
                                {errors.cognitive_behavioral_therapies.message}
                            </p>
                        </div>
                    ) : null}

                    <p className="note-label note-label-opt">
                        {SOAP_PLAN_NOTE_LABELS.relationship_based_interventions}
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("relationship_based_interventions")}
                            label="Attachment-oriented interventions"
                            value="Attachment-oriented interventions"
                            defaultChecked={handleCheckRelationship(
                                "Attachment-oriented interventions"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("relationship_based_interventions")}
                            label="Parent-child interaction therapy"
                            value="Parent-child interaction therapy"
                            defaultChecked={handleCheckRelationship(
                                "Parent-child interaction therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("relationship_based_interventions")}
                            label="Parent interventions"
                            value="Parent interventions"
                            defaultChecked={handleCheckRelationship(
                                "Parent interventions"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    {errors.relationship_based_interventions ? (
                        <div className="note-error">
                            <p>
                                {
                                    errors.relationship_based_interventions
                                        .message
                                }
                            </p>
                        </div>
                    ) : null}

                    <p className="note-label note-label-opt">
                        {SOAP_PLAN_NOTE_LABELS.other}
                    </p>
                    <div className="note-checkboxes-list">
                        <FormCheckbox
                            {...register("other")}
                            label="Client centered therapy/ Humanism"
                            value="Client centered therapy/ Humanism"
                            defaultChecked={handleCheckOther(
                                "Client centered therapy/ Humanism"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Gestalt therapy"
                            value="Gestalt therapy"
                            defaultChecked={handleCheckOther("Gestalt therapy")}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Existential therapy"
                            value="Existential therapy"
                            defaultChecked={handleCheckOther(
                                "Existential therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Feminist therapy"
                            value="Feminist therapy"
                            defaultChecked={handleCheckOther(
                                "Feminist therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Psychodynamic therapy"
                            value="Psychodynamic therapy"
                            defaultChecked={handleCheckOther(
                                "Psychodynamic therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Grief therapy"
                            value="Grief therapy"
                            defaultChecked={handleCheckOther("Grief therapy")}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Internal family systems (IFS)"
                            value="Internal family systems (IFS)"
                            defaultChecked={handleCheckOther(
                                "Internal family systems (IFS)"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Narrative therapy"
                            value="Narrative therapy"
                            defaultChecked={handleCheckOther(
                                "Narrative therapy"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Positive psychology"
                            value="Positive psychology"
                            defaultChecked={handleCheckOther(
                                "Positive psychology"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Psychoeducation"
                            value="Psychoeducation"
                            defaultChecked={handleCheckOther("Psychoeducation")}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Sex therapy"
                            value="Sex therapy"
                            defaultChecked={handleCheckOther("Sex therapy")}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Strength based theory"
                            value="Strength based theory"
                            defaultChecked={handleCheckOther(
                                "Strength based theory"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Career Counseling"
                            value="Career Counseling"
                            defaultChecked={handleCheckOther(
                                "Career Counseling"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register("other")}
                            label="Multisystemic family theory"
                            value="Multisystemic family theory"
                            defaultChecked={handleCheckOther(
                                "Multisystemic family theory"
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    {errors.other ? (
                        <div className="note-error">
                            <p>{errors.other.message}</p>
                        </div>
                    ) : null}
                    {rootError ? (
                        <div className="note-error">
                            <p>{rootError}</p>
                        </div>
                    ) : null}

                    <h3 className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.plan}
                    </h3>
                    <p className="note-label">
                        What specific steps has the client committed to work on
                        as homework or during the next session? What specific
                        interventions or treatment plan changes will the
                        clinician be focused on in the upcoming sessions?
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("plan")}
                            hasError={!!errors.plan}
                            errorText={errors.plan?.message}
                            defaultValue={noteDetails.note_content?.plan}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.frequency_of_sessions}
                    </h3>
                    <div className="note-radios">
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Weekly"
                            value="Weekly"
                            defaultChecked={
                                noteDetails.note_content
                                    ?.frequency_of_sessions === "Weekly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Bi-Weekly"
                            value="Bi-Weekly"
                            defaultChecked={
                                noteDetails.note_content
                                    ?.frequency_of_sessions === "Bi-Weekly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Monthly"
                            value="Monthly"
                            defaultChecked={
                                noteDetails.note_content
                                    ?.frequency_of_sessions === "Monthly"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <RadioInput
                            {...register("frequency_of_sessions")}
                            label="Other"
                            value="Other"
                            defaultChecked={
                                noteDetails.note_content
                                    ?.frequency_of_sessions === "Other"
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    {errors.frequency_of_sessions ? (
                        <div className="note-error">
                            <p>Frequency of sessions is required</p>
                        </div>
                    ) : null}
                    {watch("frequency_of_sessions") === "Other" && (
                        <div className="fg">
                            <Input
                                {...register("other_frequency_of_sessions")}
                                label={
                                    SOAP_PLAN_NOTE_LABELS.other_frequency_of_sessions
                                }
                                placeholder={
                                    SOAP_PLAN_NOTE_LABELS.other_frequency_of_sessions
                                }
                                hasError={!!errors.other_frequency_of_sessions}
                                errorText={
                                    errors.other_frequency_of_sessions?.message
                                }
                                defaultValue={
                                    noteDetails.note_content
                                        ?.other_frequency_of_sessions
                                }
                                onBlur={handleSubmit(handleAutoSaveNote)}
                            />
                        </div>
                    )}
                    <div className="fg">
                        <Controller
                            name="date_of_next_session"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    label={
                                        SOAP_PLAN_NOTE_LABELS.date_of_next_session
                                    }
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setLocalDateOfNextSession(date);
                                    }}
                                    selected={localDateOfNextSession}
                                    value={
                                        field.value
                                            ? formatDate(
                                                  field.value,
                                                  true,
                                                  "MM/dd/yyyy"
                                              )
                                            : undefined
                                    }
                                    hasError={!!errors.date_of_next_session}
                                    errorText={
                                        errors.date_of_next_session?.type ===
                                        "typeError"
                                            ? "Invalid date value"
                                            : errors.date_of_next_session
                                                  ?.message
                                    }
                                    onBlur={handleSubmit(handleAutoSaveNote)}
                                    shouldCloseOnSelect
                                    onCalendarClose={handleSubmit(
                                        handleAutoSaveNote
                                    )}
                                    isRequired
                                />
                            )}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.prognosis}
                    </h3>
                    <p className="note-label">
                        Please write at least 1-2 sentences about the likely
                        outcome/development of the symptoms/challenges
                    </p>
                    <div className="fg">
                        <TextArea
                            {...register("prognosis")}
                            defaultValue={noteDetails.note_content?.prognosis}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <h3 className="note-label-req">
                        {SOAP_PLAN_NOTE_LABELS.client_response}
                    </h3>
                    <div className="fg">
                        <TextArea
                            {...register("client_response")}
                            hasError={!!errors.client_response}
                            errorText={errors.client_response?.message}
                            defaultValue={
                                noteDetails.note_content?.client_response
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                </section>
                <section id="step-four" className="mb-40">
                    <h3 className="note-header">Treatment Plan/ Progress</h3>
                    <div className="fg fg-space-between two flex">
                        <TextArea
                            {...register("goal_one")}
                            label={SOAP_PLAN_NOTE_LABELS.goal_one}
                            placeholder={SOAP_PLAN_NOTE_LABELS.goal_one}
                            hasError={!!errors.goal_one}
                            errorText={errors.goal_one?.message}
                            defaultValue={noteDetails.note_content?.goal_one}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            isRequired
                        />
                        <TextArea
                            {...register("progress_one")}
                            label={SOAP_PLAN_NOTE_LABELS.progress_one}
                            placeholder={SOAP_PLAN_NOTE_LABELS.progress_one}
                            hasError={!!errors.progress_one}
                            errorText={errors.progress_one?.message}
                            defaultValue={
                                noteDetails.note_content?.progress_one
                            }
                            isRequired
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <div className="fg fg-space-between two flex">
                        <TextArea
                            {...register("goal_two")}
                            placeholder={SOAP_PLAN_NOTE_LABELS.goal_two}
                            label={SOAP_PLAN_NOTE_LABELS.goal_two}
                            hasError={!!errors.goal_two}
                            errorText={errors.goal_two?.message}
                            defaultValue={noteDetails.note_content?.goal_two}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            isOptional
                        />
                        <TextArea
                            {...register("progress_two")}
                            placeholder={SOAP_PLAN_NOTE_LABELS.progress_two}
                            label={SOAP_PLAN_NOTE_LABELS.progress_two}
                            hasError={!!errors.progress_two}
                            errorText={errors.progress_two?.message}
                            defaultValue={
                                noteDetails.note_content?.progress_two
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            isOptional
                        />
                    </div>
                    <div className="fg fg-space-between two flex">
                        <TextArea
                            {...register("goal_three")}
                            placeholder={SOAP_PLAN_NOTE_LABELS.goal_three}
                            label={SOAP_PLAN_NOTE_LABELS.goal_three}
                            hasError={!!errors.goal_three}
                            errorText={errors.goal_three?.message}
                            defaultValue={noteDetails.note_content?.goal_three}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            isOptional
                        />
                        <TextArea
                            {...register("progress_three")}
                            placeholder={SOAP_PLAN_NOTE_LABELS.progress_three}
                            label={SOAP_PLAN_NOTE_LABELS.progress_three}
                            hasError={!!errors.progress_three}
                            errorText={errors.progress_three?.message}
                            defaultValue={
                                noteDetails.note_content?.progress_three
                            }
                            onBlur={handleSubmit(handleAutoSaveNote)}
                            isOptional
                        />
                    </div>
                    <h3 className="note-label note-label-opt">
                        {
                            SOAP_PLAN_NOTE_LABELS.communication_factors_impacting_treatment
                        }
                    </h3>
                    <div className="note-checkboxes">
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            value="Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants"
                            defaultChecked={isCheckboxChecked(
                                "Need to manage maladaptive communication (e.g., related to high anxiety, high reactivity repeated questions, or disagreement) among participants",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            value="Caregiver emotions or behaviors interfered with the caregiver's understanding and ability"
                            defaultChecked={isCheckboxChecked(
                                "Caregiver emotions or behaviors interfered with the caregiver's understanding and ability",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            value="Evidence or disclosure of a sentinel event and mandated reporting to a third party"
                            defaultChecked={isCheckboxChecked(
                                "Evidence or disclosure of a sentinel event and mandated reporting to a third party",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            value="Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction"
                            defaultChecked={isCheckboxChecked(
                                "Use of play equipment or other physical devices to communicate with the client to overcome barriers to therapeutic or diagnostic interaction",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            value="Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment"
                            defaultChecked={isCheckboxChecked(
                                "Client has not developed, or lost, either the expressive language communication skills to explain their symptoms and respond to treatment",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                        <FormCheckbox
                            {...register(
                                "communication_factors_impacting_treatment"
                            )}
                            label="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            value="Client lacks the receptive communication skills to understand the therapist when using typical language for communication"
                            defaultChecked={isCheckboxChecked(
                                "Client lacks the receptive communication skills to understand the therapist when using typical language for communication",
                                noteDetails.note_content
                                    ?.communication_factors_impacting_treatment
                            )}
                            onBlur={handleSubmit(handleAutoSaveNote)}
                        />
                    </div>
                    <h3 className="note-label note-label-opt">
                        {
                            SOAP_PLAN_NOTE_LABELS.details_around_communication_factors_impacting_treatment
                        }
                    </h3>
                    <TextArea
                        {...register(
                            "details_around_communication_factors_impacting_treatment"
                        )}
                        defaultValue={
                            noteDetails.note_content
                                ?.details_around_communication_factors_impacting_treatment
                        }
                        onBlur={handleSubmit(handleAutoSaveNote)}
                    />
                </section>
                <div className="fg-info fg-line">
                    <p>Sign note here</p>
                    <div className="fg">
                        <Input
                            {...register("signature")}
                            hasError={!!errors.signature}
                            errorText={errors.signature?.message}
                            label="Provider's Initials"
                            placeholder="Provider's Initials"
                            autoComplete="off"
                            isRequired
                        />
                    </div>
                </div>
                <div>
                    <EditNoteFooter
                        onSaveButtonClick={handleSubmit(handleEditDraftNote)}
                        noteDOS={noteDetails.date_of_service}
                    />
                </div>
            </form>
        </div>
    );
}
