import * as React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import {
    Alert,
    Input,
    Select,
    TextArea,
    Button,
} from "@jhool-io/fe-components";
import styles from "./ChargeClientForm.module.scss";
import { IChargeClient } from "../../../../utils/types/billing";
import { useChargeClient } from "../../../../hooks/mutations/billing";
import useToast from "../../../../hooks/useToast";
import {
    useFetchClientBasicAndPersonalInfo,
    useFetchClientCreditCards,
} from "../../../../hooks/queries/client";
import { useGetInvoicePayment } from "../../../../hooks/queries/billing";
import { useFetchSessionNote } from "../../../../hooks/queries/note";
import { NoteTypes } from "../../../../utils/types/notes";
import {
    formatDate,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
} from "../../../../utils/helpers";
import { ClientCardTypes } from "../../../../utils/types/client";
import useCreditCardInput from "../../../../hooks/useCreditCard";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import PendingResolutionForm from "../PendingResolutionForm/PendingResolutionForm";
import { useFetchInvoices } from "../../hooks/queries/billing.queries";

interface ChargeClientProps {
    /** function to call when the form's submit button is clicked */
    onFormSubmit(): void;
    /** the ID of the invoice */
    invoiceId: string;
    /** the note Id */
    noteId: string;
}

type Steps = "charge-client" | "failed-prompt" | "pending-resolution";

type Option = {
    label: React.ReactNode;
    value: boolean;
};

export default function ChargeClient({
    onFormSubmit,
    invoiceId,
    noteId,
}: ChargeClientProps) {
    const [currentStep, setCurrentStep] =
        React.useState<Steps>("charge-client");
    const [errorMsg, setErrorMsg] = React.useState("");

    const schema = yup.object().shape({
        amount: yup.number().required("Charge amount is required").min(1),
        payment_card_id: yup.string(),
        payment_description: yup
            .string()
            .required("Charge description is required"),
        invoice_id: yup.string(),
        payment_source: yup.string().required("Payment source is required"),
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    const clientId = params.clientId as string;

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<IChargeClient>({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    // Fetch client credit cards
    const {
        data: cards,
        isLoading,
        error,
    } = useFetchClientCreditCards(clientId);

    const clientInfo = useFetchClientBasicAndPersonalInfo(clientId);

    const sessionNote = useFetchSessionNote(
        clientId,
        noteId,
        Boolean(clientId) && Boolean(noteId)
    );

    // payload to get invoice
    const getInvoicePayload = {
        invoice_id: invoiceId,
    };

    // Hook for fetching invoice to charge
    const invoiceToCharge = useFetchInvoices({ invoice_id: invoiceId });

    const invoicePayment = useGetInvoicePayment(invoiceId, getInvoicePayload);

    const chargeClient = useChargeClient(clientId);

    const { toast } = useToast();

    const { cardImages } = useCreditCardInput();

    const paymentSourceOptionsForSelect = [
        { label: "Co-Insurance", value: "co-insurance" },
        { label: "PR100", value: "pr100" },
    ];

    // Function to determine credit card provider icon to display
    const getCreditCardIconToDisplay = (cardType: string) => {
        switch (cardType) {
            case ClientCardTypes.AMERICAN_EXPRESS:
                return cardImages.amex;

            case ClientCardTypes.MASTERCARD:
                return cardImages.mastercard;

            case ClientCardTypes.DISCOVER_FINANCIAL:
                return cardImages.discover;

            case ClientCardTypes.CHASE:
                return cardImages.chase;

            case ClientCardTypes.JCB:
                return cardImages.jcb;

            case ClientCardTypes.CAPITAL_ONE:
                return cardImages.capitalone;

            case ClientCardTypes.VISA:
                return cardImages.visa;

            case ClientCardTypes.DINERSCLUB:
                return cardImages.dinersclub;

            default:
                return cardImages.capitalone;
        }
    };

    const clientCardsForSelect = cards?.data
        ?.filter((card) => !card.is_card_expired)
        .map((card) => ({
            label: (
                <div className="flex items-center justify-between">
                    <div className="flex justify-between items-center bg-[#F7FAF5] px-12 py-4 rounded-r6 gap-x-32 w-[200px] ">
                        {getCreditCardIconToDisplay(card.card_type)}
                        <p className=" text-sm font-medium">
                            {card.masked_card_number}
                        </p>
                    </div>

                    <span className="text-[#5B6A7F] flex flex-wrap text-[12px] leading-[16px] w-6/12 ml-auto">
                        {card.card_label}
                    </span>
                </div>
            ),

            value: card.payment_card_id,
        }));

    // Query client
    const queryClient = useQueryClient();

    const extractErrorCodeAndMessage = (msg: string) => {
        const regexPattern = /Code: ([A-Z0-9]+), Message: (.+)/;

        const matches = regexPattern.exec(msg);

        if (matches) {
            return matches[0];
        }
        return "Error charging client at this time";
    };

    const clientCurrentCards = cards?.data.filter((card) =>
        Boolean(card.is_current)
    );

    // Get charge message
    const handleChargeDescription = () => {
        if (cards && cards.data.length !== 0) {
            if (watch("payment_card_id")) {
                return `This charge will be made to their credit card ending in ${cards?.data
                    .find(
                        (card) =>
                            card.payment_card_id === watch("payment_card_id")
                    )
                    ?.masked_card_number.slice(-4)}. `;
            }
            if (clientCurrentCards && clientCurrentCards.length !== 0) {
                return `This charge will be made to their credit card ending in ${clientCurrentCards[
                    clientCurrentCards.length - 1
                ].masked_card_number.slice(-4)}. `;
            }
            if (clientCurrentCards && clientCurrentCards.length === 0) {
                return `This charge will be made to their credit card ending in ${cards.data[
                    cards.data.length - 1
                ].masked_card_number.slice(-4)}. `;
            }
            return `This charge will be made to the most recently added payment card. `;
        }
        if (clientInfo.data?.data.last_session !== null) {
            return "This charge will be made to the last payment card used.";
        }
        return "This charge will be made to their card available on Authorize.net.";
    };

    const onSubmit = (data: IChargeClient) => {
        const chargeClientPayload = {
            ...data,
            invoice_id: invoiceId,
            note_type: sessionNote.data?.data.type as NoteTypes,
            payment_source: undefined,
            is_pr_100: data.payment_source === "pr100",
        };

        chargeClient.mutate(chargeClientPayload, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["get-payment"],
                });
                toast({
                    mode: "success",
                    message:
                        "The client's credit card has been charged for this invoice. Please allow a few minutes for the payment to show up on the statement",
                    duration: 6000,
                    className: styles.toast,
                });

                onFormSubmit();
            },
            onError: (err) => {
                setErrorMsg(
                    extractErrorCodeAndMessage(err.response?.data.message || "")
                );
                setCurrentStep("failed-prompt");
                searchParams.set("cf_modal", "prompt");
                setSearchParams(searchParams);
            },
        });
    };

    if (currentStep === "failed-prompt") {
        return (
            <div className={styles.failed}>
                <Alert
                    type="error"
                    title="Error charging client"
                    description="Mantle is unable to charge the client's card, will you like to mark this bill as pending resolution?"
                />
                <div className={styles.failed_btns}>
                    <Button
                        onClick={() => {
                            setCurrentStep("pending-resolution");
                            searchParams.set("cf_modal", "pr");
                            setSearchParams(searchParams);
                        }}
                    >
                        Mark as Pending Resolution
                    </Button>
                    <Button variant="secondary" onClick={onFormSubmit}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }

    if (currentStep === "pending-resolution") {
        return (
            <PendingResolutionForm
                onFormSubmit={onFormSubmit}
                additionalInfo={errorMsg || ""}
                invoiceID={sessionNote.data?.data.invoice_id}
                metaProps={{ noteId }}
            />
        );
    }

    const getDefaultCard = () => {
        if (clientCurrentCards && clientCurrentCards.length !== 0) {
            return clientCurrentCards[clientCurrentCards.length - 1]
                .payment_card_id;
        }

        if (
            clientCurrentCards &&
            clientCurrentCards.length === 0 &&
            cards &&
            cards.data.length !== 0
        ) {
            return cards.data[cards.data.length - 1].payment_card_id;
        }

        return "";
    };

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.sub_text}>
                    {`You're about to process a ${
                        sessionNote.data
                            ? removeEnumUnderscore(
                                  sessionNote.data?.data.type as string
                              )
                            : ""
                    } charge of ${showMoneyInAppFormat(
                        Math.abs(
                            watch("amount") ||
                                (invoiceToCharge.data?.data[0]
                                    .accounting_coinsurance as number)
                        )
                    )} to ${
                        sessionNote.data
                            ? `${makeStringFirstLetterCapital(
                                  sessionNote?.data?.data.client.first_name
                              )} ${makeStringFirstLetterCapital(
                                  sessionNote?.data?.data.client.last_name || ""
                              )}`
                            : " "
                    }'s account.`}
                    <p className={styles.sub_text_confirm}>
                        {handleChargeDescription()}
                        Kindly confirm the amount and proceed.
                    </p>
                </div>
            </div>
            <form
                id="charge-client"
                className="mt-8"
                onSubmit={handleSubmit(onSubmit)}
            >
                {isLoading && (
                    <Skeleton className="h-[43px] w-full rounded-r8 mb-24" />
                )}
                {error && (
                    <div className="mb-24">
                        <div className={styles.error}>
                            Error loading client&apos;s cards.
                        </div>
                        <p className={styles.linkmessage}>
                            Kindly link the client merchant id to client profile
                            on Authorize.For more information{" "}
                            <a
                                href="https://docs.google.com/document/d/16Sl2vNO7U4Mzk8HGCbN1mDf--awHw0vT_pkxNBcfr3U/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                            >
                                click here
                            </a>
                        </p>
                    </div>
                )}
                <div className="fg">
                    <Controller
                        name="payment_source"
                        control={control}
                        defaultValue={
                            paymentSourceOptionsForSelect.find(
                                (option) => option.value === "co-insurance"
                            )?.value
                        }
                        render={({ field }) => (
                            <Select
                                label="Payment source"
                                placeholder="Payment source"
                                options={paymentSourceOptionsForSelect}
                                onChange={(val) => {
                                    field.onChange((val as Option).value);
                                }}
                                hasError={!!errors.payment_source}
                                errorText={errors?.payment_source?.message}
                                defaultValue={paymentSourceOptionsForSelect.find(
                                    (option) => option.value === "co-insurance"
                                )}
                                isLongListInDialog
                            />
                        )}
                    />
                </div>
                {cards?.data && (
                    <div className="fg">
                        <Controller
                            name="payment_card_id"
                            control={control}
                            defaultValue={
                                clientCardsForSelect?.find(
                                    (card) =>
                                        card.value ===
                                        (clientCurrentCards &&
                                        clientCurrentCards.length !== 0
                                            ? clientCurrentCards[
                                                  clientCurrentCards.length - 1
                                              ].payment_card_id
                                            : "")
                                )?.value
                            }
                            render={({ field }) => (
                                <Select
                                    label="Payment card"
                                    placeholder="Card to charge"
                                    defaultValue={clientCardsForSelect?.filter(
                                        (card) =>
                                            card.value === getDefaultCard()
                                    )}
                                    options={clientCardsForSelect}
                                    onChange={(val) => {
                                        field.onChange((val as Option).value);
                                    }}
                                    isDisabled={
                                        !cards || Boolean(error) || isLoading
                                    }
                                    menuPlacement="bottom"
                                    isLongListInDialog
                                />
                            )}
                        />
                    </div>
                )}

                {invoicePayment.isLoading && (
                    <Skeleton className={styles.loader} />
                )}

                {invoicePayment.data && invoiceToCharge.data?.data && (
                    <div className="fg">
                        <Input
                            {...register("amount")}
                            name="amount"
                            label="Confirm charge amount($)"
                            placeholder="Confirm charge amount($)"
                            hasError={!!errors.amount}
                            errorText={
                                errors.amount?.type === "typeError"
                                    ? "This field is required and must be a number"
                                    : "Amount must be greater than 1"
                            }
                            defaultValue={Math.abs(
                                invoiceToCharge.data?.data[0]
                                    .accounting_coinsurance as number
                            )}
                        />
                    </div>
                )}

                {sessionNote.isLoading && (
                    <Skeleton className="w-full h-[10rem] rounded-r6" />
                )}

                {sessionNote.data && (
                    <div>
                        <TextArea
                            {...register("payment_description")}
                            name="payment_description"
                            label="Description (this description will appear on the payment receipt)"
                            hasError={!!errors.payment_description}
                            errorText={errors?.payment_description?.message}
                            defaultValue={`${makeStringFirstLetterCapital(
                                removeEnumUnderscore(
                                    sessionNote.data.data.appointment_type
                                )
                            )} session on ${formatDate(
                                sessionNote.data.data.date_of_service
                            )} `}
                        />
                    </div>
                )}
            </form>
        </div>
    );
}
