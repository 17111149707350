/* eslint-disable react/no-unstable-nested-components */
import {
    Button,
    Card,
    Checkbox,
    DatePicker,
    Dialog,
    DialogContent,
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownTrigger,
    Pagination,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SearchInput,
    Table,
} from "@jhool-io/fe-components";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { IAppCustomDates } from "../../../../utils/types";
import {
    buildSuccessMessage,
    cn,
    convertDateFilterStringToDate,
    formatDate,
    getTotalAmountOfTableRowsSelected,
    makeStringFirstLetterCapital,
    removeEnumUnderscore,
    showMoneyInAppFormat,
    truncateString,
} from "../../../../utils/helpers";
import ChevronDownIcon from "../../../../components/Icons/ChevronDown";
import { APP_COLORS, LISTS_DEFAULT_LIMIT } from "../../../../utils/constants";
import CheckPrimaryColorIcon from "../../../../components/Icons/CheckPrimaryColor";
import { useDebounce } from "../../../../hooks/helpers";
import { useFetchMatchedPayment } from "../../hooks/payments.queries";
import { IMatchedPayment, PaymentStatus } from "../../types/payments.types";
import { useDoesUserHavePermission } from "../../../../hooks/permissions";
import { UserPermisions, UserRole } from "../../../../utils/types/user";
import useToast from "../../../../hooks/useToast";
import Skeleton from "../../../../components/Skeleton/Skeleton";
import ListState from "../../../../components/ListState/ListState";
import TableFiltersDisplay from "../../../../shared-ui/TableFiltersDisplay/TableFiltersDisplay";
import { getPaymentStatusTag } from "../../helpers/payments.helpers";
import {
    BillStatus,
    BillStatusLabels,
} from "../../../billing/types/billing.types";
import {
    getBillStatusTag,
    paymentDateToDisplay,
} from "../../../billing/helpers/billing.helpers";
import {
    useRefreshPaymentStatus,
    useRematchPayment,
    useUpdateInvoiceStatus,
} from "../../hooks/payments.mutations";
import UnionUpIcon from "../../../../components/Icons/UnionUp";
import UnionDownIcon from "../../../../components/Icons/UnionDown";
import PendingResolutionForm, {
    PendingResolutionMetaData,
} from "../../../billing/components/PendingResolutionForm/PendingResolutionForm";
import { useFetchInvoices } from "../../../billing/hooks/queries/billing.queries";
import { useAppSelector } from "../../../../hooks/useRedux";
import MoreButton from "../../../../shared-ui/Buttons/MoreButton/MoreButton";

interface MatchedPaymentsListProps {
    setTotalMatchedCount: React.Dispatch<React.SetStateAction<string>>;
}

const customDates = [
    IAppCustomDates.ALL_TIME,
    IAppCustomDates.LAST_24_HRS,
    IAppCustomDates.THIS_WEEK,
    IAppCustomDates.LAST_WEEK,
    IAppCustomDates.LAST_TWO_WEEKS,
    IAppCustomDates.THIS_MONTH,
    IAppCustomDates.LAST_MONTH,
];

const buildSuccessMessageForRefreshPaymentStatus = (
    updatedPayments: string[],
    unUpdatedPayments: string[]
) => {
    const updatedPaymentMessage =
        updatedPayments.length > 0
            ? `${updatedPayments.length} Bill Status updated`
            : "";
    const unUpdatedPaymentMessage =
        unUpdatedPayments.length > 0
            ? `${unUpdatedPayments.length} Bill Status not updated`
            : "";
    const message = `Update complete: ${[
        updatedPaymentMessage,
        unUpdatedPaymentMessage,
    ]
        .filter(Boolean)
        .join(", ")}`;
    return message;
};

type Dialogs = "pending_resolution" | "rematch_payment";

export default function MatchedPaymentsList({
    setTotalMatchedCount,
}: MatchedPaymentsListProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [rowSelection, setRowSelection] = React.useState({});
    const [getSelectedData, setGetSelectedData] =
        React.useState<Row<IMatchedPayment>[]>();
    const [dialogInView, setDialogInView] = React.useState<Dialogs | null>(
        null
    );
    const [dialogSearchString, setDialogSearchString] = React.useState("");
    const [propsForPendingResolution, setPropsForPendingResolution] =
        React.useState<PendingResolutionMetaData | null>(null);
    const [invoiceToRematch, setInvoiceToRematch] = React.useState<
        string | null
    >(null);
    const [paymentToRematch, setPaymentToRematch] = React.useState<
        string | null
    >(null);
    const [clientId, setClientId] = React.useState<string | null>(null);
    const [expandedClients, setExpandedClients] = React.useState<{
        [key: string]: boolean;
    }>({});
    const [selectedInvoice, setSelectedInvoice] = React.useState("");
    const [sortConfig, setSortConfig] = React.useState<{
        key: string;
        direction: string;
    }>({
        key: "",
        direction: "",
    });

    const clientFilter = searchParams.get("search_string") || "";
    const customDateFilter =
        searchParams.get("custom_date") || IAppCustomDates.LAST_24_HRS;
    const invoiceStatusFilter = searchParams.getAll("invoice_status[]");
    const fromDateFilter = searchParams.get("from_date");
    const toDateFilter = searchParams.get("to_date");
    const showFullyPaidInvoicesFilter =
        searchParams.get("show_fully_paid_invoices") || "";
    const pageFilter = Number(searchParams.get("page")) || 1;
    const limitFilter =
        Number(searchParams.get("limit")) || LISTS_DEFAULT_LIMIT;

    const fromDateFilterToDate = convertDateFilterStringToDate(fromDateFilter);
    const toDateFilterToDate = convertDateFilterStringToDate(toDateFilter);

    const debouncedClientFilter = useDebounce(clientFilter);

    const debouncedDialogClientSearchValue = useDebounce(dialogSearchString);

    const sortableColumns = [
        "client_name",
        "payment_date",
        "date_of_service",
        "payment_amount",
        "posted_by",
    ];

    const { toast } = useToast();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { practice } = useAppSelector((state) => state.userPractice);

    const handleConfigAttribute = () => {
        if (sortableColumns.includes(sortConfig.key)) {
            return sortConfig.key;
        }

        return undefined;
    };

    const { isLoading, error, data, isSuccess } = useFetchMatchedPayment({
        page: pageFilter,
        limit: limitFilter,
        search_string: debouncedClientFilter,
        custom_date: customDateFilter,
        to_date: toDateFilter,
        from_date: fromDateFilter,
        invoice_status: invoiceStatusFilter,
        show_fully_paid_invoices: showFullyPaidInvoicesFilter,
        sort_attribute:
            searchParams.get("sort_attr") || handleConfigAttribute(),
        sort_order: searchParams.get("sort_order") || sortConfig.direction,
    });

    const invoicesList = useFetchInvoices({
        search_string: debouncedDialogClientSearchValue,
        sort_attribute: "client_name",
        sort_order: "ASC",
    });

    const clients = Array.from(
        new Set(invoicesList.data?.data.map((item) => item.client.client_id))
    ).map((id) => {
        const client = invoicesList.data?.data.find(
            (item) => item.client.client_id === id
        )?.client;
        return {
            client_id: id,
            first_name: client?.first_name,
            last_name: client?.last_name,
        };
    });

    const clientInvoices = useFetchInvoices({
        client_id: clientId || "",
    });

    const refreshPaymentStatus = useRefreshPaymentStatus();
    const updateInvoice = useUpdateInvoiceStatus();
    const rematchPayment = useRematchPayment();

    const updateInVoiceIsMutating = useIsMutating(["update-invoice"]);

    // check if logged in user can see table more action button
    const canSeeDropdownActions = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    // check if logged in user can perform bulk actions
    const canPerformBulkActions = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_PAYMENTS_WRITE,
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
    ]);

    const showRefreshPaymentBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter((row) =>
                Boolean(row.original.transaction_id)
            ).length;

    const showOpenInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.OPEN
            ).length;

    const showCloseInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) => row.original.invoice_status !== BillStatus.CLOSED
            ).length;

    const showPendingResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_RESOLUTION
            ).length;

    const showPendingInsuranceResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_INSURANCE_RESOLUTION
            ).length;

    const showPendingPatientResolutionInvoiceBulkAction =
        getSelectedData &&
        getSelectedData.length > 0 &&
        getSelectedData.length ===
            getSelectedData.filter(
                (row) =>
                    row.original.invoice_status !==
                    BillStatus.PENDING_PATIENT_RESOLUTION
            ).length;

    // Get tab count for unmatched payment
    // get the total count of matched payment based on the current filter
    if (data && data?.total_count > 0) {
        setTotalMatchedCount(String(data.total_count));
    }
    if (data && data.data.length === 0) {
        setTotalMatchedCount("");
    }

    const handleSetClientFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") searchParams.delete("search_string");
        else searchParams.set("search_string", e.target.value);
        setSearchParams(searchParams);
    };

    const handleCustomDateChange = (value: IAppCustomDates) => {
        searchParams.set("custom_date", value);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFromDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("from_date");
        else searchParams.set("from_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleToDateFilterChange = (date: Date | null) => {
        if (date === null) searchParams.delete("to_date");
        else searchParams.set("to_date", format(date, "yyyy-MM-dd"));
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleShowFullyPaidInvoicesFilter = (text: string) => {
        if (text === "") searchParams.delete("show_fully_paid_invoices");
        else searchParams.set("show_fully_paid_invoices", text);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleInvoiceStatusFilter = (status: string) => {
        if (invoiceStatusFilter.includes(status)) {
            searchParams.delete("invoice_status[]", status);
        } else {
            searchParams.append("invoice_status[]", status);
        }
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handlePageFilter = (page: number) => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleLimitFilter = (limit: number) => {
        searchParams.set("limit", String(limit));
        setSearchParams(searchParams);
    };

    const getShowAllFullyPaidFilterTextToDisplay = () => {
        if (showFullyPaidInvoicesFilter === "true")
            return "Show fully paid bills";
        return "Hide fully paid bills";
    };

    const singleBillViewStatusParams = practice?.roles?.includes(
        UserRole.CLIENT_SUPPORT
    )
        ? "?invoice_status[]=pending_resolution"
        : "";

    const handleRowClick = (row: Row<IMatchedPayment>) => {
        navigate(
            `/billing-and-claims/${row.original.client_id}/${row.original.note_id}${singleBillViewStatusParams}`
        );
    };

    const toggleRowExpansion = (id: string) => {
        setExpandedClients((prevExpandedClients) => ({
            [id]: !prevExpandedClients[id],
        }));
    };

    const handleShowPendingResolutionDialog = (row?: Row<IMatchedPayment>) => {
        setDialogInView("pending_resolution");
        setPropsForPendingResolution({
            noteId: row?.original.note_id,
            clientId: row?.original.client_id,
            invoiceId: row?.original.invoice_id,
            invoiceIds: getSelectedData
                ? getSelectedData.map((item) => item.original.invoice_id)
                : undefined,
        });
    };

    const handleClosePendingResolutionDialog = () => {
        setDialogInView(null);
        setPropsForPendingResolution(null);
    };

    const handleCloseRematchDialog = () => {
        setDialogInView(null);
        setInvoiceToRematch(null);
        setPaymentToRematch(null);
        setExpandedClients({});
        setDialogSearchString("");
    };

    const handleRefreshPaymentStatus = (paymentId?: string) => {
        const selectedIds = getSelectedData
            ? getSelectedData.map((item) => item.original.payment_id)
            : [];

        const idsToChange = paymentId ? [paymentId] : selectedIds;

        const updatePaymentStatusPayload = {
            payment_ids: idsToChange,
        };
        refreshPaymentStatus.mutate(updatePaymentStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["matched"],
                });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessageForRefreshPaymentStatus(
                        response.data.updated_payments,
                        response.data.unupdated_payments
                    )}`,
                });
                if (selectedIds) setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err.response?.data.message ||
                        "No Payment Payment was refreshed",
                });
            },
        });
    };

    const handleRematchPayment = () => {
        const dataToSend = {
            payment_id: paymentToRematch as string,
            invoice_id: invoiceToRematch as string,
        };
        rematchPayment.mutate(dataToSend, {
            onSuccess: async (res) => {
                queryClient.invalidateQueries({
                    queryKey: ["matched"],
                });
                toast({
                    mode: "success",
                    message: res.message || "Payment updated successfully",
                });
                handleCloseRematchDialog();
            },
            onError: async (res) => {
                toast({
                    mode: "error",
                    message:
                        res.message || "Could not update payment at this time.",
                });
            },
        });
    };

    // function to update invoice status
    const handleUpdateInvoiceStatus = (
        status: BillStatus,
        invoiceId?: string
    ) => {
        const selectedIds = getSelectedData
            ? getSelectedData.map((item) => item.original.invoice_id)
            : [];

        const idsToChange = invoiceId ? [invoiceId] : selectedIds;

        const changeStatusPayload = {
            invoice_ids: idsToChange,
            status,
        };
        updateInvoice.mutate(changeStatusPayload, {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries({
                    queryKey: ["matched"],
                });
                toast({
                    mode: "success",
                    message: `${buildSuccessMessage(
                        response.data.updated_invoices,
                        response.data.unupdated_invoices
                    )}`,
                });
                if (selectedIds) setRowSelection({});
            },
            onError: (err) => {
                toast({
                    mode: "error",
                    message:
                        err?.response?.data.message ||
                        "Cannot update bill status at this time",
                });
            },
        });
    };

    const columns: ColumnDef<IMatchedPayment>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <Checkbox
                    handleChange={table.getToggleAllRowsSelectedHandler()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    label=""
                    name="row"
                    value="row"
                    isChecked={table.getIsAllRowsSelected()}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    handleChange={row.getToggleSelectedHandler()}
                    indeterminate={row.getIsSomeSelected()}
                    id={row.original.payment_id}
                    label=""
                    name="row"
                    value="row"
                    isChecked={row.getIsSelected()}
                />
            ),
        },
        {
            accessorKey: "client_name",
            header: "CLIENT",
            cell: ({ row }) => row.original.client_name || "--",
        },
        {
            accessorKey: "cpt_code",
            header: "CPT CODE",
            cell: ({ row }) => row.original.cpt_code || "--",
        },
        {
            accessorKey: "date_of_service",
            header: "DATE OF SERVICE",
            cell: ({ row }) =>
                row.original.date_of_service
                    ? formatDate(row.original.date_of_service)
                    : "--",
        },

        {
            accessorKey: "payment_date",
            header: "PAYMENT DATE",
            cell: ({ row }) =>
                paymentDateToDisplay(
                    row.original.payment_date,
                    row.original.payment_channel || ""
                ),
        },
        {
            accessorKey: "payment_method",
            header: "PAYMENT METHOD",
            cell: ({ row }) => {
                return (
                    <span>
                        {makeStringFirstLetterCapital(
                            row.original.payment_method || "--"
                        )}
                        {(row.original.auth_code ||
                            row.original.cheque_number) && (
                            <span>
                                {" "}
                                -{" "}
                                {row.original.auth_code ||
                                    row.original.cheque_number ||
                                    "--"}
                            </span>
                        )}
                    </span>
                );
            },
        },
        {
            accessorKey: "payment_status",
            header: "PAYMENT status",
            cell: ({ row }) =>
                getPaymentStatusTag(
                    row.original.payment_status as PaymentStatus
                ),
        },
        {
            accessorKey: "posted_by",
            header: "POSTED BY",
            cell: ({ row }) =>
                row.original.posted_by
                    ? `${row.original.posted_by.first_name} ${row.original.posted_by.last_name}` ||
                      "--"
                    : "--",
        },
        {
            accessorKey: "processor_payment_status",
            header: "PROCESSOR STATUS",
            cell: ({ row }) =>
                makeStringFirstLetterCapital(
                    row.original.processor_payment_status || "--"
                ),
        },
        {
            accessorKey: "payment_amount",
            header: "PAYMENT AMOUNT",
            cell: ({ row }) =>
                showMoneyInAppFormat(row.original.amount) || "--",
        },
        {
            accessorKey: "invoice_status",
            header: "BILL STATUS",
            cell: ({ row }) =>
                getBillStatusTag(row.original.invoice_status as BillStatus),
        },
        {
            accessorKey: "accounting",
            header: "Co-ins outstanding",
            cell: ({ row }) => (
                <span
                    className={cn("text-sm font-semibold", {
                        "text-danger": row.original.accounting > 0,
                    })}
                >
                    {row.original.accounting > 0
                        ? `(${showMoneyInAppFormat(row.original.accounting)})`
                        : showMoneyInAppFormat(
                              Math.abs(row.original.accounting)
                          )}
                </span>
            ),
        },

        {
            accessorKey: "insurance_outstanding",
            header: "Ins outstanding",
            cell: ({ row }) => (
                <span
                    className={cn("text-sm font-semibold", {
                        "text-danger": row.original.insurance_outstanding > 0,
                    })}
                >
                    {row.original.insurance_outstanding > 0
                        ? `(${showMoneyInAppFormat(
                              row.original.insurance_outstanding
                          )})`
                        : showMoneyInAppFormat(
                              Math.abs(row.original.insurance_outstanding)
                          )}
                </span>
            ),
        },
        {
            accessorKey: "payment_id",
            header: "",
            cell: ({ row }) => {
                return (
                    canSeeDropdownActions && (
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <MoreButton />
                            </DropdownTrigger>
                            <DropdownContent width="auto" align="end">
                                {row.original.transaction_id && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRefreshPaymentStatus(
                                                row.original.payment_id
                                            );
                                        }}
                                        disabled={
                                            refreshPaymentStatus.isLoading
                                        }
                                    >
                                        Refresh Payment Status
                                    </DropdownItem>
                                )}
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateInvoiceStatus(
                                            BillStatus.OPEN,
                                            row.original.invoice_id
                                        );
                                    }}
                                    disabled={updateInvoice.isLoading}
                                    hidden={
                                        row.original.invoice_status ===
                                        BillStatus.OPEN
                                    }
                                >
                                    Mark as open
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateInvoiceStatus(
                                            BillStatus.CLOSED,
                                            row.original.invoice_id
                                        );
                                    }}
                                    disabled={updateInvoice.isLoading}
                                    hidden={
                                        row.original.invoice_status ===
                                        BillStatus.CLOSED
                                    }
                                >
                                    Mark as close
                                </DropdownItem>
                                <DropdownItem
                                    disabled={updateInvoice.isLoading}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleShowPendingResolutionDialog(row);
                                    }}
                                    hidden={
                                        row.original.invoice_status ===
                                        BillStatus.PENDING_RESOLUTION
                                    }
                                >
                                    Mark as pending session fee
                                </DropdownItem>
                                <DropdownItem
                                    disabled={updateInvoice.isLoading}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateInvoiceStatus(
                                            BillStatus.PENDING_PATIENT_RESOLUTION,
                                            row.original.invoice_id
                                        );
                                    }}
                                    hidden={
                                        row.original.invoice_status ===
                                        BillStatus.PENDING_PATIENT_RESOLUTION
                                    }
                                >
                                    Mark as pending patient resolution
                                </DropdownItem>
                                <DropdownItem
                                    disabled={updateInvoice.isLoading}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUpdateInvoiceStatus(
                                            BillStatus.PENDING_INSURANCE_RESOLUTION,
                                            row.original.invoice_id
                                        );
                                    }}
                                    hidden={
                                        row.original.invoice_status ===
                                        BillStatus.PENDING_INSURANCE_RESOLUTION
                                    }
                                >
                                    Mark as pending insurance resolution
                                </DropdownItem>
                                {row.original.invoice_status ===
                                    BillStatus.OPEN && (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDialogInView("rematch_payment");
                                            setPaymentToRematch(
                                                row.original.payment_id
                                            );
                                            setSelectedInvoice(
                                                row.original.invoice_id
                                            );
                                        }}
                                    >
                                        Rematch Payment
                                    </DropdownItem>
                                )}
                            </DropdownContent>
                        </Dropdown>
                    )
                );
            },
        },
    ];

    return (
        <>
            <Dialog open={dialogInView === "rematch_payment"}>
                <DialogContent
                    title="Match payment"
                    variant="center"
                    handleCloseDialog={handleCloseRematchDialog}
                    saveText="Match payment"
                    wrapperClass="w-[750px]"
                    showFooter={Boolean(invoiceToRematch)}
                    onSaveClick={handleRematchPayment}
                    isCancelBtnDisabled={rematchPayment.isLoading}
                    isSubmitBtnDisabled={rematchPayment.isLoading}
                >
                    <div>
                        <p className="text-base font-medium mb-12">
                            Please select the client and session you want to
                            match this payment to
                        </p>
                        <Card>
                            <SearchInput
                                placeholder="Search by client name or chart number"
                                onChange={(e) =>
                                    setDialogSearchString(e.target.value)
                                }
                            />
                            {clients.map((client) => (
                                <div key={client.client_id} className="mt-16">
                                    <div className="flex items-center justify-between border-b border-b-strokedark pb-8 text-sm">
                                        {`${client.first_name} ${client.last_name}`}
                                        <Button
                                            variant="normal"
                                            size="auto"
                                            onClick={() => {
                                                setClientId(client.client_id);
                                                toggleRowExpansion(
                                                    client.client_id
                                                );
                                            }}
                                        >
                                            {expandedClients[
                                                client.client_id
                                            ] &&
                                            clientId === client.client_id ? (
                                                <UnionUpIcon />
                                            ) : (
                                                <UnionDownIcon />
                                            )}
                                        </Button>
                                    </div>
                                    {expandedClients[client.client_id] &&
                                        clientId === client.client_id && (
                                            <div className="mt-16 rounded-r8 border border-strokedark">
                                                {clientInvoices.isLoading && (
                                                    <Skeleton
                                                        count={3}
                                                        height={20}
                                                        width="100%"
                                                        containerTestId="invoices-loader"
                                                    />
                                                )}
                                                {clientInvoices.data &&
                                                    clientInvoices.data.data
                                                        .length > 0 && (
                                                        <>
                                                            <div className="grid grid-cols-5 text-xs font-bold text-gray p-12 border-b border-b-strokedark">
                                                                <p>
                                                                    DATE OF
                                                                    SERVICE
                                                                </p>
                                                                <p>PROVIDER</p>
                                                                <p>CPT CODE</p>
                                                                <p>CHARGED</p>
                                                                <p>COLLECTED</p>
                                                            </div>
                                                            {clientInvoices.data?.data
                                                                .filter(
                                                                    (item) =>
                                                                        item.invoice_id !==
                                                                        selectedInvoice
                                                                )
                                                                .map((item) => (
                                                                    <Button
                                                                        variant="normal"
                                                                        size="auto"
                                                                        key={
                                                                            item.invoice_id
                                                                        }
                                                                        className={cn(
                                                                            "grid grid-cols-5 text-xs font-regular text-black p-12 border-b border-b-strokedark !rounded-none w-full text-left",
                                                                            {
                                                                                "bg-secondary-100":
                                                                                    invoiceToRematch ===
                                                                                    item.invoice_id,
                                                                            }
                                                                        )}
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            setInvoiceToRematch(
                                                                                item.invoice_id as string
                                                                            );
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {formatDate(
                                                                                item.date_of_service
                                                                            ) ||
                                                                                "--"}
                                                                        </p>

                                                                        <p>
                                                                            {`${item.provider.first_name} ${item.provider.last_name} ` ||
                                                                                "--"}
                                                                        </p>

                                                                        <p>
                                                                            {item.cpt_code ||
                                                                                "--"}
                                                                        </p>

                                                                        <p>
                                                                            {showMoneyInAppFormat(
                                                                                item.coinsurance_amount
                                                                            ) ||
                                                                                "--"}
                                                                        </p>

                                                                        <p>
                                                                            {showMoneyInAppFormat(
                                                                                item.coinsurance_amount_collected ||
                                                                                    "--"
                                                                            )}
                                                                        </p>
                                                                    </Button>
                                                                ))}
                                                        </>
                                                    )}
                                            </div>
                                        )}
                                </div>
                            ))}
                        </Card>
                    </div>
                </DialogContent>
            </Dialog>

            {propsForPendingResolution && (
                <Dialog open={dialogInView === "pending_resolution"}>
                    <DialogContent
                        title="Pending session fee"
                        handleCloseDialog={handleClosePendingResolutionDialog}
                        variant="center"
                        showFooter
                        cancelText="Cancel"
                        saveText="Mark as Pending session fee"
                        submitBtnFormValue="pending-resolution"
                        isSubmitBtnDisabled={updateInVoiceIsMutating > 0}
                        isCancelBtnDisabled={updateInVoiceIsMutating > 0}
                    >
                        <PendingResolutionForm
                            onFormSubmit={() => {
                                handleClosePendingResolutionDialog();
                                setRowSelection({});
                            }}
                            metaProps={propsForPendingResolution}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <div className="mb-32 bg-white rounded-r8 py-16 px-12">
                <div className="flex flex-col gap-y-24">
                    <SearchInput
                        containerClass="w-[400px] max-w-full"
                        placeholder="Search by client name or chart number"
                        onChange={handleSetClientFilter}
                        defaultValue={clientFilter}
                    />
                    <div className="flex gap-12 items-center flex-wrap">
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                >
                                    {customDateFilter
                                        ? truncateString(
                                              removeEnumUnderscore(
                                                  customDateFilter
                                              )
                                          )
                                        : "Custom date"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width={200}
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {customDates.map((range) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center capitalize text-xs font-medium",
                                            {
                                                "text-primary":
                                                    customDateFilter === range,
                                            }
                                        )}
                                        key={range}
                                        onClick={() =>
                                            handleCustomDateChange(range)
                                        }
                                    >
                                        {removeEnumUnderscore(range)}{" "}
                                        {customDateFilter === range ? (
                                            <CheckPrimaryColorIcon />
                                        ) : null}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium self-start md:self-center"
                                >
                                    Payment Date
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                align="end"
                                className="p-16 gap-y-12"
                            >
                                <DatePicker
                                    label="From"
                                    placeholderText="MM/DD/YYYY"
                                    selected={fromDateFilterToDate}
                                    onChange={handleFromDateFilterChange}
                                    maxDate={
                                        toDateFilterToDate ||
                                        new Date(Date.now())
                                    }
                                    isClearable
                                />
                                <DatePicker
                                    label="To"
                                    placeholderText="MM/DD/YYYY"
                                    selected={toDateFilterToDate}
                                    onChange={handleToDateFilterChange}
                                    minDate={fromDateFilterToDate}
                                    isClearable
                                />
                            </PopoverContent>
                        </Popover>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                >
                                    {showFullyPaidInvoicesFilter
                                        ? truncateString(
                                              getShowAllFullyPaidFilterTextToDisplay()
                                          )
                                        : "Show all bills"}
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width="auto"
                                className="overflow-y-auto"
                            >
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                showFullyPaidInvoicesFilter ===
                                                "",
                                        }
                                    )}
                                    onClick={() =>
                                        handleShowFullyPaidInvoicesFilter("")
                                    }
                                >
                                    Show all bills
                                    {customDateFilter === "" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                showFullyPaidInvoicesFilter ===
                                                "true",
                                        }
                                    )}
                                    onClick={() =>
                                        handleShowFullyPaidInvoicesFilter(
                                            "true"
                                        )
                                    }
                                >
                                    Show fully paid bills
                                    {customDateFilter === "true" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                                <DropdownItem
                                    className={cn(
                                        "flex justify-between items-center capitalize text-xs font-medium",
                                        {
                                            "text-primary":
                                                showFullyPaidInvoicesFilter ===
                                                "false",
                                        }
                                    )}
                                    onClick={() =>
                                        handleShowFullyPaidInvoicesFilter(
                                            "false"
                                        )
                                    }
                                >
                                    Hide fully paid bills
                                    {customDateFilter === "false" ? (
                                        <CheckPrimaryColorIcon />
                                    ) : null}
                                </DropdownItem>
                            </DropdownContent>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger asChild>
                                <Button
                                    size="auto"
                                    variant="normal"
                                    className="px-16 h-32 shadow-active gap-x-8 text-sm data-[state=open]:border-primary font-medium capitalize"
                                >
                                    Status
                                    <ChevronDownIcon
                                        stroke={APP_COLORS.COLOR_BLACK}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContent
                                align="end"
                                width="auto"
                                maxHeight={216}
                                className="overflow-y-auto"
                            >
                                {Object.values(BillStatus).map((status) => (
                                    <DropdownItem
                                        className={cn(
                                            "flex justify-between items-center capitalize text-xs font-medium"
                                        )}
                                        key={status}
                                        onClick={() =>
                                            handleInvoiceStatusFilter(status)
                                        }
                                        isMulti
                                        itemValue={status}
                                        values={invoiceStatusFilter}
                                    >
                                        {`${removeEnumUnderscore(
                                            BillStatusLabels[status]
                                        )} Bills`}
                                    </DropdownItem>
                                ))}
                            </DropdownContent>
                        </Dropdown>
                    </div>
                </div>

                <TableFiltersDisplay
                    appliedFilters={[
                        {
                            key: "invoice_status[]",
                            values: invoiceStatusFilter,
                        },
                    ]}
                    wrapperClass="mt-12"
                />
            </div>
            {canPerformBulkActions && (
                <div>
                    {getTotalAmountOfTableRowsSelected(rowSelection).amount >
                        0 && (
                        <div className="flex items-center mb-16 gap-12 flex-wrap">
                            <div className="font-semibold text-base">
                                {
                                    getTotalAmountOfTableRowsSelected(
                                        rowSelection
                                    ).amountString
                                }{" "}
                                :
                            </div>
                            {showRefreshPaymentBulkAction && (
                                <Button
                                    onClick={() => handleRefreshPaymentStatus()}
                                    variant="secondary"
                                    disabled={refreshPaymentStatus.isLoading}
                                >
                                    Refresh Payment Status
                                </Button>
                            )}
                            {showCloseInvoiceBulkAction && (
                                <Button
                                    onClick={() =>
                                        handleUpdateInvoiceStatus(
                                            BillStatus.CLOSED
                                        )
                                    }
                                    variant="secondary"
                                    disabled={updateInvoice.isLoading}
                                >
                                    Mark as close
                                </Button>
                            )}
                            {showOpenInvoiceBulkAction && (
                                <Button
                                    onClick={() =>
                                        handleUpdateInvoiceStatus(
                                            BillStatus.OPEN
                                        )
                                    }
                                    variant="secondary"
                                    disabled={updateInvoice.isLoading}
                                >
                                    Mark as open
                                </Button>
                            )}
                            {showPendingResolutionInvoiceBulkAction && (
                                <Button
                                    onClick={() =>
                                        handleShowPendingResolutionDialog()
                                    }
                                    variant="secondary"
                                    disabled={updateInvoice.isLoading}
                                >
                                    Mark as pending session fee
                                </Button>
                            )}
                            {showPendingInsuranceResolutionInvoiceBulkAction && (
                                <Button
                                    onClick={() =>
                                        handleUpdateInvoiceStatus(
                                            BillStatus.PENDING_INSURANCE_RESOLUTION
                                        )
                                    }
                                    variant="secondary"
                                    disabled={updateInvoice.isLoading}
                                >
                                    Mark as pending insurance resolution
                                </Button>
                            )}
                            {showPendingPatientResolutionInvoiceBulkAction && (
                                <Button
                                    onClick={() =>
                                        handleUpdateInvoiceStatus(
                                            BillStatus.PENDING_INSURANCE_RESOLUTION
                                        )
                                    }
                                    variant="secondary"
                                    disabled={updateInvoice.isLoading}
                                >
                                    Mark as pending patient resolution
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}
            {isLoading && (
                <Skeleton
                    type="table"
                    count={6}
                    containerTestId="matchedpayments-loader"
                />
            )}
            {error && (
                <ListState
                    isError
                    stateHelperText="Try reloading this page to solve this issue"
                    errorMsg={
                        error?.response?.data.message ||
                        `Cannot display matched payments at this time please try again later`
                    }
                />
            )}
            {data && isSuccess && data?.data?.length === 0 && (
                <ListState
                    stateHelperText="Matched payments will appear here when available"
                    isError={false}
                    emptyMessage="No matched payment"
                />
            )}
            {isSuccess && data && data.data.length > 0 && (
                <Table
                    data={data.data}
                    columns={columns}
                    hasPagination={data.total_count > 20}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    setGetSelectedData={setGetSelectedData}
                    handleRowClick={handleRowClick}
                    setSortConfig={setSortConfig}
                    sortConfig={sortConfig}
                    sortableColumns={sortableColumns}
                    pagination={
                        <Pagination
                            totalCount={data.total_count}
                            totalPages={data.total_pages}
                            currentPage={pageFilter}
                            onPageChange={handlePageFilter}
                            limit={limitFilter}
                            onLimitChange={handleLimitFilter}
                        />
                    }
                />
            )}
        </>
    );
}
