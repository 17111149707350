import * as React from "react";
import { useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { Dialog, DialogContent, Button } from "@jhool-io/fe-components";
import styles from "./PersonalInfo.module.scss";
import { useFetchClientBasicAndPersonalInfo } from "../../../../hooks/queries/client";
import ListState from "../../../ListState/ListState";
import EditPersonalInfo from "./EditPersonalInfo/EditPersonalInfo";
import { UserPermisions } from "../../../../utils/types/user";
import {
    useDoesUserHavePermission,
    useDoesUserHaveRole,
} from "../../../../hooks/permissions";
import EditIcon from "../../../Icons/Edit";
import ClientProvidersHistoryList from "../../../../modules/clients/components/ClientProvidersHistoryList/ClientProvidersHistoryList";
import Skeleton from "../../../Skeleton/Skeleton";
import PlusIcon from "../../../Icons/Plus";
import { APP_COLORS } from "../../../../utils/constants";
import { UserRole } from "../../../../modules/user-management/types/user-management.types";

export default function PersonalInfo() {
    // Component local states
    const [showModal, setShowModal] = React.useState(false);

    // Get id from url params
    const params = useParams();
    const clientId = params.clientId as string;

    // Fetch client personal info
    const clientPersonalInfo = useFetchClientBasicAndPersonalInfo(clientId);

    const handleToggleShowModal = () => {
        setShowModal((state) => !state);
    };

    // To determine if mutation is currently running, returns 0 || 1
    const isMutating = useIsMutating(["edit-personal-info", clientId]);

    // Roles restricted from editing
    const restrictedRoles = [UserRole.AUDITOR, UserRole.PROVIDER];

    // Check permissions and roles
    const hasEditPermission = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_PERSONAL_WRITE,
        UserPermisions.CLIENT_INFO_ALL_WRITE,
    ]);

    const hasRestrictedRole = useDoesUserHaveRole(restrictedRoles);

    // Check if the logged in user can edit personal info
    const canEditPersonalInfo = hasEditPermission && !hasRestrictedRole;

    const canSeeProviderHistory = useDoesUserHavePermission([
        UserPermisions.CLIENT_INFO_ALL_ADMIN,
        UserPermisions.CLIENT_INFO_PROVIDER_READ,
        UserPermisions.CLIENT_INFO_PROVIDER_WRITE,
    ]);

    return (
        <>
            {clientPersonalInfo.data && (
                <Dialog open={showModal && Boolean(clientPersonalInfo.data)}>
                    <DialogContent
                        title="Edit info"
                        variant="center"
                        handleCloseDialog={handleToggleShowModal}
                        showFooter
                        cancelText="Cancel"
                        saveText="Save Changes"
                        submitBtnFormValue="edit-personal-info"
                        isCancelBtnDisabled={isMutating > 0}
                        isSubmitBtnDisabled={isMutating > 0}
                    >
                        <EditPersonalInfo
                            clientPersonalInfo={clientPersonalInfo.data.data}
                            onFormSubmit={handleToggleShowModal}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {canSeeProviderHistory ? (
                <div className="mb-32" id="provider-history">
                    <ClientProvidersHistoryList />
                </div>
            ) : null}
            {clientPersonalInfo.data && (
                <div>
                    <div className="rounded-r8 bg-white border border-stroke-divider px-12 py-24 md:p-24 flex items-center justify-between mb-16">
                        <h3 className="text-base font-bold leading-normal">
                            Relations Info
                        </h3>
                        {canEditPersonalInfo && !clientPersonalInfo.error && (
                            <>
                                <Button
                                    onClick={handleToggleShowModal}
                                    className="hidden md:flex"
                                >
                                    {clientPersonalInfo.data.data
                                        .relation_info &&
                                    clientPersonalInfo.data.data.relation_info
                                        .length === 0
                                        ? "Add relation"
                                        : "Edit"}
                                </Button>
                                <Button
                                    variant="normal"
                                    size="auto"
                                    onClick={handleToggleShowModal}
                                    className="flex md:hidden size-24 items-center justify-center rounded-full bg-white border border-strokedark focus-visible:border-primary"
                                >
                                    {clientPersonalInfo.data.data
                                        .relation_info &&
                                    clientPersonalInfo.data.data.relation_info
                                        .length === 0 ? (
                                        <PlusIcon
                                            stroke={APP_COLORS.COLOR_PRIMARY}
                                            className="size-20"
                                        />
                                    ) : (
                                        <EditIcon className="size-20" />
                                    )}
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="rounded-r8 bg-white border border-stroke-divider px-12 py-24 md:p-24">
                        {clientPersonalInfo.isLoading && (
                            <div className="flex flex-col gap-y-[30px]">
                                <div className="flex items-center gap-x-24">
                                    <Skeleton width="30%" height="50px" />
                                    <Skeleton width="30%" height="50px" />
                                </div>
                                <div className="flex items-center gap-x-24">
                                    <Skeleton width="30%" height="50px" />
                                    <Skeleton width="30%" height="50px" />
                                </div>
                                <div className="flex items-center gap-x-24">
                                    <Skeleton width="30%" height="50px" />
                                    <Skeleton width="30%" height="50px" />
                                </div>
                            </div>
                        )}
                        {clientPersonalInfo.isError && (
                            <ListState
                                isError
                                errorMsg={
                                    clientPersonalInfo?.error?.response?.data
                                        .message ||
                                    `Cannot display client relation at this time please try again later`
                                }
                                stateHelperText="Try reloading this page to solve this issue"
                                context="general"
                            />
                        )}
                        {clientPersonalInfo.data &&
                            clientPersonalInfo.data.data.relation_info &&
                            clientPersonalInfo.data.data.relation_info
                                .length === 0 && (
                                <ListState
                                    stateHelperText="Client's relation info will appear here when added"
                                    emptyMessage="No relation info"
                                    context="general"
                                    isError={false}
                                />
                            )}
                        {clientPersonalInfo.data &&
                            clientPersonalInfo.data.data.relation_info &&
                            clientPersonalInfo.data.data.relation_info?.map(
                                (item) => (
                                    <div
                                        className={styles.flex}
                                        key={item.relation_id}
                                    >
                                        <div className={styles.info}>
                                            <span className="section-info fs-exclude">
                                                Relation type
                                                <span
                                                    className={styles.relation}
                                                >
                                                    ({item.relationship_type})
                                                </span>
                                            </span>
                                            <h3 className={styles.name}>
                                                <span
                                                    className={`section-value capitalize ${styles.fname}`}
                                                >
                                                    {item.first_name}
                                                </span>
                                                <span className="section-value capitalize">
                                                    {item.last_name}
                                                </span>
                                            </h3>
                                        </div>
                                        <div className={styles.info}>
                                            <span className="section-info fs-exclude">
                                                contact
                                                {item.is_primary_contact && (
                                                    <span className={styles.pc}>
                                                        (primary)
                                                    </span>
                                                )}
                                            </span>
                                            <span className="section-value capitalize">
                                                {item.contact_phone_no}
                                            </span>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            )}
        </>
    );
}
