export const getMemoListNavigateUrl = () => {
    // Get note list filters from localstorage
    const memoListFiltersFromLS = localStorage.getItem("memolist_filters");

    if (memoListFiltersFromLS) {
        return `${memoListFiltersFromLS}`;
    }
    return "?tab=memo";
};

export const getClientClaimsListNavigateUrl = () => {
    // Get client claims list filters from localstorage
    const clientClaimsListFiltersFromLS = localStorage.getItem(
        "clientclaimslist_filters"
    );

    if (clientClaimsListFiltersFromLS) {
        return `${clientClaimsListFiltersFromLS}`;
    }
    return "?tab=claims";
};
